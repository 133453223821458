import React , { useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import {TaskboardContext} from "../Shared/TaskboardContext.js";
import Form from "./Form.js";

const TaskTemplate = ({taskTemplate, project_id, column, closeModal, parent_task_id, setSubtasks}) => {
  const match = useRouteMatch();
  const {setColumns} = useContext(TaskboardContext);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [taskId, setTaskId] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (modify_template) => {
    if(modify_template){
      setLoading(true);
    }

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks`, {
      column_id: column.token,
      title: taskTemplate.task_data.title,
      description: taskTemplate.task_data.description,
      cover_url: taskTemplate.task_data.cover_url,
      unsplash_data: taskTemplate.task_data.unsplash_data,
      priority: taskTemplate.task_data.priority,
      questions: taskTemplate.task_data.field_data,
      private: taskTemplate.task_data.private,
      asana_gid: taskTemplate.task_data.asana_gid,
      finished: taskTemplate.task_data.finished,
      subtasks: taskTemplate.subtasks_data,
      task_labels: taskTemplate.task_data.task_labels, 
      task_users: taskTemplate.task_data.users, 
      modify_template: modify_template,
      relative_due_days: taskTemplate.relative_due_days, 
      parent_task_id: parent_task_id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        if (modify_template) {
          setTaskId(response.data.task_data.token);
          setOpen(true);
          setLoading(false);
        } else {
          closeModal();
          if(parent_task_id){
            setSubtasks(prevSubtasks => [...prevSubtasks, response.data.task_data])
            // refetchData();
          } else {
            setColumns(prevColumns => {
              const updatedColumns = prevColumns.map(col => {
                if (col.token === response.data.task_data.column.token) {
                  return {
                    ...col,
                    tasks: [...col.tasks, response.data.task_data]
                  };
                }
                return col;
              });
              return updatedColumns;
            });
          }
        }
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <li className="position-relative hover-parent-opacity">
        <a disabled={disabled} onClick={() => handleSubmit(false)}>
          {taskTemplate.title}
          <span className="font-10 opacity-4 ml-8"><i class="far fa-project-diagram mr-5"></i>{taskTemplate.subtasks_data.length}</span>
        </a>
        
        <i onClick={() => setOpen(true)} className={`far ${loading ? "fa-spinner fa-spin" : "fa-pen"} btn-small font-10 position-absolute hover-child-opacity dropdown-modify-button`}></i>


        <Modal focusTrapped={false} closeOnEsc={false} open={open} onClose={() => {setOpen(false)}} className="modal-body-white" classNames={{modal: 'width-800'}} center>
          <Form taskTemplate={taskTemplate} closeModal={() => {setOpen(false);}} />
        </Modal>
      </li>
    </React.Fragment>
  )
}

export default TaskTemplate;