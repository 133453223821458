import React , { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Form from "./Form.js";

const Role = ({role, refetchData}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const handleDelete = () => {
    axios.delete(`/api/o/${match.params.organization_id}/roles/${role.token}`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <div className="row">
        <div className="display-flex align-items-center justify-content-between default-padding pl-0 pr-0">
          <div className="max-width-150px truncate">
            {role.title}
          </div>

          <div>
            <span className="badge background-active color-1 mr-8">{role.permissions.length} disabled permissions</span>
            <div class="btn-group">
              <a type="button" class="btn btn-small color-1 opacity-6 opacity-10-hover background-hover dropdown-toggle" data-toggle="dropdown">
                <i className="fas fa-ellipsis-v"></i>
              </a>
              <ul class="dropdown-menu" role="menu">
                <li><a onClick={() => setOpen(true)}><i className="fal fa-pencil-alt"></i>Edit</a></li>
                <li><a onClick={handleDelete}><i className="fal fa-trash-alt"></i>Delete</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <Form role={role} refetchData={refetchData} closeModal={() => setOpen(false)} is_client={role.is_client}/>
      </Modal>
    </React.Fragment>
  )
}

export default Role;