import React , { useState } from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom'
import { Modal } from 'react-responsive-modal';
import File from "../../../Replays/Forms/File.js";
import WebsiteForm from "../../../Replays/Website/Form.js";
import RecentReplays from "./RecentReplays.js";
import ReplayRequestsIndex from "../../../Projects/RequestFiles/Index.js";
import { useTranslation } from 'react-i18next';
import LinkForm from "../../../Replays/Forms/LinkForm.js";
import axios from "axios";
import {Menu, Item, useContextMenu} from "react-contexify";

const Upload = ({refetchData, task_id, project_id, task}) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [uploadFile, setUploadFile] = useState(false);
  const [openWebsiteForm, setOpenWebsiteForm] = useState(false)
  const [open, setOpen] = useState(false);
  const [requestOpen, setRequestOpen] = useState(false);
  const [openLink, setOpenLink] = useState(false);
  const { t } = useTranslation();

  const { show } = useContextMenu({
    id: "upload-menu"
  });

  const handleContextMenu = (e) => {
    e.preventDefault();
    show({ event: e });
  };

  const createDocument = () => {
    axios.post(`/replays`, {
      project_id: (match.params.project_id || project_id),
      task_id: task_id,
      replay: {
        title: "Untitled document",
        document: true, 
      }
    }).then((response) => {
      if(response.data.success) {
        refetchData();
        history.push(`/replays/${response.data.replay.token}`);
      }
    })
  }

  return(
    <React.Fragment>
      <div>
        <a 
          className="btn background-hover color-1 btn-small"
          onClick={handleContextMenu}
        >
          <i className="far fa-plus"></i>
        </a>

        <Menu id="upload-menu">
          <Item onClick={() => setUploadFile(true)}>
            <i className="fal fa-upload"></i>{t("File upload")}
          </Item>
          
          <Item onClick={() => setOpenWebsiteForm(true)}>
            <i className="far fa-globe"></i>{t("Live website")}
          </Item>

          <Item onClick={() => setOpenLink(true)}>
            <i className="far fa-link"></i>Direct link
          </Item>
          
          <Item onClick={() => createDocument()}>
            <i className="far fa-file-alt"></i>Document<span className="badge badge-blue ml-8">BETA</span>
          </Item>

          {/* <Item onClick={() => setRequestOpen(true)}>
            <i className="far fa-file-export"></i> {t("Request files")}
          </Item> */}

          <Item onClick={() => setOpen(true)}>
            <i className="far fa-list"></i>{t("Import from files")}
          </Item>
        </Menu>

        <Modal open={uploadFile} onClose={() => setUploadFile(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
          <File closeModal={() => setUploadFile(false)} organization_id={match.params.organization_id} project_id={project_id || match.params.project_id} folder_id={match.params.folder_id} task_id={task_id} refetchData={refetchData}/>
        </Modal>

        <Modal open={openWebsiteForm} onClose={() => setOpenWebsiteForm(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
          <WebsiteForm closeModal={() => setOpenWebsiteForm(false)} task_id={task_id} refetchData={refetchData} project_id={project_id}/>
        </Modal>

        <Modal open={openLink} onClose={() => setOpenLink(false)}  className="modal-body-white" classNames={{modal: 'width-500'}} center>
          <LinkForm closeModal={() => setOpenLink(false)} project_id={project_id} task_id={task?.token} refetchData={refetchData}/>
        </Modal>

        <Modal open={requestOpen} onClose={() => setRequestOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
          <ReplayRequestsIndex setOpen={setRequestOpen} task={task}/>
        </Modal>

        <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
          <RecentReplays task_id={task_id} refetchData={refetchData} project_id={project_id}/>
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default Upload