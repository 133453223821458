import React , { useState, useEffect, useContext } from 'react';
import { useRouteMatch} from "react-router-dom";
import axios from "axios"; 
import {UserContext} from "../../Shared/UserContext.js";
import ReplayDropdown from "../../Replays/ReplayDropdown.js";

const ArchivedProjects = ({refetchData}) => {
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [archivedProjects, setArchivedProjects] = useState([])
  const [deletedProjects, setDeletedProjects] = useState([])
  const [archivedReplays, setArchivedReplays] = useState([])
  const currentUser = useContext(UserContext);

  useEffect(() => {
    fetchArchivedProjects();
  }, []);

  const fetchArchivedProjects = () => {
    axios.get(`/api/o/${match.params.organization_id}/project_archives.json`)
    .then(function(response){
      console.log(response);
      setArchivedProjects(response.data.project_archives)
      setDeletedProjects(response.data.deleted_projects)
      // setArchivedReplays(response.data.replay_archives)
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
      setLoaded(true)
    });
  }

  const archived_projects_list = archivedProjects.map(archive_project => {
    return(
      <Project key={archive_project.token} archive_project={archive_project} refetchData={refetchData} fetchArchivedProjects={fetchArchivedProjects}/>
    )
  });

  const deleted_projects_list = deletedProjects.map(deleted_record => {
    //recover it
    const recoverRecord = () => {
      setDisabled(true);
  
      axios.post(`/api/o/${match.params.organization_id}/recover_record`, {
        record_id: deleted_record.token,
        kind: "projects"
      })
      .then(function(response){
        // console.log(response);
        if(response.data.success){
          fetchDeletedRecords();
          notice("Recover successful");
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
    }

    return(
      <tr className="background-3">
        <td>{deleted_record.title}</td>
        <td className="text-right">{deleted_record.deleted_at}</td>
        <td className="text-right">
          <a disabled={disabled} onClick={recoverRecord} className="btn btn-small background-hover color-1">
            {disabled ? (
              <>
                <i class="fal fa-spinner fa-spin mr-8"></i>Recovering...
              </>
            ):(
              <>
                <i class="fal fa-undo mr-8"></i>Recover
              </>
            )}
          </a>
        </td>
      </tr>
    )
  })

  // const archived_replays_list = archivedReplays.map(replay => {
  //   return(
  //     <React.Fragment>
  //       <tr className="hover-parent-opacity border-bottom">
  //         <td>{replay.title}</td>
  //         <td className="text-right"><ReplayDropdown replay={replay} refetchData={fetchArchivedProjects}/></td>
  //       </tr>
  //     </React.Fragment>
  //   )
  // });
  

  return(
    <React.Fragment>
      <h4 className="mt-15">Archived projects</h4>
      <table class="table">
        <tbody>
          {loaded ? archived_projects_list : <React.Fragment><i className="fas fa-spinner fa-spin"></i> Loading...</React.Fragment>}
        </tbody>
      </table>
      

      {/* <h4 style={{marginTop: 20}}>Deleted project recovery</h4>
      <p className="opacity-6 font-12">Data recovery is a premium feature that lets you recover certain data that was deleted in your workspace.</p>
      <table class="table">
        <tbody>
          {deleted_projects_list}
        </tbody>
      </table> */}
    </React.Fragment>
  )
}

/// archive_project item 
const Project = ({archive_project, refetchData, fetchArchivedProjects}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const currentUser = useContext(UserContext);

  const Unarchive = (archive_project_id) => {
    setDisabled(true)
    axios.delete(`/api/o/${match.params.organization_id}/project_archives/${archive_project_id}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Unarchived finished")
        fetchArchivedProjects();
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false)
    });
  }

  return(
    <tr className="background-3">
      <td>{archive_project.project.title}</td>
      <td className="text-right">Archived {archive_project.updated_at} ago </td>
      <td className="text-right">
        {disabled ? (
          <React.Fragment>
            <i className="fas fa-spinner fa-spin"></i> Wait...
          </React.Fragment>
        ):(
          <a className="btn btn-small background-hover color-1" onClick={() => Unarchive(archive_project.token)}>Unarchive</a>
        )}
      </td>
    </tr>
  )
}

export default ArchivedProjects