import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom';
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../Shared/AppContext.js';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactTooltip from 'react-tooltip';

const TextField = ({project_id, field, setSections, setFields}) => {
  // const { notice } = useContext(AppContext);
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();

  const updateField = (newValue) => {
    console.log('Starting updateField with:', {
      field,
      newValue,
      sectionId: field.section_id
    });

    if(setSections){
      setSections(prev => {
        const updatedSections = prev.map(section => {
          if (section.id === field.section_id) {
            const updatedSection = {
              ...section,
              fields: section.fields.map(f => {
                if (f.id === field.id) {
                  return {...f, value: newValue};
                }
                return f;
              })
            };
            return updatedSection;
          }
          return section;
        });

        return updatedSections;
      });
    } else {
      setFields(prev => {
        return prev.map(f => f.id === field.id ? {...f, value: newValue} : f);
      });
    }
  }

  return (
    field && 
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className="field-answer-wrapper">
        <input className="form-control" defaultValue={field.value} onChange={(e) => updateField(e.target.value)} />
        <CopyToClipboard text={field.value}>
          <i class="fal fa-copy field-copy-icon" data-tip={t("Copy")}></i>
        </CopyToClipboard>
      </div>
    </React.Fragment>
  );
}

export default TextField;