import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import OrganizationUser from "./OrganizationUser.js";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import RolesIndex from "../Organizations/Roles/Index.js";
import InviteUser from "./InviteUser.js";
import Invite from "./Invite.js";

const OrganizationUsers = ({invites, setOrganizationUsers, organizationUsers, roles, refetchData, organization}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openRoles, setOpenRoles] = useState(false);

  // organization_users
  const organization_users_list =  organizationUsers.map(user => {
    return(
      <OrganizationUser user={user} refetchData={refetchData} roles={roles} organization={organization} setOrganizationUsers={setOrganizationUsers}/>
    )
  }); 

  const invites_list = invites.filter(invite => invite.is_client == null).map(invite => {
    return(
      <InviteUser invite={invite} refetchData={refetchData}/>
    )
  });

  return(
    <React.Fragment>
      <div className="queue-table-container">
        <div className="queue-table-header mb-30">
          <div className="queue-table-header-left">
            <h4 className="table-header-title">Teammates</h4>
            <div className="table-header-description">Invite your team and set custom roles & permissions. </div>
          </div>

          <div>
            <a className="btn background-active background-hover-2 color-1 mr-15 btn-small" onClick={() => setOpenRoles(true)}><i class="fal fa-user-shield mr-8"></i>Roles</a>
            <a className="btn btn-primary btn-small" onClick={() => setOpen(true)}><i class="fal fa-plus mr-8"></i>Add teammate</a>
          </div>
        </div>

        <table class="table queue-table">
          <thead>
            <tr className="queue-table-header-tr">
              <th className="queue-table-th" scope="col">Name</th>
              <th className="queue-table-th" scope="col">Hourly rate</th>
              <th className="queue-table-th" scope="col">Last sign in</th>
              <th className="queue-table-th" scope="col">Role</th>
              <th className="queue-table-th" scope="col"></th>
            </tr>
          </thead>

          <tbody>
            {organization_users_list}
            {invites_list}
          </tbody>
        </table>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Invite project_id={null} refetchData={refetchData} closeModal={() => setOpen(false)}/> 
      </Modal>

      <Modal open={openRoles} onClose={() => setOpenRoles(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <RolesIndex refetchData={refetchData} roles={roles} is_client={false}/>
      </Modal>
    </React.Fragment>
  )
}

export default OrganizationUsers