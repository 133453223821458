import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Permission from './Permission.js';

const Form = ({role, refetchData, closeModal, is_client}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState(role?.permissions || []);
  const [title, setTitle] = useState(role?.title || "");

  useEffect(() => {
    fetchPermissions();
  }, []);

  const fetchPermissions = () => {
    const url = is_client ? `/api/permissions.json?is_client=true` : `/api/permissions.json`;
    axios.get(url)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setPermissions(response.data.permissions);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/roles`, {
      title: title, 
      selectedPermissions: rolePermissions, 
      is_client: is_client
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        closeModal();
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/roles/${role?.token}`, {
      title: title, 
      selectedPermissions: rolePermissions,
      is_client: is_client
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const permissions_list = Object.entries(
    _.groupBy(permissions, 'category')
  ).map(([category, categoryPermissions]) => (
    <Permission key={category} category={category} categoryPermissions={categoryPermissions} rolePermissions={rolePermissions} setRolePermissions={setRolePermissions}/>
  ));

  return(
    loaded && 
    <React.Fragment>
      <form onSubmit={role ? handleUpdate : handleSubmit}>
        <div className="field">
          <label>Name</label>
          <input className="form-control" type="text" name="title" onChange={(evt) => setTitle(evt.target.value)} defaultValue={title}/>
        </div>

        <div className="field mt-25">
          <label>Permissions available</label>
          <div class="panel-group" id="accordion">
            {permissions_list}
          </div>

          <div className="field mt-15">
            <p className="font-12 opacity-4">
              Missing any? Request a new one <a href="https://roadmap.usequeue.com" target="_blank">here</a>.
            </p>
          </div>
        </div>

        <div className="field text-right">
          <button className="btn btn-primary" type="submit" disabled={disabled || title.length === 0}>Submit</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default Form;