import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import axios from "axios";
import EmbedForm from "./EmbedForm.js";
import { Modal } from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert';
import { Menu, Item, useContextMenu } from 'react-contexify';
import { Portal } from 'react-portal';

const EmbedDropdown = ({embed, refetchData, project_id, className}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  
  // Create unique ID for this dropdown
  const MENU_ID = `embed-menu-${embed.token}`;
  const { show } = useContextMenu({
    id: MENU_ID
  });

  const handleContextMenu = (event) => {
    event.preventDefault();
    show({ event });
  };

  const confirmDelete = () => {
    confirmAlert({
      title: 'Are you absolutely sure?',
      message: 'This action cannot be undone. This will permanently delete your embed and remove it from your project.',
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Delete',
          className: 'btn btn-danger',
          onClick: () => deleteEmbed()
        },
      ]
    });
  };

  const deleteEmbed = () => {
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}/embeds/${embed.token}`)
      .then(function(response){
        if(response.data.success){
          if(location.href.includes(embed.token)){
            history.push(`/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}`);
          }
          refetchData();
        }
      })
      .catch(function(error){
        console.log(error);
        notice("An error occurred");
      })
      .finally(function () {
        setDisabled(false);
      });
  };

  return(
    <React.Fragment>
      <div 
        className={`${embed.sidenav ? "hover-child-opacity position-absolute right-5" : "hover-child embed-edit-tabs-btn"} ml-5 ${className}`}
        onClick={handleContextMenu}
      >
        <a 
          className={`pl-5 pr-5 opacity-4 opacity-10-hover color-1 ${embed.sidenav ? "embed-edit-btn background-hover-2" : ""}`}
          style={{paddingLeft: "10px"}}
        >
          <i className="fas fa-ellipsis-v"></i>
        </a>
      </div>

      <Portal>
        <Menu id={MENU_ID}>
          <Item onClick={() => setOpen(true)}>
            <i className="fal fa-pencil mr-2"></i>
          Edit
        </Item>
        <Item onClick={confirmDelete}>
          <i className="fal fa-trash-alt mr-2"></i>
          Delete
          </Item>
        </Menu>
      </Portal>

      <Modal 
        open={open} 
        onClose={() => setOpen(false)} 
        className="modal-body-white" 
        classNames={{modal: 'modal-full-width'}} 
        center
      >
        <EmbedForm 
          closeModal={() => setOpen(false)} 
          refetchData={refetchData} 
          embedData={embed}
        />
      </Modal>
    </React.Fragment>
  );
};

export default EmbedDropdown;