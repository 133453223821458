import React , { useState, useEffect, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { useTranslation } from 'react-i18next';
import {OrganizationContext} from "../../../../Shared/OrganizationContext.js";
import {ProjectContext} from "../../../../Projects/ProjectContext.js";
import PrivateForm from "./PrivateForm.js";
import Select from "react-select";
import TipTap from "../../../../Shared/TipTap.js";
import AssignDeadlineForm from "./AssignDeadlineForm.js";
import AssignLabelsForm from "./AssignLabelsForm.js";
import AssignUsersForm from "./AssignUsersForm.js";
import AssignColumnsForm from "./AssignColumnsForm.js";
import SelectProject from "../../../../Shared/SelectProject.js";
import { Modal } from 'react-responsive-modal';

const DefaultQuestionsUniversal = ({closeModal}) => {
  const {organizationAuthorizations, hasPermission} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const {projects, setRefreshData, refreshData, setNewTasks} = useContext(ProjectContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priority, setPriority] = useState("");
  const [columns, setColumns] = useState([]);
  const [isPrivate, setIsPrivate] = useState(false);
  const [priorities, setPriorities] = useState([{label: "No priority", value: null}, {label: "High", value: "High"}, {label: "Medium", value: "Medium"}, {label: "Low", value: "Low"}]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedPriority, setSelectedPriority] = useState(null);
  const [selectedTaskLabels, setSelectedTaskLabels] = useState([]);
  const [selectedTaskUsers, setSelectedTaskUsers] = useState([]);
  const [openFilepicker, setOpenFilepicker] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const clientPortal = window.location.pathname.includes("/portal/o/")
  const [openProjects, setOpenProjects] = useState(false);
  
  useEffect(() => {
    setColumns([]);
    if(selectedProject){
      fetchColumns();
    }
  }, [selectedProject]);

  const fetchColumns = () => {
    axios.get(`/api/portal/projects/${selectedProject?.value}/columns.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        if(response.data.columns.length > 0){
          setSelectedColumn(response.data.columns[0]);
        }
        setColumns(response.data.columns);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/portal/projects/${selectedProject?.value}/tasks`, {
      column_id: selectedColumn.token,
      title: title, 
      description: description, 
      priority: selectedPriority?.value, 
      // date: date, 
      // parent_task_id: parent_task_id, 
      // attachements: attachements, 
      private: isPrivate, 
      deadline: dateRange[1],
      start_date: dateRange[0],
      selected_task_labels: selectedTaskLabels,
      selected_task_users: selectedTaskUsers
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Task created successfully");
        setRefreshData(refreshData + 1)
        closeModal();
        // setNewTasks(prev => [...prev, { id: Date.now(), title: "Your task title here" }]);
        setNewTasks(prev => [...prev, { id: Date.now(), title: title, project_id: selectedProject.value, task_id: response.data.task.token }]);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };


  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">New request</h4>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>{t("Title")}</label>
            <input value={title} onChange={(e) => setTitle(e.target.value)} className="form-control" required="true" autoFocus/>
          </div>

          <div className="field">
            <label>{t("Description")}</label>
            <TipTap comment={description} setComment={setDescription} users={[]}/>
          </div>

          {/* Options */}
          <div className="field border-bottom pb-15">
            <a className="color-1 btn btn-small btn-default background-hover-2 mr-8" onClick={() => setOpenProjects(true)}>
              {selectedProject ?( 
                <>
                  <i className="fal fa-project-diagram mr-8"></i>{selectedProject.label}
                </>
              ):(
                <>
                  <i className="fal fa-project-diagram mr-8"></i>Select client
                </>
              )}
            </a>

            {selectedProject && 
              <>
                {!clientPortal && columns.length > 0 && 
                  <>
                    <AssignColumnsForm setColumn={setSelectedColumn} column={selectedColumn} columns={columns}/>
                    <PrivateForm isPrivate={isPrivate} setIsPrivate={setIsPrivate} />
                    <AssignUsersForm selectedUsers={selectedTaskUsers} setSelectedUsers={setSelectedTaskUsers}/>
                    <AssignLabelsForm selectedLabels={selectedTaskLabels} setSelectedLabels={setSelectedTaskLabels}/>
                    <AssignDeadlineForm dateRange={dateRange} setDateRange={setDateRange} />
                  </>
                }
              </>
            }
          </div>

          <div className="field text-right mt-15 display-flex align-items-center justify-content-end">
            <a onClick={() => setOpenFilepicker(true)} className="color-1 btn btn-small mr-5 background-hover"><i className="fal fa-paperclip"></i></a>
            <button disabled={disabled || !selectedProject || !selectedColumn || !title} className="btn btn-primary" type="submit">{t("Submit")}</button>
          </div>
        </form>
      </div>

      <Modal open={openProjects} onClose={() => setOpenProjects(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <div className="row no-margin">
          <div className="field">
            <SelectProject selectedProject={selectedProject} setSelectedProject={setSelectedProject} closeModal={() => {setOpenProjects(false)}}/>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default DefaultQuestionsUniversal;