import React , { useState, useContext, useEffect, useRef } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Linkify from 'react-linkify';
import DOMPurify from 'dompurify';
import { Modal } from 'react-responsive-modal';
import ReactTooltip from 'react-tooltip';
import TaskReplay from "../TaskReplay.js";
import DisplayHTML from "../../../../Shared/DisplayHTML.js";
import Edit from "./Form.js";
import NestedTaskComment from "./TaskComment.js";
import { AppContext } from "../../../../Shared/AppContext.js";
import Emojis from "./Emojis.js";
import Emoji from "./Emoji.js";
import queryString from "query-string";
import EmailLogs from "../../../../EmailLogs/EmailLogs.js";
import { Menu, Item, useContextMenu } from 'react-contexify';

const TaskComment = ({refetchData, task_comment, taskComments, setTaskComments, project_id, task, users, projectAuthorizations, nested, fetchTask}) => {
  const {markNotificationRead, unreadNotifications} = useContext(AppContext);
  // const currentUser = useContext(UserContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [commentReply, setCommentReply] = useState(false);
  const emojiRef = useRef(null); // Ref to the emoji menu
  const [active, setActive] = useState(false);
  const [openEmailLogs, setOpenEmailLogs] = useState(false);
  const commentRef = useRef(null);
  const [manualState, setManualState] = useState(false);
  const EMOJI_MENU_ID = `emoji-menu-${task_comment.id}`;
  const { show } = useContextMenu({
    id: EMOJI_MENU_ID,
  });

  useEffect(() => {
    // Function to close emoji menu when clicking outside of it
    const handleClickOutside = (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setOpenEmoji(false);
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const isUnreadNotification = unreadNotifications.some(notification => 
    notification.location.includes("TaskComment") && 
    notification.notifiable_token === task.token &&
    notification.notifiable_id === task_comment.id
  );

  // Add new effect to reset manualState when comments change
  useEffect(() => {
    setManualState(false);
  }, [taskComments]);

  // Keep existing effect for marking as read
  useEffect(() => {
    if (!isUnreadNotification) {
      return;
    }

    setManualState(true);
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          markNotificationRead("TaskComment", task_comment.id);
        }
      },
      { threshold: 1.0 }
    );

    if (commentRef.current) {
      observer.observe(commentRef.current);
    }

    return () => observer.disconnect();
  }, [isUnreadNotification]);

  const deleteComment = (evt) => {
    const confirmThis = confirm("Are you sure?")
    if(confirmThis){
      evt.preventDefault();
      setDisabled(true);
  
      axios.delete(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments/${task_comment.token}`)
      .then(function(response){
        // console.log(response);
        if(response.data.success){
          refetchData();
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
    }
  }

  const task_comment_replays_list = task_comment.replays.map(replay => {
    return(
      <TaskReplay replay={replay} refetchData={() => {refetchData(); fetchTask();}} project_id={project_id || match.params.project_id} simple={false}/>
    )
  });

  const nested_task_comments = task_comment.nested_task_comments.map(nested_task_comment => {
    return(
      <NestedTaskComment task_comment={nested_task_comment} refetchData={refetchData} project_id={project_id} task={task} users={users} projectAuthorizations={projectAuthorizations} nested={true}/>
    )
  });

  const reactions_list = task_comment.reactions.map(reaction => {
    return (
      <Emoji reaction={reaction} task={task} taskComment={task_comment} taskComments={taskComments} setTaskComments={setTaskComments}/>
    )
  });

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    // If the url contains a `marker_id`, go to that marker
    if (searchParams.task_comment_id === task_comment.token) {
      const commentElement = document.getElementById(`task-comment-${searchParams.task_comment_id}`);
      if (commentElement) {
        commentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setActive(true);
      }
    }
  }, []);

  const resendEmail = () => {
    notice("resend email");
    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_comments/${task_comment.token}/resend_email`)
    .then(function(response){
      // console.log(response);
    })
  }

  const handleEmojiClick = (e) => {
    show({ event: e });
  };

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div 
        ref={commentRef}
        id={`task-comment-${task_comment.token}`} 
        className={`row hover-parent-opacity default-padding background-active border-all background-3 mb-15 border-radius 
          ${task_comment.private ? 'private-task-comment-comment' : ''} 
          ${nested != null ? "" : "pt-15 pb-15"} 
          position-relative 
          ${manualState ? "unread-task-comment" : ""}
          ${task_comment.isFirstUnread ? "first-unread-comment" : ""}`}
      >
        <div onClick={() => {task_comment.unread && markNotificationRead("TaskComment", task_comment.id)}} className={`daas-task-comments-comment ${nested ? "col-12 daas-task-comments-comment" : ""} ${active ? "background-active" : ""}`}>
          <span className="font-10 opacity-6 ml-8 task-comment-created-ago">{task_comment.created_ago} ago</span>
          <div className="daas-task-user-info">
            <div className="display-flex align-items-center">
              <img className="daas-task-comments-avatar mr-5" src={task_comment.user.avatar}/>
              <span className="font-weight-600">{task_comment.user.username}</span> {task_comment.private && <i className="badge badge-team-only ml-8">Team only</i>}
            </div>
            
            <div className="task-comment-icons hover-child-opacity">
              <div className="btn-group dropup">
                <a onClick={handleEmojiClick} type="button" className="btn-icon mr-5">
                  <i className="far fa-smile-plus"></i>
                </a>
                <Menu id={EMOJI_MENU_ID} animation={false}>
                  <Item>
                    <Emojis 
                      refetchData={refetchData} 
                      task={task} 
                      taskComment={task_comment} 
                      taskComments={taskComments} 
                      project_id={project_id} 
                      setTaskComments={setTaskComments} 
                      openEmoji={openEmoji}
                    />
                  </Item>
                </Menu>
              </div>

              {nested == null && 
                <i data-tip="Reply to comment" class={`far fa-reply btn-icon ${commentReply ? "background-active" : ""}`} onClick={() => setCommentReply(!commentReply)}></i>
              }

              <i data-tip="View email notifications sent" onClick={() => setOpenEmailLogs(true)} class="fal fa-envelope-open-text btn btn-small mr-5 background-hover color-1 cursor-pointer opacity-6 opacity-10-hover"></i>
              <Modal open={openEmailLogs} onClose={() => setOpenEmailLogs(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
                <EmailLogs emailable_type={"TaskComment"} emailable_id={task_comment.id} closeModal={() => setOpenEmailLogs(false)} />
              </Modal>

              <div class="btn-group">
                <a type="button" class="btn btn-small color-1 dropdown-toggle opacity-6 opacity-10-hover background-hover btn-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="far fa-ellipsis-v"></i>
                </a>
                <ul class="dropdown-menu pull-right animated fadeInUp">
                  {task_comment.owner &&
                    <>
                      {task_comment.editable ?(
                        <React.Fragment>
                          <li>
                            <a onClick={() => setOpen(true)}>
                              <i class="far fa-pencil"></i>Edit
                            </a>
                          </li>
                          
                          <li>
                            <a onClick={deleteComment}>
                              <i class="far fa-trash"></i>Delete
                            </a>
                          </li>
                          
                        </React.Fragment> 
                      ):(
                        <React.Fragment>
                          <li>
                            <a disabled={true} onClick={() => setOpen(true)}>
                              <i class="far fa-pencil"></i>Edit
                            </a>
                          </li>
                          
                          <li>
                            <a disabled onClick={deleteComment}>
                              <i class="far fa-trash"></i>Delete
                            </a>
                          </li>
                        </React.Fragment> 
                      )}
                    </>
                  }
                  <li>
                    <a onClick={resendEmail} className="">
                      <i class="fal fa-envelope-open-text mr-8"></i>Resend email to client
                    </a>
                  </li>
                  <li class="divider"></li>
                  <li>
                    <a disabled className="font-12 opacity-4 pointer-events-none">
                      <i class="fal fa-exclamation-triangle mr-8"></i>Editing will be disabled after 15min
                    </a>
                  </li>
                </ul>
              </div>
              <span className="ml-5 display-flex">
                
              </span>
            </div>
          </div>
          <p className="color-3">
            <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
              <a href={decoratedHref} key={key} target="_blank">{decoratedText}</a>
            )}>
              <DisplayHTML htmlContent={task_comment.comment} classNames="full-card-description tiptap-render"/>
            </Linkify>

            <div className="task-comment-reactions">
              {reactions_list}
            </div>
          </p>
          
          {task_comment.loom_link != null && 
            <div className="field">
              <iframe className="task-comment-loom-link-iframe" src={`https://www.loom.com/embed/${task_comment.loom_link.substring(task_comment.loom_link.lastIndexOf('/') + 1)}`} frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
            </div>
          }
          
          {task_comment.replays.length > 0 && 
            <div className="field file-uploaded-icon-wrapper" style={{flexDirection: "column"}}>
              {task_comment_replays_list}
            </div>
          }

          {task_comment.uploadingReplay && 
            <div className="daas-task-replays-wrapper align-items-start" style={{flexDirection: "column"}}>
              <div>
                <i className="fal fa-spinner fa-spin mr-8"></i>Creating review link...
              </div>
            </div>
          }

          {nested_task_comments}

          {commentReply && 
            <Edit task={task} refetchData={refetchData} projectAuthorizations={projectAuthorizations} project_id={project_id} users={users} editing={false} closeModal={() => setOpen(false)} parent_id={task_comment.token} setTaskComments={setTaskComments} taskComments={taskComments}/>
          }
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <Edit task={task} refetchData={refetchData} projectAuthorizations={projectAuthorizations} project_id={project_id} users={users} taskComment={task_comment} closeModal={() => setOpen(false)} parent_id={null}/>
      </Modal>
    </React.Fragment>
  )
}

export default TaskComment;