import React from 'react';

const PaperTrailTable = ({ version, index }) => {
  return (
    <table className="table react-table-table border-all border-radius background-3">
      <thead>
        <tr>
          <th>Version</th>
          <th>User</th>
          <th>Created at</th>
          <th>Title</th>
          <th>Description</th>
          <th>Column</th>
          <th>Status</th>
          <th>Priority</th>
          <th>Deadline</th>
          <th>Privacy</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{index + 1}</td>
          <td>{version.user?.username}</td>
          <td>{version.created_at}</td>
          <td>{version.object.title}</td>
          <td>
            <div dangerouslySetInnerHTML={{ __html: version.object.description }} />
          </td>
          <td>{version.object.column}</td>
          <td>{version.object.status}</td>
          <td>{version.object.priority}</td>
          <td>{version.object.deadline}</td>
          <td>{version.object.privacy}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default PaperTrailTable;