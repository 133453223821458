import React, { useState, useRef } from 'react';
import axios from "axios"; 
import OtpInput from 'react-otp-input';

const VerifyCode = ({refetchData, email, organization, setStep}) => {
  const [disabled, setDisabled] = useState(false)
  const [otp, setOtp] = useState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/verify_code`, {
      code: otp, 
      email: email
    })
    .then(function(response){
      if(response.data.success){
        if(refetchData != null){
          refetchData();
        } else {
          const url_params = new URLSearchParams(window.location.search);
          const redirectUrl = url_params.get('redirect_url') || "/";
          window.location.href = redirectUrl;
        }
      } else {
        notice("Invalid code")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occurred")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const resendCode = () => {
    axios.post(`/api/check_account`, {
      email: email
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Code resent")
      } else if (response.data.error) {
        notice(response.data.error)
      } else {
        setStep("Signup")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }
  

  return(
    <React.Fragment>
        <form onSubmit={handleSubmit}>
          <div class="field text-center mb-25">
            {organization != null && 
              <img src={organization.avatar} className="avatar-md mb-10" />
            }
            <h3 class="font-weight-600">Login</h3>
            <p className="opacity-7">Please enter the code sent to <strong>{email}</strong>. Please check spam folder too.</p>
          </div>
          
          <div className="field login-code-wrapper">
            <OtpInput
              shouldAutoFocus={true}
              inputStyle="otp-input"
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderInput={(props) => <input {...props} />}
              inputType="number"
            />
          </div>
          <div className="field mt-30">
            <button className="btn btn-primary btn-block" disabled={disabled}>Login</button>
          </div>
        </form>

        <div class="form-group text-center">
          <p className="font-12">
            <a class="color-1 btn btn-small background-hover" onClick={() => setStep("Check")}>Change email</a>  | <a class="color-1 btn btn-small background-hover" onClick={resendCode}>Resend code</a>
          </p>
        </div>
    </React.Fragment>
  )
}
export default VerifyCode;