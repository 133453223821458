import React , { useContext, Portal } from 'react';
import {OrganizationContext} from "../../../../Shared/OrganizationContext.js";
import {Menu, Item, useContextMenu} from "react-contexify";

const AssignUsersForm = ({setSelectedUsers, selectedUsers}) => {
  const {organizationUsers} = useContext(OrganizationContext);

  return(
    <React.Fragment>
      <div className="btn-group dropup">
        <button type="button" className="btn btn-default btn-small dropdown-toggle background-hover-2" data-toggle="dropdown">
          <i className="fal fa-user mr-8"></i>
          {selectedUsers.length > 0 ? `${selectedUsers.length} assigned` : 'Assignee'}
        </button>

        <ul className="dropdown-menu animated fadeInUp" role="menu" style={{maxHeight: '300px', overflowY: 'auto'}}>
          {organizationUsers.map((organization_user) => {
            const isSelected = selectedUsers.find(u => u.value === organization_user.user.id);
            return (
              <li key={organization_user.user.id}>
                <a onClick={(e) => {
                  e.stopPropagation();
                  if (isSelected) {
                    setSelectedUsers(selectedUsers.filter(u => u.value !== organization_user.user.id));
                  } else {
                    setSelectedUsers([...selectedUsers, {
                      value: organization_user.user.id,
                      label: organization_user.user.username, 
                      avatar: organization_user.user.avatar, 
                      email: organization_user.user.email,
                    }]);
                  }
                }}>
                  <i className={`fal fa-${isSelected ? 'check-square' : 'square'} mr-8`}></i>
                  {organization_user.user.username}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default AssignUsersForm;