import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import Select from 'react-select';
// import {UserContext} from "../../Shared/UserContext.js";
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import TipTap from "../Shared/TipTap.js";
import SubtaskItem from "./SubtaskItem.js";
import NewLabel from "../Labels/NewLabel.js";
import { ReactSortable } from "react-sortablejs";
import { confirmAlert } from 'react-confirm-alert'; 
import {TaskboardContext} from "../Shared/TaskboardContext.js";

const Form = ({taskTemplate, closeModal}) => {
  // const {} = useContext(TaskboardContext);
  const {fetchTaskTemplates, organizationUsers, labels, fetchLabels, setTaskTemplates, taskTemplates} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [openNewLabel, setOpenNewLabel] = useState(false);
  const [templateName, setTemplateName] = useState(taskTemplate?.title || "");
  const [title, setTitle] = useState(taskTemplate?.task_data?.title || "");
  const [description, setDescription] = useState(taskTemplate?.task_data?.description || "");
  const [users, setUsers] = useState(taskTemplate?.task_data?.users || []);
  const [priority, setPriority] = useState(taskTemplate?.task_data?.priority || null);
  const [selectedLabels, setSelectedLabels] = useState(taskTemplate?.task_data?.task_labels || []);
  const [relativeDueDays, setRelativeDueDays] = useState(taskTemplate?.relative_due_days || null);
  const [subtasks, setSubtasks] = useState(taskTemplate?.subtasks_data || []);
  const [isPrivate, setIsPrivate] = useState(taskTemplate?.task_data?.private || false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    const payload = {
      title: templateName,
      task_data: {
        title: title,
        description: description,
        task_labels: selectedLabels,
        priority: priority,
        users: users,
        private: isPrivate,
      },
      relative_due_days: relativeDueDays,
      subtasks_data: subtasks,
    };

    const request = taskTemplate
      ? axios.put(`/api/o/${match.params.organization_id}/task_templates/${taskTemplate.token}`, payload)
      : axios.post(`/api/o/${match.params.organization_id}/task_templates`, payload);

    request
      .then(function(response){
        if(response.data.success){
          notice(taskTemplate ? "Template updated" : "Template created");
          fetchTaskTemplates();
          closeModal();
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
  }

  const handleDelete = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'This will permanently delete it. So proceed with caution.',
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Delete',
          className: 'btn btn-danger',
          onClick: () => {
            axios.delete(`/api/o/${match.params.organization_id}/task_templates/${taskTemplate?.token}`)
              .then(function(response){
                if(response.data.success){
                  setTaskTemplates(taskTemplates.filter(template => template.token !== taskTemplate.token));
                  closeModal();
                  notice("Template deleted")
                }
              })
          }
        },
      ]
    });
  }

  return(
    <React.Fragment>
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label>Template name</label>
          <input required defaultValue={templateName} className="form-control" type="text" name="title" placeholder="Name your template. Make it short." onChange={(evt) => setTemplateName(evt.target.value)}/>
        </div>

        <hr />
        <div className="field">
          <label>Task Title</label>
          <input defaultValue={title} className="form-control" type="text" name="title" placeholder="Title" required onChange={(evt) => setTitle(evt.target.value)}/>
        </div>

        <div className="field">
          <label>Description</label>
          <TipTap comment={description} setComment={setDescription} users={[]}/>
        </div>

        {subtasks.length > 0 && 
          <div className="field">
            <label>Subtasks</label>
            <div className="task-template-subtasks-list">
              <ReactSortable
                list={subtasks}
                setList={setSubtasks}
                animation={150}
                handle=".subtask-drag-handle"
              >
                {subtasks.map((subtask, index) => (
                  <SubtaskItem
                    key={index}
                    subtask={subtask}
                    setSubtasks={setSubtasks}
                    subtasks={subtasks}
                    index={index}
                  />
                ))}
              </ReactSortable>
            </div>
          </div>
        }

        <div className="field">
          {/* ASSIGN */}
          <div className="btn-group dropup mr-8">
            <button type="button" className="btn btn-default btn-small dropdown-toggle background-hover-2" data-toggle="dropdown">
              <i className="fal fa-user mr-8"></i>
              {users.length > 0 ? `${users.length} users selected` : 'Assign to'}
            </button>

            <ul className="dropdown-menu animated fadeInUp" role="menu">
              {organizationUsers.map((organization_user) => {
                const isSelected = users.find(u => u.value === organization_user.user.id);
                return (
                  <li key={organization_user.user.id}>
                    <a onClick={(e) => {
                      e.stopPropagation();
                      if (isSelected) {
                        setUsers(users.filter(u => u.value !== organization_user.user.id));
                      } else {
                        setUsers([...users, {
                          value: organization_user.user.id,
                          label: organization_user.user.username, 
                          avatar: organization_user.user.avatar, 
                          email: organization_user.user.email,
                        }]);
                      }
                    }}>
                      <i className={`fal fa-${isSelected ? 'check-square' : 'square'} mr-8`}></i>
                      {organization_user.user.username}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          {/* PRIORITY */}
          <div class="btn-group dropup">
            <button type="button" class="btn btn-default btn-small dropdown-toggle background-hover-2" data-toggle="dropdown">
              {priority ? (
                <>
                  <i className={`fas fa-circle mr-8 ${priority}`}></i>
                  {priority}
                </>
              ):(
                <>
                  <i className={`fal fa-circle mr-8 ${priority ? priority : ""}`}></i>
                  Priority
                </>
              )}  
            </button>

            <ul class="dropdown-menu animated fadeInUp" role="menu">
              <li><a onClick={() => setPriority(null)}><i className="fal fa-circle mr-8"></i>None</a></li>
              <li><a onClick={() => setPriority("Low")}><i className="fas fa-circle mr-8 low"></i>Low</a></li>
              <li><a onClick={() => setPriority("Medium")}><i className="fas fa-circle mr-8 medium"></i>Medium</a></li>
              <li><a onClick={() => setPriority("High")}><i className="fas fa-circle mr-8 high"></i>High</a></li>
            </ul>
          </div>
          
          {/* LABEL */}
          <div className="btn-group dropup ml-8">
            <button type="button" className="btn btn-default btn-small dropdown-toggle background-hover-2" data-toggle="dropdown">
              <i className="fal fa-tag mr-8"></i>
              {selectedLabels.length > 0 ? `${selectedLabels.length} labels selected` : 'Select labels'}
            </button>

            <ul className="dropdown-menu animated fadeInUp" role="menu" style={{maxHeight: '300px', overflowY: 'auto'}}>
              {labels.map((label) => {
                const isSelected = selectedLabels.find(l => l.id === label.id);
                return (
                  <li key={label.id}>
                    <a onClick={(e) => {
                      e.stopPropagation();
                      if (isSelected) {
                        setSelectedLabels(selectedLabels.filter(l => l.id !== label.id));
                      } else {
                        setSelectedLabels([...selectedLabels, label]);
                      }
                    }}>
                      <i className={`fal fa-${isSelected ? 'check-square' : 'square'} mr-8`}></i>
                      <i className={`fas fa-circle`} style={{ color: `#${label.color}` }}></i>
                      {label.title}
                    </a>
                  </li>
                );
              })}

              <li><a onClick={() => setOpenNewLabel(true)}><i className="fal fa-plus mr-8"></i>Add new label</a></li>
            </ul>
          </div>

          {/* DUE DATE */}
          <div className="btn-group dropup ml-8">
            <button type="button" className="btn btn-default btn-small color-1 ml-8 dropdown-toggle background-hover-2" data-toggle="dropdown">
              <i className="fal fa-calendar-alt mr-8"></i>
              {relativeDueDays ? `Due in ${relativeDueDays} days` : 'Relative due date'}
            </button>

            <ul className="dropdown-menu animated fadeInUp" role="menu">
              <li><a onClick={() => setRelativeDueDays(null)}><i className="fal fa-calendar-minus mr-8"></i>No due date</a></li>
              <li><a onClick={() => setRelativeDueDays(1)}><i className="fal fa-calendar-day mr-8"></i>In 1 day</a></li>
              <li><a onClick={() => setRelativeDueDays(2)}><i className="fal fa-calendar-day mr-8"></i>In 2 days</a></li>
              <li><a onClick={() => setRelativeDueDays(3)}><i className="fal fa-calendar-day mr-8"></i>In 3 days</a></li>
              <li><a onClick={() => setRelativeDueDays(7)}><i className="fal fa-calendar-week mr-8"></i>In 1 week</a></li>
              <li><a onClick={() => setRelativeDueDays(30)}><i className="fal fa-calendar-alt mr-8"></i>In 1 month</a></li>
              <li className="divider"></li>
              <li>
                <div className="default-padding pt-0 pb-0">
                  <input 
                    placeholder="Custom days"
                    type="number" 
                    className="form-control" 
                    min="1"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setRelativeDueDays(parseInt(e.target.value))}
                  />
                </div>
              </li>
            </ul>
          </div>

          <a className="btn btn-default btn-small background-hover-2 ml-8" onClick={() => setSubtasks([...subtasks, { title: "", labels: [], users: [] }])}>
            {subtasks.length > 0 ? (
              <>
                <i className="fal fa-layer-group mr-8"></i>
                {subtasks.length} subtasks
              </>
            ) : (
              <>
                <i className="fal fa-layer-plus mr-8"></i>
                Add subtasks
              </>
            )}
          </a>

          <a className={`btn btn-small btn-default ml-8 ${isPrivate ? "btn-warning" : ""}`} onClick={() => setIsPrivate(!isPrivate)}><i className="fal fa-lock mr-8"></i>Private</a>
        </div>

        <div className="text-right">
          {taskTemplate && (
            <a className="btn btn-danger-outline mr-15" onClick={handleDelete}>Delete</a>
          )}
          <button type="submit" className="btn btn-primary">Save</button>
        </div>
      </form>

      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => {setOpenNewLabel(false); fetchLabels();}}/>
      </Modal>
    </React.Fragment>
  )
}

export default Form;