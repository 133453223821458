import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; 
import {OrganizationContext} from "../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import {TaskboardContext} from "../Shared/TaskboardContext.js";
import { confirmAlert } from 'react-confirm-alert';

const NewLabel = ({closeModal, label}) => {
  const {setLabels} = useContext(OrganizationContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(label?.title || "");
  const [color, setColor] = useState(label?.color || "4169E1");
  const [colorClass, setColorClass] = useState(label?.color_class || "background-blue");
  const [colorTitle, setColorTitle] = useState(label?.color_title || "Blue");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);
    
    axios.post(`/api/o/${match.params.organization_id}/labels`, {
      title: title,
      color: color
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setLabels(prevLabels => [...prevLabels, response.data.label]);
        setTitle("");
        closeModal();
        // fetchLabels();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
      setTitle("");
      setColor("4169E1")
      setColorClass("background-blue");
      setColorTitle("Blue")
    });
  }

  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);
    
    axios.put(`/api/o/${match.params.organization_id}/labels/${label?.token}`, {
      title: title,
      color: color
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        setLabels(prevLabels => prevLabels.map(l => l.token === label.token ? response.data.label : l));
        notice("Label updated");
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const labelOptions = [
    { color: '4169E1', title: 'Blue', class: "background-blue" },
    { color: 'ea2c39', title: 'Red', class: "background-red" },
    { color: '00c292', title: 'Green', class: "background-green" },
    { color: 'ffab58', title: 'Orange', class: "background-orange" },
    { color: 'f5c402', title: 'Yellow', class: "background-yellow" },
    { color: '800080', title: 'Purple', class: "background-purple" }
  ];

  const label_colors = labelOptions.map(option => {
    return(
      <li>
        <a onClick={() => {setColor(option.color); setColorClass(option.class); setColorTitle(option.title)}} className="text-black-only"><span className={`label-color ${option.class}`}></span>{option.title}</a>
      </li>
    )
  })

  // delete label
  const removeLabel = (e) => {
    e.preventDefault();
    e.stopPropagation(); // Stop event propagation

    confirmAlert({
      title: 'Are you sure you want to delete this label?',
      message: (
        <div style={{ opacity: 0.6 }}>
          This will delete this label and remove it from all tasks that it was assigned to. 
          <br /> <br />
          If you want to remove the label only from this task, click cancel and then click on the labels name.
        </div>
      ),
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Delete',
          className: 'btn btn-danger',
          onClick: () => {
            axios.delete(`/api/o/${match.params.organization_id}/labels/${label?.token}`)
            .then(function(response){
              // console.log(response);
              if(response.data.success){
                setLabels(prevState => _.reject(prevState, { token: label?.token }));
                closeModal();
                // refetchData();
              } else {
                response.data.errors.forEach((error) => {
                  notice(error);
                });
              }
            })
            .catch(function(error){
              console.log(error)
              reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
              refetchData();
            })
            .then(function () {
              setDisabled(false);
            });
          }
        },
      ]
    });
  }

  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Create a label</h4>

      <div className="row no-margin">
        <form onSubmit={label ? handleUpdate : handleSubmit}>          
          <div class="input-group">
            <div class="input-group-btn">
              <a type="button" class="btn background-hover dropdown-toggle min-width-unset display-flex align-items-center background-3 color-1 border-all" data-toggle="dropdown">
                <span className={`label-color ${colorClass}`}></span> {colorTitle}
                <i class="far fa-angle-down ml-15"></i>
              </a>
              <ul class="dropdown-menu">
                {label_colors}
              </ul>
            </div>

            <input defaultValue={title} className="form-control" placeholder="New label" onChange={(e) => setTitle(e.target.value)}/>
            <span class="input-group-btn">
              <a onClick={label ? handleUpdate : handleSubmit} disabled={disabled} class="btn btn-primary" type="submit">{label ? "Update label" : "Create label"}</a>
            </span>
          </div>
        </form>

        {label && 
          <div className="mt-10 text-center mt-15">
            <a onClick={removeLabel} className="btn btn-small background-hover color-red opacity-5 opacity-10-hover">Delete label</a>
          </div>
        }
      </div>
      
    </React.Fragment>
  )
}

export default NewLabel;