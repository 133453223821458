import React, { useState, useEffect, useContext } from 'react';
import axios from "axios"; 
import { PickerOverlay, PickerInline } from 'filestack-react';
import {UserContext} from "../../Shared/UserContext.js";
import "@lottiefiles/lottie-player";
import animationData from "../../Shared/Lotties/lottie-uploading.json";
import finished from "../../Shared/Lotties/lottie-finished.json";
import { Player } from "@lottiefiles/react-lottie-player";

const Filestack = ({project_id, folder_id, task_id, refetchData, closeParentModal, parent_replay_id}) => {
  const currentUser = useContext(UserContext);
  const maxFileSize = currentUser.subdomain_organization.subscribed ? 10 * 1024 * 1024 * 1024 : 5 * 1024 * 1024 * 1024; // 10GB for subscribed users, 1GB for others
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const createReplay = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    console.log(filesUploaded)
    uploadReplays(filesUploaded);
  }

  const uploadReplays = (files) => {
    setUploading(true);
    axios.post(`/api/replays`, {
      files: files,
      filestack: true, 
      project_id: project_id, 
      folder_id: folder_id, 
      task_id: task_id,
      replay: {
        create_ancestry_id: parent_replay_id
      }
    })
    .then(function(response){
      console.log(response)
      if(response.data.success){ 
        setUploaded(true);
        refetchData();
        setTimeout(function() {
          closeParentModal();
        }, 2000);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occurred")
    });
  }

  return(
    <React.Fragment>
      {uploading ? (
        <>
        {uploaded ? (
          <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={finished} />
        ):(
          <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={animationData} />
        )}
          <h5 className="text-center">Finishing upload...</h5>
        </>
      ):(
        <PickerInline
          apikey={"AKVhxMbEQhkOIryqBvSEQz"}
          onSuccess={(res) => console.log(res)}
          onUploadDone={(res) => {
            setUploading(true);
            createReplay(res);
          }}
          clientOptions={{
            sessionCache:true
          }}
          pickerOptions={{
            onClose: () => {
              // closeParentModal();
            }, 
            fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
            maxFiles: 50,
            maxSize: maxFileSize, 
            storeTo: {
              location: 'gcs',
              path: '/',
              container: 'queue-filestack'
            }
          }}
        />
      )}
      
        {/* {currentUser.subdomain_organization.tiered && 
          <p className="text-center font-12 mt-15 opacity-6">File size limit is 1GB</p> 
        } */}
    </React.Fragment>
  )
}

export default Filestack