import React , { useState, useEffect} from 'react';
import axios from "axios"; 

const ApproveModal = ({replay, refetchData, closeModa, approve}) => {
  const [disabled, setDisabled] = useState(false);
  const [approved, setApproved] = useState(true);

  useEffect(() => {
    if(approve != null){
      setApproved(approve);
    }
  }, [approve]);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);
    axios.post(`/api/replays/${replay.token}/approvals`, {
      comment: document.getElementById("comment").value == "" ? null : document.getElementById("comment").value,
      approved: approved
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData();
        closeModa();
      } else {
        notice("Uh oh! an error occured")
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
       <form onSubmit={handleSubmit} className="row no-margin color-black-always" id="review_link">
        <h4 className="modal-title">Submit review</h4>
        
        <div className="field">
          <div className="font-600 mb-10">Approval</div>
          <div className="display-column">
            <div>
              <input type="radio" id="signoff" name="select" value="final" className="mr-8" style={{display: "unset"}} checked={approved === true} onChange={() => setApproved(true)}/>
              <label for="signoff" className="font-weight-400">Approved</label>
            </div>

            <div>
              <input type="radio" id="changes" name="select" value="requestChanges" className="mr-8 mb-10" style={{display: "unset"}} onChange={() => setApproved(false)} checked={approved === false}/>
              <label for="changes" className="font-weight-400">Request changes</label>
            </div>
          </div>
        </div>

        <div className="field">
          <div className="font-600 mb-10">Comment</div>
          <textarea rows="4" class="form-control" name="comment" id="comment" placeholder="Optional" style={{maxWidth: "100%"}}/>
        </div>
        
        <div className="field flex" style={{justifyContent: "end"}}>
          <button type="submit" disabled={disabled} className="btn btn-small btn-primary">Submit</button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default ApproveModal