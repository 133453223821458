import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import PaperTrailTable from "./PaperTrailTable.js";

const PaperTrail = ({project_id, task_id}) => {
  const [versions, setVersions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetchPaperTrail();
  }, []);

  const fetchPaperTrail = () => {
    axios.get(`/api/portal/projects/${project_id}/tasks/${task_id}/paper_trail.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setVersions(response.data.versions);
        setLoaded(true);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      // always executed
    });
  }

  const paper_trails_list = versions.map((version, index) => {
    return(
      <PaperTrailTable key={version.id} version={version} index={index}/>
    )
  })

  return(
    loaded &&
    <React.Fragment>
      <div className="warning">
        <i className="fal fa-exclamation-triangle mr-5"></i>
        Everytime this task is updated we keep the previous version here. This is in beta, so if you'd like to see additional details please contact us through https://roadmap.usequeue.com. Note: This is not a backup, it is only for you to see the changes and we only show you the task information such as title, description, status, priority, deadline, privacy, etc.
      </div>
      {paper_trails_list}

      {versions.length === 0 && (
        <div className="text-center opacity-5 font-12">
          No changes to show
        </div>
      )}
    </React.Fragment>
  )
}

export default PaperTrail;