import React, { useContext, useState } from 'react';
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import { Modal } from 'react-responsive-modal';
import TipTap from '../Shared/TipTap.js';

const SubtaskItem = ({ subtask, index, setSubtasks, subtasks }) => {
  const {organizationUsers, labels} = useContext(OrganizationContext);
  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState(subtask.description);
  const [relativeDueDays, setRelativeDueDays] = useState(subtask?.relative_due_days || null);
  
  const updateSubtaskDescription = (newDescription) => {
    setDescription(newDescription);
    setSubtasks(subtasks.map((s, i) => 
      i === index ? { ...s, description: newDescription } : s
    ));
  };

  const removeSubtask = (index) => {
    setSubtasks(subtasks.filter((_, i) => i !== index));
  };

  const updateSubtaskTitle = (index, newTitle) => {
    setSubtasks(subtasks.map((subtask, i) => 
      i === index ? { ...subtask, title: newTitle } : subtask
    ));
  };

  const toggleLabel = (labelId, e) => {
    e.stopPropagation();
    setSubtasks(subtasks.map((s, i) => {
      if (i === index) {
        const currentLabels = s.labels || [];
        const label = labels.find(l => l.id === labelId);
        const hasLabel = currentLabels.some(l => l.id === labelId);
        return {
          ...s,
          labels: hasLabel
            ? currentLabels.filter(l => l.id !== labelId)
            : [...currentLabels, label]
        };
      }
      return s;
    }));
  };

  const toggleUser = (userId, e) => {
    e.stopPropagation();
    setSubtasks(subtasks.map((s, i) => {
      if (i === index) {
        const currentUsers = s.users || [];
        const hasUser = currentUsers.some(u => u.id === userId);
        const user = organizationUsers.find(u => u.user.id === userId)?.user;
        return {
          ...s,
          users: hasUser
            ? currentUsers.filter(u => u.id !== userId)
            : [...currentUsers, user]
        };
      }
      return s;
    }));
  };

  const setRelativeDueDaysForSubtask = (days) => {
    setRelativeDueDays(days);
    setSubtasks(subtasks.map((s, i) => 
      i === index ? { ...s, relative_due_days: days } : s
    ));
  };

  const subtask_labels_list = subtask?.labels
    ?.filter(label => labels.some(l => l.id === label.id))
    .map(label => (
      <span key={label.id} className="badge" style={{color: "white", backgroundColor: `#${label.color}`}}>{label.title}</span>
    ));

  const subtask_users_list = (subtask?.users || [])
    ?.filter(user => organizationUsers.some(orgUser => orgUser.user.id === user.id))
    .map(user => (
      <span key={user.id} className="badge-user ml-15-negative">
        <img src={user.avatar} className="avatar-small mr-5" />
      </span>
    ));

  return (
    <div key={index} className="task-template-subtask d-flex align-items-center">
      <div className="subtask-drag-handle">
        <i className="fas fa-grip-vertical opacity-4 mr-15"></i>
      </div>
      <i className="fal fa-circle"></i>
      <input
        type="text"
        className="form-control flex-grow-1 mr-15 border-none background-none width-100"
        placeholder="Subtask title"
        value={subtask.title}
        onChange={(e) => updateSubtaskTitle(index, e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            // Only add new subtask if current one isn't empty
            if (subtask.title.trim()) {
              setSubtasks([...subtasks, { title: "", labels: [], users: [] }]);
              // Focus the new input after React re-renders
              setTimeout(() => {
                const inputs = document.querySelectorAll('.task-template-subtask input');
                inputs[inputs.length - 1]?.focus();
              }, 0);
            }
          } else if (e.key === 'Backspace' && !subtask.title && subtasks.length > 1) {
            // Remove empty subtask when pressing backspace
            e.preventDefault();
            removeSubtask(index);
            // Focus previous input
            setTimeout(() => {
              const inputs = document.querySelectorAll('.task-template-subtask input');
              inputs[index - 1]?.focus();
            }, 0);
          }
        }}
      />
      <div className="btn-group">
        <a type="button" className="opacity-4 opacity-10-hover btn background-hover color-1 btn-small dropdown-toggle" data-toggle="dropdown">
          <i className="far fa-ellipsis-v"></i>
        </a>
        <ul className="dropdown-menu animated fadeInUp" role="menu">
          <li className="dropdown-submenu">
            <a tabIndex="-1"><i className="fal fa-calendar-alt"></i>Relative due date</a>
            <ul className="dropdown-menu animated fadeInUp" role="menu">
              <li><a onClick={() => setRelativeDueDaysForSubtask(null)}><i className="fal fa-calendar-minus mr-8"></i>No due date</a></li>
              <li><a onClick={() => setRelativeDueDaysForSubtask(1)}><i className="fal fa-calendar-day mr-8"></i>In 1 day</a></li>
              <li><a onClick={() => setRelativeDueDaysForSubtask(2)}><i className="fal fa-calendar-day mr-8"></i>In 2 days</a></li>
              <li><a onClick={() => setRelativeDueDaysForSubtask(3)}><i className="fal fa-calendar-day mr-8"></i>In 3 days</a></li>
              <li><a onClick={() => setRelativeDueDaysForSubtask(7)}><i className="fal fa-calendar-week mr-8"></i>In 1 week</a></li>
              <li><a onClick={() => setRelativeDueDaysForSubtask(30)}><i className="fal fa-calendar-alt mr-8"></i>In 1 month</a></li>
              <li className="divider"></li>
              <li>
                <div className="default-padding pt-0 pb-0">
                  <input 
                    placeholder="Custom days"
                    type="number" 
                    className="form-control" 
                    min="1"
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => setRelativeDueDaysForSubtask(parseInt(e.target.value))}
                  />
                </div>
              </li>
            </ul>
          </li>

          <li className="dropdown-submenu">
            <a tabIndex="-1"><i className="fal fa-tag"></i>Labels</a>
            <ul className="dropdown-menu animated fadeInUp">
              {labels.map(label => (
                <li key={label.id}>
                  <a onClick={(e) => toggleLabel(label.id, e)}>
                    <i className={`far ${(subtask.labels || []).some(l => l.id === label.id) ? 'fa-check-square' : 'fa-square'}`}></i>
                    <span className="ml-5" style={{color: label.color}}>{label.title}</span>
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li className="dropdown-submenu">
            <a tabIndex="-1"><i className="fal fa-user"></i>Assign Users</a>
            <ul className="dropdown-menu animated fadeInUp">
              {organizationUsers.map(organization_user => (
                <li key={organization_user.user.id}>
                  <a onClick={(e) => toggleUser(organization_user.user.id, e)}>
                    <i className={`far ${(subtask.users || []).some(u => u.id === organization_user.user.id) ? 'fa-check-square' : 'fa-square'}`}></i>
                    <img src={organization_user.user.avatar} className="avatar-small mr-5" />
                    <span className="ml-5">{organization_user.user.username}</span>
                  </a>
                </li>
              ))}
            </ul>
          </li>
          <li><a onClick={() => setOpen(true)}><i className="fal fa-pencil-alt"></i>Description</a></li>
          <li><a onClick={() => removeSubtask(index)}><i className="fal fa-trash-alt"></i>Remove</a></li>
        </ul>
      </div>

      <div className="task-template-subtask-labels display-flex align-items-center">
        {relativeDueDays && <>
          <span className="badge border-all"><i className="fal fa-calendar-day mr-8"></i>{relativeDueDays} days</span>
        </>}
        {subtask_labels_list}
        <div className="ml-15">
          {subtask_users_list}
        </div>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <h4 className="color-black-always modal-title">Description</h4>

        <div className="row no-margin">
          <TipTap 
            comment={description} 
            setComment={updateSubtaskDescription} 
            users={[]} 
            classNames="field-tiptap"
          />
        </div>
      </Modal>
    </div>
  );
};

export default SubtaskItem; 