import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import { TaskFormContext } from "./TaskFormContext.js";

const AiForm = ({setChoosePath, setUseAi}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const {setAiGenerated, setSections} = useContext(TaskFormContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [generating, setGenerating] = useState(false);

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);
    setGenerating(true);

    axios.post(`/api/openai_task_form_generator_2`, {
      description: description
    })
    .then(function(response){
      console.log(response);
      setSections(JSON.parse(response.data.response.choices[0].message.content).sections);
      setAiGenerated(true);
      setChoosePath(false);
      if(response.data.success){
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
      setGenerating(false);
    });
  }


  return(
    <React.Fragment>
      <div className="display-flex">
        <a className="color-1 btn btn-small background-hover opacity-5 opacity-10-hover" onClick={() => setUseAi(false)}>
          <i className="fas fa-arrow-left"></i> Back
        </a>
      </div>

      <div className="large-padding">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Describe what the client will be requesting</label>
            <div className="textarea-gradient-border">
              <textarea 
                autoFocus
                className="form-control textarea-gradient-content" 
                rows="4" 
                placeholder="e.g. This form is for submitting a new request for a video editing service for youtube reels. " 
                value={description} 
                onChange={(e) => setDescription(e.target.value)} 
              />
            </div>
            <p className="font-13 opacity-6">💡 This is currently in beta. Help us improve it <a href="https://roadmap.usequeue.com" target="_blank">here</a>.</p>
          </div>

          <div className="field text-right">
            <button disabled={disabled || !description} type="submit" className="btn btn-primary">
              {generating ? <><i className="fas fa-spinner fa-spin"></i> Generating...</> : "Generate form"}
            </button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default AiForm;