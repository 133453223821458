import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom';
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../Shared/AppContext.js';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Field from "./Field.js";

const AdditionalSection = ({field, setFields}) => {
  const { notice } = useContext(AppContext);
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // fetchSomething();
  }, []);

  const fetchSomething = () => {
    // axios.get(`/api/`)
    // .then(function(response){
    //   if(response.data.success){
    //     // Handle success
    //   } else {
    //     // Handle failure
    //   }
    // })
    // .catch(function(error){
    //   console.log(error);
    //   notice("An error occurred");
    //   reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    // })
    // .then(function () {
    //   // always executed
    // });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/`)
      .then(function(response){
        if(response.data.success){
          // Handle success
        } else {
          response.data.errors.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error);
        notice("An error occurred");
        reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
  }

  return (
    <React.Fragment>
      <div className="task-form-edit mt-10" key={field.id}>
        <div class="display-flex gap-8 mb-10">text</div>
        <Field field={field} setFields={setFields}/>
      </div>
    </React.Fragment>
  );
}

export default AdditionalSection;