import React , { useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import {OrganizationContext} from "../../Shared/OrganizationContext.js";

const ClientUser = ({user, refetchData}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const {hasPermission} = useContext(OrganizationContext)

  const handleRemoveUser = () => {
    axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/remove_client_user`, {email: user.email})
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      <div className="display-flex justify-content-between align-items-center mb-15 hover-parent-opacity">
        <div className="display-flex align-items-center">
          <img src={user.avatar} className="avatar mr-8" />
          <div className="font-12 display-flex align-items-center">
            {user.username}
          </div>
        </div>

        <div className="display-flex align-items-center">
          {hasPermission("client_email") ?(
            <p className="font-12 opacity-6">{user.email}</p>
          ):(
            <div data-tip="You don't have permission to view this email" className="no-permission-blur font-12 opacity-6">Permission denied</div>
          )}
          <div className="ml-8 hoverx-child-opacity cursor-pointer animate" onClick={() => handleRemoveUser()}>
            <a className="opacity-4 opacity-10-hover color-1 btn btn-small background-hover">
              <i className="fas fa-times"></i>
            </a>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ClientUser;