import React , { useState, useContext, useEffect } from 'react';
import { useHistory, useRouteMatch, Link } from 'react-router-dom'
import { Draggable } from "react-beautiful-dnd";
import { Modal } from 'react-responsive-modal';
import FullCardV2 from "./FullCardV2.js";
import TaskDropdown from "./TaskForms/TaskDropdown.js";
import ReactTooltip from 'react-tooltip';
import { AppContext } from "../../../Shared/AppContext.js";
import axios from "axios"; 
import { useTranslation } from 'react-i18next';
import {useContextMenu} from "react-contexify";
import NewLabel from "../../../Labels/NewLabel.js";
import NewTaskTemplate from "../../../TaskTemplates/NewTaskTemplate.js";
import Cover from "./Task/Cover.js";
import {OrganizationContext} from "../../../Shared/OrganizationContext.js";
import {TaskboardContext} from "../../../Shared/TaskboardContext.js";
import Priority1Icon from "../../../Shared/svg/priority1.jsx";
import Priority2Icon from "../../../Shared/svg/priority2.jsx";
import Priority3Icon from "../../../Shared/svg/priority3.jsx";
import {ProjectContext} from "../../../Projects/ProjectContext.js";
import Move from "./Task/Move.js";
import TaskLabel from "../../../Labels/TaskLabel.js";
import moment from 'moment';

const Card = ({card, index, refetchData, column, openTask, setOpenTask, projectAuthorizations, project_id, disable_project_title, setArray, isSubtask }) => {
  const {isDragging} = useContext(TaskboardContext);
  const {hasPermission} = useContext(OrganizationContext)
  const {markNotificationRead} = useContext(AppContext);
  const {testers} = useContext(ProjectContext);
  const {unreadNotifications} = useContext(AppContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [showSubtasks, setShowSubtasks] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [openNewLabel, setOpenNewLabel] = useState(false);
  const [openTaskTemplate, setOpenTaskTemplate] = useState(false);
  const isPortalUrl = location.pathname.includes('/portal/');
  const [openCover, setOpenCover] = useState(false);
  const [openMoveTask, setOpenMoveTask] = useState(false);
  const [subtasks, setSubtasks] = useState(card?.subtasks || []);
  const dashboard = window.location.pathname.includes(`/o/${match.params.organization_id}/dashboard`);

  useEffect(() => {
    setSubtasks(card?.subtasks || []);
  }, [card]);

  const { t } = useTranslation();
  const { show } = useContextMenu({
    id: card.token
  });
  function displayMenu(e){
    e.stopPropagation();
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }

  const users_list = card?.users?.map(user => {
    return(
      <img src={user.avatar} className="kanban-card-users-image"/>
    )
  }); 

  const subtasks_list = subtasks?.sort((a, b) => a.position - b.position).map((subtask, index) => (
    <Card key={subtask.token} card={subtask} index={index} refetchData={refetchData} column={column} projectAuthorizations={projectAuthorizations} project_id={project_id} setOpenTask={setOpenTask} setArray={setSubtasks} isSubtask={true}/>
  ));

  const markFinished = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${card.token}`, {
      finished: !card.finished
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const card_task_labels_list = card?.task_labels?.map(task_label => {
    return(
      <TaskLabel key={task_label.token} task_label={task_label}/>
    )
  });

  const isUnreadNotification = unreadNotifications.some(notification => 
    notification.location.includes("Task") && 
    notification.notifiable_token === card.token
  );

  return(
    card != null && 
    <React.Fragment>
      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => {setOpenNewLabel(false); refetchData();}}/>
      </Modal>
      
      <Modal open={openTaskTemplate} onClose={() => setOpenTaskTemplate(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewTaskTemplate task={card} refetchData={refetchData} project_id={project_id} closeModal={() => setOpenTaskTemplate(false)}/>
      </Modal>

      <Modal open={openCover} onClose={() => setOpenCover(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <Cover task_id={card.token} refetchData={() => {refetchData();}} closeModal={() => setOpenCover(false)} cover_exists={card.cover_url != null || card.unsplash_data != null}/>
      </Modal>

      {/* <Modal open={openMoveTask} onClose={() => setOpenMoveTask(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Move task_id={card.token} refetchData={() => {refetchData();}} closeModal={() => setOpenMoveTask(false)}/>
      </Modal> */}

      <Draggable key={String(card.id)} draggableId={String(card.id)} index={index} isDragDisabled={
        card.parent_task_id != null || (
          isPortalUrl
            ? projectAuthorizations.disable_dragging || !hasPermission("update_stage_task")
            : (projectAuthorizations.disable_dragging && !projectAuthorizations.organization_user) || !hasPermission("update_stage_task") || (card.project?.parent_project_id && match.params.project_id != card.project.token)
        )
      }>
        {(provided, snapshot) => (
          <div className="position-relative hover-parent-opacity" onContextMenu={displayMenu} onClick={() => {isUnreadNotification ? markNotificationRead("Task", card._id) : null}} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
            <div className={`kanban-card default-padding background-3 background-hover hover-parent-opacity ${card.unread ? "border-red" : ""} position-relative`} onClick={() => {
                if(!isDragging){
                  if(dashboard){
                    setOpen(true); 
                  }
                  history.push(`?task_id=${card.token}`);
                }
              }
            }>
              {isUnreadNotification &&
                <span className="card-notification notification-dot-small position-absolute" style={{ top: '8px', right: '8px' }}></span>
              }
              {card.unsplash_data && 
                <div className="kanban-card-cover">
                  <img src={card.unsplash_data.urls.small} className="kanban-card-cover-image"/>
                </div>
              }
              {card.cover_url && 
                <div className="kanban-card-cover">
                  <img src={card.cover_url} className="kanban-card-cover-image"/>
                </div>
              }

              {(card.project != null && !disable_project_title && card.project.parent_project_id == null && match.params.project_id != card.project.token) && 
                <div className="display-flex align-items-center mb-8 gap-8">
                  <Link to={`/o/${match.params.organization_id}/projects/${card.project.token}/tasks`} className="card-project-link" onClick={(e) => e.stopPropagation()} style={{width: "fit-content"}}>
                  <img src={card.project.avatar} className="project-list-avatar" style={{width: 15, height: 15, marginRight: 5}}/>
                    <span className="truncate-text">{card.project.title}</span>
                  </Link>
                </div>
              }

              {(card.project?.parent_project_id && match.params.project_id != card.project.token) && 
                <div className="display-flex align-items-center mb-8 gap-8">
                  <Link to={`/o/${match.params.organization_id}/projects/${card.project.token}/tasks`} className="card-project-link" onClick={(e) => e.stopPropagation()} style={{width: "fit-content"}}>
                    <img src={card.project.avatar} className="project-list-avatar" style={{width: 15, height: 15, marginRight: 5}}/>
                    <span className="truncate-text">{card.project.title}</span>
                  </Link>
                </div>
              }
              
              <h4 className="kanban-card-title">
                <div className="kanban-card-title-wrap">
                  {card.private && 
                    <i className="fal fa-lock mr-5"></i>
                  }
                  {card.title}
                </div>
              </h4>

              <div className="kanban-card-info hover-parent-opacity">
                <div className="kanban-card-info-comments">
                  {(card.priority != "") && 
                    <div className="priority-icon-wrapper">
                      {card.priority == "Low" && <Priority1Icon/>}
                      {card.priority == "Medium" && <Priority2Icon/>}
                      {card.priority == "High" && <Priority3Icon/>}
                    </div>
                  }

                  {(subtasks && subtasks.length > 0 ) &&
                    <>
                      <span onClick={(e) => {e.stopPropagation(); setShowSubtasks(!showSubtasks)}} data-tip="Subtasks" className={`badge ${subtasks.every(subtask => subtask?.column?.stage === "done") ? "badge-green" : ""} border-all hover-scale cursor-pointer mr-5 mb-5 font-weight-500 task-deadline-wrapper`}>
                        {subtasks.every(subtask => subtask?.column?.stage === "done") ? (
                          <>
                            <i class="far fa-check-square mr-5"></i>
                          </>
                        ):(
                          <>
                            <i class="far fa-square mr-5"></i>
                          </>
                        )}
                        {subtasks.filter(subtask => subtask?.column?.stage === "done").length}/{subtasks.length}
                      </span>
                    </>
                  }

                  {card.deadline != null &&
                    <span onClick={markFinished} data-tip="Due date" className={`badge ${card.finished ? "badge-green" : card.deadline_color} cursor-default mr-5 mb-5 font-weight-500 task-deadline-wrapper`}>
                      {disabled ? (
                        <>
                          <i className="fas fa-spinner fa-spin mr-5"></i>
                        </>
                      ):(
                        <>
                          {card.finished ? (
                            <>
                              <i class="far fa-check-square mr-5 task-deadline-clock"></i>
                              <i class="far fa-square mr-5 task-deadline-check" data-tip="Mark unfinished"></i>
                            </>
                          ):(
                            <>
                              <i class="far fa-clock mr-5 task-deadline-clock"></i>
                              <i class="far fa-square mr-5 task-deadline-check" data-tip="Mark finished"></i>
                            </>
                          )}
                        </>
                      )}
                      {moment(card.deadline_raw).format('MMM D')}
                    </span>
                  }


                  {/* {card.parent_task_id != null && <span className="badge background-active ml-10">{card.column.title}</span>} */}

                  {card_task_labels_list}
                </div>
                <div className="kanban-card-users font-12">
                  {users_list}
                </div>
              </div>
            </div>
          
            {!isPortalUrl && 
              <TaskDropdown task={card} refetchData={refetchData} column={column} project_id={project_id} displayMenu={displayMenu} setOpenNewLabel={setOpenNewLabel} setOpenTaskTemplate={setOpenTaskTemplate} setOpenCover={setOpenCover} hideIcon={false} customClass="task-card-dropdown" setArray={setArray} subtask={isSubtask} />
            }
          </div>
        )}
      </Draggable>

      {showSubtasks && card.parent_task_id == null && 
        <div className="animated fadeIn" style={{marginLeft: "20px", paddingLeft: "5px", borderLeft: "1px solid var(--border-color)"}}>
          {subtasks_list}
        </div>
      }
      <Modal focusTrapped={false} closeOnEsc={false} open={open || openTask} onClose={() => {setOpen(false); history.replace(location.pathname); setOpenTask(false)}} className="modal-body-white" classNames={{modal: 'modal-fullcard'}} center>
        <FullCardV2 task_id={card.token} refetchData={refetchData} project_id={project_id}/>
      </Modal>
    </React.Fragment>
  )
}

export default Card