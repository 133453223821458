import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import {
  Menu,
  Item,
  useContextMenu,
  Submenu, 
  Separator
} from "react-contexify";
import {TaskboardContext} from "../Shared/TaskboardContext.js";

const TaskLabels = ({label, task, refetchData, setArray, isSubtask, updateNestedArray, setOpenLabelForm, setLabelFormLabel}) => {
  const {setLabels, columns, setColumns} = useContext(TaskboardContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  
  // create task label
  const createTaskLabel = () => {
    axios.post(`/api/portal/projects/${match.params.project_id}/tasks/${task.token}/task_labels`,{
      label_id: label.token
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        // refetchData();
        if(isSubtask){
          setArray(prevArray => updateNestedArray(prevArray, response.data.task_data));
        } else {
          if(setArray){
            setArray(prevState => [...prevState, response.data.task_label]);
          }
          
          setColumns(prevColumns => {
            return prevColumns.map(column => {
              if (column.title === task.column.title) {
                return {
                  ...column,
                  tasks: column.tasks.map(t => 
                    t.token === task.token ? response.data.task_data : t
                  )
                };
              }
              return column;
            });
          });
        }

        // refetchData();
        
      } else {
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
      refetchData();
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <Item className="label-item-hover-parent" closeOnClick={false} onClick={createTaskLabel}>
        <div className="display-flex align-items-center justify-content-between" style={{width: "100%"}}>
          <div className="display-flex align-items-center">
            <i className={`fal fa-square opacity-5 mr-8`}></i>
            <span className="label-color" style={{background: `#${label.color}`}}></span>
            {label.title}
          </div>

          <i className="fal fa-pencil-alt ml-15 label-item-hover-child background-hover-2" 
             onClick={(e) => {
               e.stopPropagation();
               setOpenLabelForm(true);
               setLabelFormLabel(label);
             }}>
          </i>
        </div>
      </Item>
    </React.Fragment>
  )
}

export default TaskLabels;