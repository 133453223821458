import React, { useContext } from 'react';
import { Menu, Item, Separator, Submenu, useContextMenu } from "react-contexify";
import { Portal } from 'react-portal';
import { OrganizationContext } from '../Shared/OrganizationContext.js';

const Filters = ({ 
  view, 
  setView, 
  filters, 
  setFilters, 
  users, 
  columns,
  projects, 
  setFixColumns,
  resetFilters 
}) => {
  const { labels } = useContext(OrganizationContext);
  const { show } = useContextMenu({
    id: "filter-menu"
  });

  const handleFilterClick = (event) => {
    event.preventDefault();
    show({ event });
  };

  const handleItemClick = ({ data }) => {
    switch (data.action) {
      case 'setView':
        setView(data.value);
        break;
      case 'setDeadline':
        const newDeadline = filters.deadline === data.value ? null : data.value;
        setFilters({...filters, deadline: newDeadline});
        break;
      case 'reorderByDate':
        const isCurrentSort = filters.sortBy === 'created_at' && filters.sortOrder === (data.value === 'nearest' ? 'asc' : 'desc');
        setFilters({
          ...filters,
          sortBy: isCurrentSort ? null : 'created_at',
          sortOrder: data.value === 'nearest' ? 'asc' : 'desc'
        });
        break;
      case 'togglePriority':
        const newPriorities = filters.taskPriorities.includes(data.value)
          ? filters.taskPriorities.filter(p => p !== data.value)
          : [...filters.taskPriorities, data.value];
        setFilters({...filters, taskPriorities: newPriorities});
        break;
      case 'toggleUser':
        const isSelected = filters.users.some(item => item.id === data.user.id);
        setFilters(prevFilters => ({
          ...prevFilters,
          users: isSelected
            ? prevFilters.users.filter(item => item.id !== data.user.id)
            : [...prevFilters.users, { 
                id: data.user.id, 
                username: data.user.username, 
                avatar: data.user.avatar 
              }]
        }));
        break;
      case 'toggleLead':
        const isUserSelected = filters.users.some(item => item.id === data.user.id);
        setFilters(prevFilters => ({
          ...prevFilters,
          users: isUserSelected
            ? prevFilters.users.map(item => 
                item.id === data.user.id 
                  ? { ...item, lead: !item.lead }
                  : item
              )
            : [...prevFilters.users, { 
                id: data.user.id, 
                username: data.user.username, 
                avatar: data.user.avatar, 
                lead: true 
              }]
        }));
        break;
      case 'toggleLabel':
        const newLabels = filters.labels?.some(l => l.id === data.value.id)
          ? filters.labels.filter(l => l.id !== data.value.id)
          : [...(filters.labels || []), data.value];
        setFilters({...filters, labels: newLabels});
        break;
    }
  };

  const users_list = users.map(user => {
    const isSelected = filters.users.some(item => item.id === user.id);
    
    return (
      <Item className="hover-parent-opacity display-flex align-items-center justify-content-between" key={user.id} closeOnClick={false}>
        <a className="color-1" onClick={() => {
          setFilters(prevFilters => ({
            ...prevFilters,
            users: isSelected
              ? prevFilters.users.filter(item => item.id !== user.id)
              : [...prevFilters.users, { id: user.id, username: user.username, avatar: user.avatar }]
          }));
        }}>
          <img src={user.avatar} className="avatar-small mr-8" alt={user.username}/>
          {user.username}

          {isSelected &&
            <i className="fas fa-check text-right font-12 color-blue ml-5"></i>
          }

        </a>
        <div onClick={() => {
          setFilters(prevFilters => ({
            ...prevFilters,
            users: isSelected
              ? prevFilters.users.map(item => 
                  item.id === user.id 
                    ? { ...item, lead: !item.lead }  // Toggle lead property
                    : item
                )
              : [...prevFilters.users, { id: user.id, username: user.username, avatar: user.avatar, lead: true }]
          }));
        }} className={`badge border-all background-hover-2 cursor-pointer ml-8 ${isSelected && filters.users.some(item => item.id === user.id && item.lead) ? "badge-blue task-user-lead-remove" : "hover-child-opacity badge-default"}`}>Lead</div>
      </Item>
    );
  });

  const projects_list = projects.map(project => {
    const isSelected = filters.projects.some(item => item.id === project.id);
    
    return(
      <Item 
        key={project.token} 
        onClick={() => filterByProjectId(project.id, project.title)}
        closeOnClick={false}
      >
        <div className="display-flex align-items-center justify-content-between width-100">
          {project.title}

          {isSelected &&
            <i className="fas fa-check ml-15 font-12 color-primary"></i>
          }
        </div>
      </Item>
    )
  });

  const columns_list = columns.map(column => {
    const isHidden = filters.hiddenColumns.some(item => item.title === column.title);
    
    return(
      <Item onClick={() => {
        setFilters(prevFilters => ({
          ...prevFilters,
          hiddenColumns: isHidden
            ? prevFilters.hiddenColumns.filter(item => item.title !== column.title)
            : [...prevFilters.hiddenColumns, { title: column.title }]
        }));
      }} closeOnClick={false}>
        <div className="justify-content-between align-items-center"  style={{display: "flex"}}>
          {column.title}

          {isHidden &&
            <i className="fas fa-check ml-15 font-12 color-primary"></i>
          }
        </div>
      </Item>
    )
  });

  const filterByProjectId = (projectId, projectTitle) => {
    const isProjectSelected = filters.projects.some(item => item.id === projectId);
    
    setFilters(prevFilters => ({
      ...prevFilters,
      projects: isProjectSelected
        ? prevFilters.projects.filter(item => item.id !== projectId) // Remove if exists
        : [...prevFilters.projects, { id: projectId, title: projectTitle }] // Add if doesn't exist
    }));
  };

  return (
    <>
      <a 
        className="dropdown-toggle btn-small mr-8 border-radius color-1 border-all background-hover" 
        onClick={handleFilterClick}
      >
        <i className="fal fa-sliders-h mr-8"></i> Filters
        {(filters.hiddenColumns.length > 0 || 
          filters.users.length > 0 || 
          filters.taskPriorities.length > 0 || 
          filters.projects.length > 0 || 
          filters.labels?.length > 0 ||
          filters.deadline || 
          filters.sortBy) && 
          <>
            <span className="badge ml-8 badge-blue" style={{padding: "2px 5px", borderRadius: 3}}>
              {filters.hiddenColumns.length + 
               filters.users.length + 
               filters.taskPriorities.length + 
               filters.projects.length + 
               (filters.labels?.length || 0) + 
               (filters.deadline ? 1 : 0) + 
               (filters.sortBy ? 1 : 0)
              }
            </span>
            <i className="fas fa-circle ml-8 color-blue taskboard-filters-active"></i>
          </>
        }
      </a>

      <Portal>
        <Menu id="filter-menu">
          <Submenu label={<><i className="fal fa-eye"></i>View</>}>
            <Item onClick={handleItemClick} data={{ action: 'setView', value: 'calendar' }} closeOnClick={false}>
              <i className="fal fa-calendar-alt"></i>Calendar
              {view === 'calendar' && <i className="fas fa-check ml-8 font-12"></i>}
            </Item>
            <Item onClick={handleItemClick} data={{ action: 'setView', value: 'kanban' }} closeOnClick={false}>
              <i className="fal fa-th"></i>Board
              {view === 'kanban' && <i className="fas fa-check ml-8 font-12"></i>}
            </Item>
            <Item onClick={handleItemClick} data={{ action: 'setView', value: 'list' }} closeOnClick={false}>
              <i className="fal fa-bars"></i>List
              {view === 'list' && <i className="fas fa-check ml-8 font-12"></i>}
            </Item>
            <Item onClick={handleItemClick} data={{ action: 'setView', value: 'tickets' }} closeOnClick={false}>
              <i className="fal fa-ticket-alt"></i>Tickets
              {view === 'tickets' && <i className="fas fa-check ml-8 font-12"></i>}
            </Item>
          </Submenu>

          <Submenu label={<><i className="fal fa-calendar-exclamation"></i>Deadline</>}>
            <Item onClick={handleItemClick} data={{ action: 'setDeadline', value: 'nearest' }} closeOnClick={false}>
              <i className="fal fa-calendar-exclamation color-red"></i>Approaching
              {filters.deadline === 'nearest' && <i className="fas fa-check ml-15 font-12 color-primary"></i>}
            </Item>
            <Item onClick={handleItemClick} data={{ action: 'setDeadline', value: 'furthest' }} closeOnClick={false}>
              <i className="fal fa-calendar-exclamation color-green"></i>Furthest
              {filters.deadline === 'furthest' && <i className="fas fa-check ml-15 font-12 color-primary"></i>}
            </Item>
          </Submenu>

          <Submenu label={<><i className="fal fa-users"></i>Assignee</>}>
            {users_list}
          </Submenu>

          <Submenu label={<><i className="fal fa-exclamation-circle"></i>Priority</>}>
            <Item 
              onClick={handleItemClick} 
              data={{ action: 'togglePriority', value: 'High' }}
              closeOnClick={false}
            >
              <i className="fas fa-circle color-red"></i>High
              {filters.taskPriorities.includes('High') && <i className="fas fa-check ml-15 font-12 color-primary"></i>}
            </Item>
            <Item 
              onClick={handleItemClick} 
              data={{ action: 'togglePriority', value: 'Medium' }}
              closeOnClick={false}
            >
              <i className="fas fa-circle color-yellow"></i>Medium
              {filters.taskPriorities.includes('Medium') && <i className="fas fa-check ml-15 font-12 color-primary"></i>}
            </Item>
            <Item 
              onClick={handleItemClick} 
              data={{ action: 'togglePriority', value: 'Low' }}
              closeOnClick={false}
            >
              <i className="fas fa-circle color-green"></i>Low
              {filters.taskPriorities.includes('Low') && <i className="fas fa-check ml-15 font-12 color-primary"></i>}
            </Item>
          </Submenu>

          <Submenu label={<><i className="fal fa-layer-group"></i>Project</>}>
            {projects_list}
          </Submenu>

          <Submenu label={<><i className="fal fa-columns"></i>Hide Columns</>}>
            {columns_list}
          </Submenu>

          <Submenu label={<><i className="fal fa-calendar-plus"></i>Created at</>}>
            <Item onClick={handleItemClick} data={{ action: 'reorderByDate', value: 'nearest' }} closeOnClick={false}>
              <i className="fal fa-calendar-plus color-red"></i>Nearest
              {filters.sortBy === 'created_at' && filters.sortOrder === 'asc' && 
                <i className="fas fa-check ml-15 font-12 color-primary"></i>}
            </Item>
            <Item onClick={handleItemClick} data={{ action: 'reorderByDate', value: 'furthest' }} closeOnClick={false}>
              <i className="fal fa-calendar-plus color-green"></i>Furthest
              {filters.sortBy === 'created_at' && filters.sortOrder === 'desc' && 
                <i className="fas fa-check ml-15 font-12 color-primary"></i>}
            </Item>
          </Submenu>

          <Submenu label={<><i className="fal fa-tags"></i>Labels</>}>
            {labels?.map(label => {
              const isSelected = filters.labels?.some(l => l.id === label.id);
              return (
                <Item 
                  key={label.id}
                  onClick={handleItemClick} 
                  data={{ action: 'toggleLabel', value: label }}
                  closeOnClick={false}
                >
                  <div className="display-flex align-items-center justify-content-between width-100">
                    <div className="display-flex align-items-center">
                      <i className={`fas fa-circle mr-8`} style={{ color: `#${label.color}` }}></i>
                      <span>{label.title}</span>
                    </div>
                    {isSelected && <i className="fas fa-check ml-15 font-12 color-primary"></i>}
                  </div>
                </Item>
              );
            })}
          </Submenu>

          <Separator />

          <Item onClick={() => setFixColumns(true)} closeOnClick={false}>
            <i className="fal fa-cog"></i>Modify column stages
          </Item>

          <Item onClick={resetFilters} closeOnClick={false}>
            <i className="fal fa-history"></i>Reset to default
          </Item>
        </Menu>
      </Portal>
    </>
  );
};

export default Filters;
