import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import ReplayDropdown from "../../../../../Replays/ReplayDropdown.js";
import UploadAttachements from "../UploadAttachements.js";

const Index = ({project_id, task_id, taskAttachements, task_user, task, refetchData}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [taskReplays, setTaskReplays] = useState([]);

  useEffect(() => {
    fetchTaskReplays();
  }, []);

  const fetchTaskReplays = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${project_id}/tasks/${task_id}/task_replays.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setTaskReplays(response.data.replays);
        setLoaded(true)
      } else {
        notice(response.data.errors);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const taskReplaysList = taskReplays?.map((taskReplay) => {
    // const filestackId = taskReplay.url.split('/').pop();

    return (
      <div key={taskReplay.token} className="display-flex width-100-percent align-items-center justify-content-between truncate-parent border-bottom default-padding hover-parent-opacity position-relative pl-0 pr-0">
        <a href={`/replays/${taskReplay.token}`} target="_blank" className="truncate-child pl-5 color-1 opacity-5 opacity-10-hover font-13">
          {taskReplay.title}
        </a>
        <div className="font-12 opacity-5 pl-15 pr-15">
          {taskReplay.created_at}
        </div>

        <div className="font-12 opacity-5 pl-15 pr-15">
          <img src={taskReplay.user.avatar} className="avatar-small mr-8" alt={taskReplay.user.avatar}/>
          {taskReplay.user.username}
          <a className="btn btn-small ml-5 background-hover color-1" href={taskReplay.download_link}>
            <i className="fal fa-download"></i>
          </a>
        </div>
      </div>
    )
  })

  const task_attachements_list = taskAttachements?.map((taskAttachement) => {
    const filestackId = taskAttachement.url.split('/').pop();

    return (
      <div key={taskAttachement.token} className="display-flex width-100-percent align-items-center justify-content-between truncate-parent border-bottom default-padding hover-parent-opacity position-relative pl-0 pr-0">
        <a href={taskAttachement.url} target="_blank" className="truncate-child pl-5 color-1 opacity-5 opacity-10-hover font-13">
          {taskAttachement.title}
        </a>
        <div className="font-12 opacity-5 pl-15 pr-15">
          {moment(taskAttachement.created_at).format('MM/DD/YYYY')}
        </div>

        <div className="font-12 opacity-5 pl-15 pr-15">
          <img src={task_user?.avatar} className="avatar-small mr-8" alt={task_user?.avatar}/>
          {task_user?.username}
          <a className="btn btn-small ml-5 background-hover color-1" href={`https://cdn.filestackcontent.com/${filestackId}?dl=true`}>
            <i className="fal fa-download"></i>
          </a>
        </div>
      </div>
    )
  })
  
  return(
    loaded ? (
      <React.Fragment>
        {task_attachements_list}
        {taskReplaysList}

        {taskReplays.length === 0 && taskAttachements.length === 0 && (
          <div className="display-flex width-100-percent justify-content-center align-items-center flex-column" style={{height: "calc(100vh - 300px)"}}>
            <div className="default-padding">
              <div>
                <i className="fal fa-file-alt font-20 mb-5 display-flex align-items-center justify-content-center background-active border-radius border-all default-padding"></i>
              </div>
              <div className="font-14">No files uploaded yet</div>
              <p className="font-12 opacity-5">This is where you'll find all files uploaded with a comment on this task.</p>
            </div>
          </div>
        )}
        <UploadAttachements task={task} refetchData={refetchData} project_id={project_id || match.params.project_id}/>
      </React.Fragment>
    ):(
      <React.Fragment>
        <div className="display-flex width-100-percent justify-content-center align-items-center">
          <i className="fas fa-spinner fa-spin"></i>
        </div>
      </React.Fragment>
    )
  )
}

export default Index;