import React , { useState, useEffect } from 'react';
import axios from "axios"; 

const ApprovalsIndex = ({replay}) => {
  return(
    <React.Fragment>
      <div className="default-padding">
        <div>
          <div>
            {replay.approval.approved ? 
              <div className="mb-15 alert-success">
                <i class="fal fa-check mr-5"></i> Approved by {replay.approval?.user?.username}
              </div>
            : 
              <div className="mb-15 default-padding alert-danger">
                <i class="fal fa-times mr-5"></i> Revision requested by {replay.approval?.user?.username}
              </div>
            }
          </div>
          {replay.approval?.comment && 
            <>
              <div className="display-flex align-items-center justify-content-between mb-10">
                Comment
                <span className="opacity-5 font-12">
                  {replay.approval?.created_at}
                </span>
              </div>
              <p className="opacity-5 font-13">{replay.approval?.comment}</p>
            </>
          }
        </div>
      </div>
    </React.Fragment>
  )
}

export default ApprovalsIndex;