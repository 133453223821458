import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Select from "react-select";
import Priority1Icon from "../../../../Shared/svg/priority1.jsx";
import Priority2Icon from "../../../../Shared/svg/priority2.jsx";
import Priority3Icon from "../../../../Shared/svg/priority3.jsx";
// import TaskDropdown from "../TaskForms/TaskDropdown.js";
import {Menu, Item, useContextMenu} from "react-contexify";
import TaskDropdown from "../TaskForms/TaskDropdown.js";
import Cover from "../Task/Cover.js";
import NewLabel from "../../../../Labels/NewLabel.js";
import NewTaskTemplate from "../../../../TaskTemplates/NewTaskTemplate.js";
import UpdateColumn from "../Task/List/UpdateColumn.js";
import {TaskboardContext} from "../../../../Shared/TaskboardContext.js";
import StageIcon from "../../../../Shared/StageIcon.js";
import FullCardV2 from "../FullCardV2.js";

const Ticket = ({task, refetchData, column, project_id, setArray, isSubtask}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const {setColumns, columns} = useContext(TaskboardContext);
  const portal = window.location.href.includes("/portal/o/");
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [openNewLabel, setOpenNewLabel] = useState(false);
  const [openTaskTemplate, setOpenTaskTemplate] = useState(false);
  const [openCover, setOpenCover] = useState(false);

  const { show } = useContextMenu({
    id: task.token
  });

  const { show: showColumnMenu } = useContextMenu({
    id: `${task.token}-column`
  });

  function displayMenu(e){
    e.stopPropagation();
    show({
      event: e,
    });
  }

  function displayColumnMenu(e){
    e.stopPropagation();
    showColumnMenu({
      event: e,
    });
  }

  useEffect(() => {
    // fetchSomething();
  }, []);

  const fetchSomething = () => {
    // axios.get(`/api/`)
    // .then(function(response){
    // //   console.log(response);
    //   if(response.data.success){

    //   } else {

    //   }
    // })
    // .catch(function(error){
    //   console.log(error)
    //   notice("An error occured")
    // })
    // .then(function () {
    //   // always executed
    // });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){

      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const users_list = task.users.map(user => {
    return(
      <img src={user.avatar} className="queue-table-image" style={{marginLeft: -8}}/>
    )
  });

  const columns_list = columns.filter(column => column.title !== task.column.title).map(column => {
    return(
      <UpdateColumn column={column} project_id={project_id} task_id={task.token} isSubtask={isSubtask}/>
    )
  });

  return(
    <React.Fragment>
      <div onContextMenu={displayMenu} className="row display-flex align-items-center border-bottom" style={{padding: 8, paddingLeft: 0}}>
        <div className="col-12 col-md-8">
          <div className="display-flex align-items-top">
            <a onClick={displayColumnMenu} style={{display: "inline-flex"}} className={`align-items-center btn btn-small mr-15 opacity-7 opacity-10-hover ${
              task.column.stage === 'in_queue' ? 'color-1 border-all' :
              task.column.stage === 'in_progress' ? 'badge-yellow' :
              task.column.stage === 'in_review' ? 'badge-orange' :
              task.column.stage === 'done' ? 'badge-green' : ''
            }`}>
              <StageIcon stage={task.column.stage} classNames="mr-0"/>
            </a>

            <div className="display-flex flex-column">
              <div className="font-14 cursor-pointer opacity-7 opacity-10-hover" onClick={() => {setOpen(true); history.push(`?task_id=${task.token}`);}}>{task.title}</div>
              <div className="opacity-4 font-12 mt-5 opacity-10-hover">
                <Link to={`${portal ? "/portal" : ""}/o/${match.params.organization_id}/projects/${task.project.token}/tasks`} className="color-1">
                  {task.project.title}
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-1 queue-table-td" onClick={displayMenu}>
          <div className="default-padding">
            {users_list}
          </div>
        </div>
        
        <div className="col-12 col-md-1 queue-table-td">
          {/* <span className="badge badge-green">High Priority</span> */}
          {(task.priority != "") ?(
            <div className="priority-icon-wrapper display-flex align-items-center cursor-pointer" onClick={displayMenu}>
              <div>
                {task.priority == "Low" && <div className="badge badge-green"><i className="fas font-10 mr-5 fa-circle"></i>Low</div>}
                {task.priority == "Medium" && <div className="badge badge-yellow"><i className="fas font-10 mr-5 fa-circle"></i>Medium</div>}
                {task.priority == "High" && <div className="badge badge-red"><i className="fas font-10 mr-5 fa-circle"></i>High</div>}
              </div>
            </div>
          ):(
            <div onClick={displayMenu} className="badge background-active cursor-pointer"><i className="fas fa-circle font-10 mr-5"></i>No priority</div>
          )}
        </div>

        <div className="col-12 col-md-1 font-12" onClick={displayMenu}>
          {task.deadline ? ( 
            task.deadline
          ):(
            <div className="opacity-4 opacity-10-hover cursor-pointer font-12">No due date</div>
          )}
        </div>

        <div className="col-12 col-md-1 text-right">
          {!portal && 
            <TaskDropdown task={task} refetchData={refetchData} column={column} project_id={project_id} displayMenu={displayMenu} setOpenNewLabel={setOpenNewLabel} setOpenTaskTemplate={setOpenTaskTemplate} setOpenCover={setOpenCover} hideIcon={false} customClass="task-card-dropdown" setArray={setArray} subtask={isSubtask} />
          }
          <div className="btn-group">
            <a onClick={displayMenu} className="color-1 btn btn-small background-hover opacity-4 hover-child-opacity">
              <i className="fas fa-ellipsis-v"></i>
            </a>
          </div>
        </div>
      </div>

      <Menu id={`${task.token}-column`}>
        {columns_list}
      </Menu>

      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => {setOpenNewLabel(false); refetchData();}}/>
      </Modal>
      
      <Modal open={openTaskTemplate} onClose={() => setOpenTaskTemplate(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewTaskTemplate task={task} refetchData={refetchData} project_id={task.project.token} closeModal={() => setOpenTaskTemplate(false)}/>
      </Modal>

      <Modal open={openCover} onClose={() => setOpenCover(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <Cover task_id={task.token} refetchData={() => {refetchData();}} closeModal={() => setOpenCover(false)} cover_exists={task.cover_url != null || task.unsplash_data != null}/>
      </Modal>

      <Modal focusTrapped={false} closeOnEsc={false} open={open} onClose={() => {setOpen(false); history.replace(location.pathname);}} className="modal-body-white" classNames={{modal: 'modal-fullcard'}} center>
        <FullCardV2 task_id={task.token} refetchData={refetchData} project_id={task.project.token}/>
      </Modal>
    </React.Fragment>
  )
}

export default Ticket;