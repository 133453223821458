import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';
import { Modal } from 'react-responsive-modal';

const StripeAccount = ({stripeAccount, refetchData}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [automaticTax, setAutomaticTax] = useState(stripeAccount.automatic_tax);
  const [taxCollection, setTaxCollection] = useState(stripeAccount.tax_collection);
  const [billingAddressCollection, setBillingAddressCollection] = useState(stripeAccount.billing_address_collection);
  const [viewStripeAccount, setViewStripeAccount] = useState(false);

  const handleDelete = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/stripe_accounts/${stripeAccount.token}`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Removed successfully");
        refetchData();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const handleTaxToggle = () => {
    const updatedTaxStatus = !automaticTax;
    setAutomaticTax(updatedTaxStatus);

    // Here, you can send a request to your server to update the Stripe account with the new tax status
    axios.post(`/api/o/${match.params.organization_id}/stripe_accounts/${stripeAccount.token}/automatic_tax`, {
      automatic_tax: updatedTaxStatus
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        // You may want to refetch data here if needed
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured while updating tax setting")
    });
  }

  const handleTaxCollectionToggle = (e) => {
    const updatedTaxCollectionStatus = !taxCollection;
    setTaxCollection(updatedTaxCollectionStatus);

    // Here, you can send a request to your server to update the Stripe account with the new tax status
    axios.post(`/api/o/${match.params.organization_id}/stripe_accounts/${stripeAccount.token}/tax_collection`, {
      tax_collection: updatedTaxCollectionStatus
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        // You may want to refetch data here if needed
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured while updating tax setting")
    });
  }

  const handleBillingAddressCollection = (e) => {
    const updatedToggle = !billingAddressCollection;
    setBillingAddressCollection(updatedToggle);

    // Here, you can send a request to your server to update the Stripe account with the new tax status
    axios.post(`/api/o/${match.params.organization_id}/stripe_accounts/${stripeAccount.token}/billing_address_collection`, {
      billing_address_collection: updatedToggle
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        // You may want to refetch data here if needed
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured while updating tax setting")
    });
  }

  return(
    <React.Fragment>
      <div className="row align-items-center animated fadeIn">
        <div className="col-10 col-md-8">
          <h5 className="font-weight-500"><i class="fas fa-percentage mr-5"></i>Automatic taxes</h5>
          <p className="opacity-5 font-13">Taxes will be autos of payer. Please make sure taxes are properly setup on Stripe.</p>
        </div>

        <div className="col-2 col-md-4 text-right">
          <Toggle checked={automaticTax} onChange={handleTaxToggle} icons={false}/>
        </div>
      </div>

      <div className="row align-items-center mt-30 border-top mt-15 pt-15 animated fadeIn">
        <div className="col-10 col-md-8">
          <h5 className="font-weight-500"><i class="fas fa-hand-holding-usd mr-5"></i>Tax ID Collection</h5>
          <p className="opacity-5 font-12">
            With tax ID collection enabled, Checkout shows and hides the tax ID collection form depending on your customer's location.
          </p>
        </div>

        <div className="col-2 col-md-4 text-right">
          <Toggle checked={taxCollection} onChange={handleTaxCollectionToggle} icons={false}/>
        </div>
      </div>

      <div className="row align-items-center mt-30 border-top mt-15 pt-15 animated fadeIn">
        <div className="col-10 col-md-8">
          <h5 className="font-weight-500"><i class="fas fa-location mr-5"></i>Require address</h5>
          <p className="opacity-5 font-12">
            We automatically determine if we should collect the address based on their location, but you can make it a requirement too.
          </p>
        </div>

        <div className="col-122 col-md-4 text-right">
          <Toggle checked={billingAddressCollection} onChange={handleBillingAddressCollection} icons={false}/>
        </div>
      </div>

      <div className="row align-items-center mt-30 border-top mt-15 pt-15 animated fadeIn">
        <div className="col-12 col-md-8">
          <h5 className="font-weight-500"><i class="fab fa-stripe-s mr-5"></i>Remove Stripe integration</h5>
          <p className="opacity-5 font-12">This will remove your Stripe integration and all services.</p>
        </div>

        <div className="col-12 col-md-4 text-right">
          <a className="btn background-hover color-1 mr-15" onClick={() => setViewStripeAccount(true)}><i className="fal fa-eye mr-8"></i>View details</a>
          <button disabled={disabled} onClick={handleDelete} className="btn btn-danger-outline">Remove integration</button>
        </div>
      </div>


      {stripeAccount  &&
        <Modal open={viewStripeAccount} onClose={() => setViewStripeAccount(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
          <h4 className="color-black-always modal-title">Stripe Account Information</h4>

          <div className="row no-margin">
            <div className="field">
              <div className="row border-bottom pb-15 pt-15">
                <div className="col-6 opacity-6">
                  Account Name
                </div>

                <div className="col-6 text-right">
                  {stripeAccount.stripe_account_data.settings.dashboard.display_name}
                </div>
              </div>

              <div className="row border-bottom pb-15 pt-15">
                <div className="col-6 opacity-6">
                  Account ID
                </div>

                <div className="col-6 text-right">
                  {stripeAccount.account_id}
                </div>
              </div>

              <div className="row border-bottom pb-15 pt-15">
                <div className="col-6 opacity-6">
                  Country
                </div>

                <div className="col-6 text-right">
                  {stripeAccount.stripe_account_data.country}
                </div>
              </div>

              <div className="row border-bottom pb-15 pt-15">
                <div className="col-6 opacity-6">
                  Currency
                </div>

                <div className="col-6 text-right">
                  {stripeAccount.stripe_account_data.default_currency}
                </div>
              </div>

              <div className="row border-bottom pb-15 pt-15">
                <div className="col-6 opacity-6">
                  Automatic tax
                </div>

                <div className="col-6 text-right">
                  {stripeAccount.stripe_account_data.automatic_tax ? "Yes" : "No"}
                </div>
              </div>
            </div>

            <div className="field text-right">
              <button className="btn color-1 border-all" onClick={() => setViewStripeAccount(false)}>Close</button>
            </div>
          </div>
        </Modal>
      }
    </React.Fragment>
  )
}

export default StripeAccount