import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import Select from 'react-select';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Toggle from 'react-toggle';
import {ProjectContext} from "../../Projects/ProjectContext.js";

const ClientInvite = ({project_id, refetchData, closeModal}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const {projects, groups} = useContext(ProjectContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [email, setEmail] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [roleModalOpen, setRoleModalOpen] = useState(false);

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = () => {
    axios.get(`/api/o/${match.params.organization_id}/roles.json?is_client=true`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setRoles(response.data.roles);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
      setLoaded(true);
    });
  }

  const roleOptions = [
    ...roles.filter(role => role.is_client === true).map(role => ({
      value: role.token,
      label: role.title
    })),
    {
      value: 'add_role',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <i className="fal fa-plus mr-8"></i>
          Add Role
        </div>
      )
    }
  ];

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/invites`, {
      email: email,
      project_id: match.params.project_id ? match.params.project_id : selectedProject, 
      is_client: true, 
      role_id: selectedRole?.value
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const projectsOptions = [
    ...groups.map((group) => ({
      label: group.title,
      options: group.projects.map((project) => ({
        value: project.token,
        label: project.title,
      })),
    })),
    {
      label: "Clients",
      options: projects.map((project) => ({
        value: project.token,
        label: project.title,
      })),
    },
  ];

  const handleRoleChange = (selectedRole) => {
    // if (selectedRole.value === 'add_role') {
    //   setRoleModalOpen(true);
    //   return;
    // }

    setSelectedRole(selectedRole);
  };

  return(
    <React.Fragment>
      <h4 className="color-black-always modal-title">Invite client</h4>

      <div className="row no-margin">
        <form onSubmit={handleSubmit}>
          <div className="field">
            <label>Email</label>
            <input type="email" className="form-control" onChange={(evt) => setEmail(evt.target.value)} required />
          </div>

          <div className="field">
            <label>Role</label>
            <Select
              // value={roles.find(option => option.value === selectedRole?.value)}
              onChange={handleRoleChange}
              options={roleOptions}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Leave blank for regular access"
            />
          </div>

          {match.params.project_id == null && (
            <div className="field">
              <label>Client project</label>
              <Select 
                options={projectsOptions} 
                onChange={(evt) => setSelectedProject(evt.value)}
                className="color-1"
                classNamePrefix="react-select"
                placeholder="Select..."
              />
            </div>
          )}

          <div className="field text-right">
            <button disabled={disabled || email == "" || (!selectedProject && !match.params.project_id)} type="submit" className="btn btn-primary"><i className="fal fa-plus mr-8"></i>Add member</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default ClientInvite