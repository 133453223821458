import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom';
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../Shared/AppContext.js';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";

const CheckboxField = ({field, setSections, setFields}) => {
  const { notice } = useContext(AppContext);
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOptionToggle = (option) => {
    const newValue = Array.isArray(field.value) ? [...field.value] : [];
    
    if (newValue.includes(option)) {
      const index = newValue.indexOf(option);
      newValue.splice(index, 1);
    } else {
      newValue.push(option);
    }

    if (setSections) {
      setSections(prevSections => {
        return prevSections.map(section => {
          if (section.id === field.section_id) {
            return {
              ...section,
              fields: section.fields.map(f => {
                if (f.id === field.id) {
                  return { ...f, value: newValue };
                }
                return f;
              })
            };
          }
          return section;
        });
      });
    } else if (setFields) {
      setFields(prevFields => {
        return prevFields.map(f => {
          if (f.id === field.id) {
            return { ...f, value: newValue };
          }
          return f;
        });
      });
    }
  };

  return (
    field &&
    <React.Fragment>
      <div className="field-answer-wrapper">
        {field.options.map((option, index) => (
          <div 
            className="field field-checkbox-option" 
            key={option.id}
            onClick={() => handleOptionToggle(option)}
            style={{ cursor: 'pointer' }}
          >
            <input
              type="checkbox"
              className="mt-0"
              checked={field.value.includes(option)}
              onChange={(e) => handleOptionToggle(option)}
              onClick={(e) => e.stopPropagation()}
            />
            
            <span className="ml-10">{option}</span>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}

export default CheckboxField;