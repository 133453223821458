import React, { useState, useEffect, useContext } from "react";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { UserContext } from "../Shared/UserContext";
import { Modal } from "react-responsive-modal";
import axios from "axios"; 
import Premium from "../Subscriptions/Premium.js";
import NewOrganization from "../Organizations/New.js";
import Notifications from "../Notifications/Index.js";
import { ProjectContext } from "../Projects/ProjectContext";
import { isMobile } from "react-device-detect";
// import {OrganizationContext} from "../Shared/OrganizationContext.js";
import { HiChevronUpDown } from "react-icons/hi2";

const Organizations = ({organizationAuthorizations}) => {
  // const {organizationAuthorizations, hasPermission} = useContext(OrganizationContext)
  const { setShowSidenav } = useContext(ProjectContext);
  const match = useRouteMatch();
  const history = useHistory();
  const [organizations, setOrganizations] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const currentUser = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [openTheme, setOpenTheme] = useState(false);
  const [openSubscriptionModal, SetOpenSubscriptionModal] = useState(false);
  const [openNotificationSettings, setOpenNotificationSettings] = useState(false);
  const [electron, setElectron] = useState(window && window.process && window.process.type === 'renderer');

  useEffect(() => {
    fetchOrganizations();
  }, [currentUser]);

  function fetchOrganizations() {
    $.ajax({
      url: `/api/organizations.json`,
      type: "GET",
      dataType: "json",
      success: (response) => {
        setOrganizations(response.organizations);
        // setTotalUnread(response.organizations.filter(organization => organization.organization.token != match.params.organization_id).reduce((accumulator, object) => {
        //   return accumulator + object.organization.unread_notifications_size;
        // }, 0))
        if(typeof fetchProfile === "function") {
          fetchProfile(); // from portfolios/index.js
        } 
        setLoaded(true);
      },
      error: (response) => {
        // window.location.href = `/not-found`
      },
    });
  }

  const deleteOrganizationUser = () => {
    if(confirm("Are you sure?")){
      axios.post(`/api/o/${match.params.organization_id}/organization_users/delete`)
      .then(function(response){
        window.location.href = "/"
      })
    }
  }

  const organizations_list = organizations.map((organization) => {
    return (
      <React.Fragment key={organization.organization.token}>
        <li>
          <a href={`https://${organization.organization.subdomain}.${window.location.hostname.includes("ngrok") ? "queue.ngrok.io" : "usequeue.com"}?token=${currentUser.token}`} className={`navigation-organizations-item truncate ${match.params.organization_id == organization.organization.token && "font-weight-600"}`}>
            {organization.organization.title}
          </a>
        </li>
      </React.Fragment>
    );
  });

  return (
    <React.Fragment>
      {currentUser != null && organizations != null && (
        <React.Fragment>
          <div className="display-flex align-items-center">

            <div class="btn-group" style={{width: "100%"}}>
              <div type="button" class="navigation-user-btn dropdown-toggle" data-toggle="dropdown">
                <div className="display-flex align-items-center space-between pr-15">
                  <div className="display-flex align-items-center">
                    <img src={organizationAuthorizations.organization.avatar} className="avatar-medium" />
                    <div className="navigation-user-info">
                      <div className="navigation-user-username truncate-inside">{organizationAuthorizations.organization.title}</div>
                      <div className="navigation-user-email">
                        {organizationAuthorizations.organization_subscribed.subscribed ? (
                          "Premium"
                        ) : (
                          organizationAuthorizations.organization.tier ? 
                          `Tier ${organizationAuthorizations.organization.tier}` :
                          "Free Plan"
                        )}
                      </div>
                    </div>
                  </div>

                  {/* <HiChevronUpDown size={21}/> */}
                </div>
              </div>

              <ul class="navigation-organizations-ul dropdown-menu animated fadeInUp">
                <li>
                  <a href="https://roadmap.usequeue.com" target="blank"><i class="fal fa-fire"></i>Public Roadmap</a>
                </li>

                {organizationAuthorizations.organization_admin && 
                  <React.Fragment>
                    <li>
                      <a onClick={() => SetOpenSubscriptionModal(true)}><i class="fal fa-rocket"></i>Subscription</a>
                      <Modal open={openSubscriptionModal} onClose={() => SetOpenSubscriptionModal(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                        <Premium organization_id={match.params.organization_id}/>
                      </Modal>
                    </li>
                  </React.Fragment>
                }

                <React.Fragment>
                  {
                    ((!organizationAuthorizations.organization.owner) && (organizationAuthorizations.organization.organization_user)) &&
                    <li>
                      <a onClick={deleteOrganizationUser}>
                        <i className="fas fa-house-leave"></i>Leave workspace
                      </a>
                    </li>
                  }
                  <li role="separator" class="divider"></li>
                  <li className="navbar-dropdown-label">All workspaces</li>
                  {organizations_list}

                  <li>
                    {organizationAuthorizations.organization_admin ?(
                      <NewOrganization refetchData={fetchOrganizations} />
                    ) : (
                      <a href="https://usequeue.com" target="_blank" className="navigation-organizations-item">
                        <i class="fal fa-sparkles"></i> Try Queue for free
                      </a>
                    )}
                  </li>
                </React.Fragment>
              </ul>
            </div>

            {isMobile ?(
              <i className="fal fa-times btn btn-small background-hover color-1 close-sidenav-mobile" onClick={() => setShowSidenav(false)}></i>
            ) : (
              <Notifications />
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>

  );
};

export default Organizations;
