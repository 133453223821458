import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Ticket from "./Ticket.js";

const Tickets = ({columns}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeTabIndex, setActiveTabIndex] = useState(() => {
    // Retrieve the last active tab index from localStorage, default to 0 if not found
    return parseInt(localStorage.getItem('dashboard_activeTabIndex')) || 0;
  });

  useEffect(() => {
    // Store the active tab index in localStorage whenever it changes
    localStorage.setItem('dashboard_activeTabIndex', activeTabIndex);
  }, [activeTabIndex]);

  const handleTabSelect = (index) => {
    setActiveTabIndex(index);
  };

  const columns_list = columns.map(column => {
    return(
      <Tab>{column.title}</Tab>
    )
  });

  const task_tabs = columns.map(column => {
    return(
      <TabPanel key={column.id}>
        {column.tasks.map((task, taskIndex) => (
          <Ticket key={taskIndex} task={task} />
        ))}
      </TabPanel>
    )
  });

  return(
    <React.Fragment>
      <div className="container-fluid">
        <Tabs selectedIndex={activeTabIndex} onSelect={handleTabSelect}>
          <TabList className="react-tabs-custom mb-15" style={{ width: "max-content" }}>
            {columns_list}
          </TabList>
          {task_tabs}
        </Tabs>
      </div>
    </React.Fragment>
  )
}

export default Tickets;