import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import { TaskFormContext } from "./TaskFormContext.js";
import "./style.css";
import AiForm from "./AiForm.js";

const ChoosePath = ({setChoosePath}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const {fields, setFields, handleFieldChange, handleLabelChange, handleOptionsChange, removeField, reorderFields, addOptionToField, handleRadioChange, handleValueAdd, taskForm, handleValueChange, removeOption, handleRadioCheckboxOptionChange, handleRequiredChange, updateFieldById} = useContext(TaskFormContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [useAi, setUseAi] = useState(false);

  useEffect(() => {
    // fetchSomething();
  }, []);

  const fetchSomething = () => {
    // axios.get(`/api/`)
    // .then(function(response){
    // //   console.log(response);
    //   if(response.data.success){

    //   } else {

    //   }
    // })
    // .catch(function(error){
    //   console.log(error)
    //   notice("An error occured")
    // })
    // .then(function () {
    //   // always executed
    // });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){

      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  return(
    <React.Fragment>
      {!useAi ?(
        <div className="choose-path-container display-flex flex-direction-column justify-content-center align-items-center" style={{minHeight: 500}}>
          <h4 className="choose-path-header">
            How do you want to build your form?
          </h4>

          <div className="choose-path-options row">
            <div className="choose-path-option col-6 default-padding">
              <div className="border-all border-radius background-3 large-padding display-flex align-items-center justify-content-center flex-direction-column background-hover cursor-pointer" onClick={() => setChoosePath(false)}>
                <div className="choose-path-option-icon" style={{fontSize: 40}}>
                  ✍️
                </div>
                <h4>Start from scratch</h4>
                <p className="text-center opacity-5">Build a custom form from scratch with a blank canvas.</p>
              </div>

            </div>

            <div className="choose-path-option col-6 default-padding">
              <div className="border-all border-radius background-3 large-padding display-flex align-items-center justify-content-center flex-direction-column background-hover cursor-pointer" onClick={() => setUseAi(true)}>
                <div className="choose-path-option-icon" style={{fontSize: 40}}>
                  ✨
                </div>
                <h4>Create with AI</h4>
                <p className="text-center opacity-5">Use AI to help generate questions for your form and then modify it.</p>
              </div>
            </div>
          </div>
        </div>
      ) :(
        <AiForm setChoosePath={setChoosePath} setUseAi={setUseAi} />
      )}


    </React.Fragment>
  )
}

export default ChoosePath;