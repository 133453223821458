import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { useTranslation } from 'react-i18next';
import Section from "../../Daas/ServiceForms/Section.js";
import AdditionalSection from "../../Daas/ServiceForms/AdditionalSection.js";

const ClientOnboardingFields = ({clientOnboardingRead, clientOnboarding}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [sections, setSections] = useState(clientOnboardingRead?.section_field_data || []);
  const [fields, setFields] = useState(clientOnboardingRead?.field_data || []);

  const section_list = sections?.map(section => (
    <Section section={section} setSections={setSections}/>
  ));

  const fields_list = fields?.map(field => (
    <AdditionalSection field={field} setFields={setFields}/>
  ));

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/client_onboardings/${clientOnboarding.token}/client_onboarding_reads/${clientOnboardingRead.token}`, {
      section_field_data: sections,
      field_data: fields
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        notice("Saved");
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <div>
          {section_list}

          <div className="section-wrapper box-shadow background-3 mb-15 field mb-10 border-all default-padding mb-10 border-radius">
            <div className="form-header-input">
              {t("Additional information")}
            </div>
            <div class="form-subheading-input mb-30">
              {t("These questions helps us understand your request better.")}
            </div>
            {fields_list}
          </div>

          <div className="text-right">
            <a disabled={disabled} className="btn btn-default btn-small" onClick={handleSubmit}><i className={`fal  mr-8 ${disabled ? 'fa-spinner fa-spin' : 'fa-save'}`}></i>Save</a>
          </div>
        </div>
    </React.Fragment>
  )
}

export default ClientOnboardingFields;