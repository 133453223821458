import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import StageIcon from "../../../../Shared/StageIcon.js";

const FixColumns = ({columns, refetchData}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // fetchSomething();
  }, []);

  const fetchSomething = () => {
    // axios.get(`/api/`)
    // .then(function(response){
    // //   console.log(response);
    //   if(response.data.success){

    //   } else {

    //   }
    // })
    // .catch(function(error){
    //   console.log(error)
    //   notice("An error occured")
    //   reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    // })
    // .then(function () {
    //   // always executed
    // });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){

      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const columns_list = columns.map((column) => {
    const setStage = (stage) => {
      setDisabled(true);
      axios.post(`/api/o/${match.params.organization_id}/fix_column_stages`, {
        stage: column.stage,
        title: column.title, 
        new_column_stage: stage
      })
      .then(function(response){
        if(response.data.success){
          notice("Updated successfully")
          refetchData();  // Refresh the columns data
        } else {
          response.data.errors?.forEach((error) => {
            notice(error);
          });
        }
      })
      .catch(function(error){
        console.log(error);
        notice("An error occurred while updating the stage");
        reportError(`File: FixColumns.js.setStage: ${error.config.url}. StackTrace: ${error.stack}.`);
      })
      .then(function () {
        setDisabled(false);
      });
    }

    return (
      <tr key={column.id}>
        <td>{column.title}</td>
        <td className="text-right">
          <div className="btn-group">
            <button type="button" className="btn btn-default btn-small dropdown-toggle" data-toggle="dropdown" disabled={disabled}>
              <div className="display-flex align-items-center">
                {column.stage ? (
                  <>
                    <StageIcon stage={column.stage}/>Update
                  </>
                ):(
                  <>
                    <i className="far fa-exclamation-triangle mr-8"></i>Missing stage
                  </>
                )}
              </div>
            </button>
            <ul className="dropdown-menu min-width-unset" role="menu">
              <li><a onClick={() => setStage("in_queue")} className="stage-dropdown-a"><StageIcon stage={"in_queue"}/>In Queue</a></li>
              <li><a onClick={() => setStage("in_progress")} className="stage-dropdown-a"><StageIcon stage={"in_progress"}/>In Progress</a></li>
              <li><a onClick={() => setStage("in_review")} className="stage-dropdown-a"><StageIcon stage={"in_review"}/>Needs Review</a></li>
              <li><a onClick={() => setStage("done")} className="stage-dropdown-a"><StageIcon stage={"done"}/>Done</a></li>
              <li><a onClick={() => setStage("canceled")} className="stage-dropdown-a"><StageIcon stage={"canceled"}/>Canceled</a></li>
            </ul>
          </div>
        </td>
      </tr>
    )
  })


  return(
    <React.Fragment>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>Column</th>
            <th className="text-right">Stage</th>
          </tr>
        </thead>
        <tbody>
          {columns_list}
        </tbody>
    </table>
    </React.Fragment>
  )
}

export default FixColumns;