import React , { useState, useContext, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom'
import {UserContext} from "../Shared/UserContext.js";
import NotificationSettings from "./NotificationSettings.js";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import OrganizationNotification from "./Notifiable/OrganizationNotification.js";
import Pusher from 'pusher-js';
import { Modal } from "react-responsive-modal";
import LocalizedStrings from 'react-localization';
import loadingData from "../Shared/Lotties/lottie-loading.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { useTranslation } from 'react-i18next';
import ReactTooltip from "react-tooltip";
import cable from "../Shared/actionCable";

const Index = ({customClass}) => {
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const [notifications, setNotifications] = useState([]);
  const [loaded, setLoaded] = useState(false);
  // const pusherNotifications = new Pusher('683f89863cff332fae20', {cluster: 'us2'});
  const [openNotificationSettings, setOpenNotificationSettings] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [viewUnreadNotifications, setViewUnreadNotifications] = useState(true);
  
  const { t } = useTranslation();

  useEffect(() => {
    // if(currentUser != null){
      // const userNotificationChannel = pusherNotifications.subscribe(`user-${currentUser.id}-notifications`);
      // userNotificationChannel.bind(`notifications`, function(data) {
      //   checkLogin();
      // })

      // const organizationNotificationChannel = pusherNotifications.subscribe(`organization-${match.params.organization}-notifications`);
      // organizationNotificationChannel.bind(`notifications`, function(data) {
      //   console.log(data)
      //   checkLogin();
      // })
    // }

    const actioncableChannel = cable.subscriptions.create(
      { channel: `UserChannel` }, 
      {
        received(data) {
          checkLogin();
        },
      }
    );

    return () => {
      // pusherNotifications.unsubscribe(`user-${currentUser.id}-notifications`);
      // pusherNotifications.unsubscribe(`organization-${match.params.organization}-notifications`);
      actioncableChannel.unsubscribe();
    }
  },[currentUser])

  const showNotifications = () => {
    fetchNotifications();
  }

  useEffect(() => {
    fetchNotifications();
  }, [viewUnreadNotifications])

  const fetchNotifications = () => {
    setLoaded(false);
    $.ajax({
      url: `/api/notifications.json?unread=${viewUnreadNotifications}`,
      type: 'GET',
      dataType: 'json',
      success: (response) => {
        setNotifications(response.notifications);
        setLoaded(true);
        setDisabled(false);
      }, 
      error: (error) => {
        // notice("Oh man, an error occured. Don't worry, we're gonna smack the engineer who caused it!")
      }
    });
  }

  const readNotifications = () => {
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/notifications/mark_as_read`)
    .then(function (response) {
      fetchNotifications();
      checkLogin();
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  const notifications_list = notifications.map(notification => {
    return(
      ((notification.notifiable != null) && (notification.type == "user")) ? (
        <React.Fragment>
          
        </React.Fragment>
      ):(
        <OrganizationNotification notification={notification} refetchData={fetchNotifications} setNotifications={setNotifications} notifications={notifications}/>
      )
    )
  })

  return(
    <React.Fragment>
      <div class={`btn-group daas-platform-links-li ${customClass}`} style={{marginRight: 0, marginBottom: 0, marginLeft: 3}}>
        <div class="dropdown">
          <a className="daas-platform-links-a sidebar-text-color dropdown-toggle default-padding" id="dropdownMenuButton" data-toggle="dropdown" data-bs-auto-close="inside" aria-haspopup="true" aria-expanded="false" style={{height: "100%"}} onClick={() => showNotifications(true)}>
            <i class="fal fa-bell"></i>
            {currentUser.unread_notifications_size > 0 &&
              <span className="notification-circle-dot">{currentUser.unread_notifications_size}</span>
            }
          </a>

          <ul class="dropdown-menu navbar-dropdown-ul client-notifications-dropdown" aria-labelledby="dropdownMenuButton" role="menu" style={{background: "var(--background)", overflowY: "hidden"}}>
            <div className="notif-container">
              <div className="notif-header">
                <h5>{t("Notifications")}</h5>
                <div style={{display: "flex", alignItems: "center"}}>
                  <a onClick={readNotifications} className="color-1 opacity-4 opacity-10-hover btn btn-small disable-dropdown-close background-hover">
                    {disabled ? <i class="fal fa-spinner fa-spin mr-5"></i> : <i class="fal fa-check-square mr-5"></i>}
                    {t("Mark all as read")}
                  </a>
                  <a className="color-1 opacity-4 opacity-10-hover btn btn-small background-hover" onClick={() => setOpenNotificationSettings(true)}>
                    <i class="fal fa-cog mr-5"></i>Settings
                  </a>
                </div>
              </div>

              <div className="notif-mainview">
                <div className="notif-mainview-header">
                  <a className={`btn btn-small background-hover color-1 mr-15 ${viewUnreadNotifications ? "background-active" : ""}`} onClick={() => setViewUnreadNotifications(true)}>Unread</a>
                  <a className={`btn btn-small background-hover color-1 mr-15 ${viewUnreadNotifications ? "" : "background-active"}`} onClick={() => setViewUnreadNotifications(false)}>All</a>
                </div>

                <div className="ml-8 mt-15" style={{overflowY: "auto", height: "100%", maxHeight: "500px"}}>
                  {loaded && 
                    <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
                  }

                  {loaded ? notifications_list : <div className="default-padding"><Player style={{width: "100px", margin: "auto"}} mode="normal" autoplay loop src={loadingData} /></div>}

                  {loaded && notifications.length == 0 && 
                    <div className="default-padding opacity-4 text-center font-12 display-flex flex-direction-column align-items-center" style={{paddingTop: 150}}>
                      <i class="fal fa-inbox" style={{fontSize: 20, marginBottom: 10}}></i>
                      You're all caught up!
                    </div>
                  }
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>

      <Modal open={openNotificationSettings} onClose={() => setOpenNotificationSettings(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <NotificationSettings />
      </Modal>
    </React.Fragment>
  )
}

export default Index