import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import {TaskboardContext} from "../../../../Shared/TaskboardContext.js";

const TaskOwner = ({task, refetchData, project_id}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // fetchSomething();
  }, []);

  const fetchSomething = () => {
    // axios.get(`/api/`)
    // .then(function(response){
    // //   console.log(response);
    //   if(response.data.success){

    //   } else {

    //   }
    // })
    // .catch(function(error){
    //   console.log(error)
    //   notice("An error occured")
    // })
    // .then(function () {
    //   // always executed
    // });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/`)
    .then(function(response){
      // console.log(response);
      if(response.data.success){

      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }


  const fetchUsers = () => {
    axios.get(`/api/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}/users.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setUsers(response.data.users)
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
      setLoaded(true);
    });
  }


  const users_list = users.filter(user => user.id !== task.user_id).map(user => {
    const changeOwner = () => {
      axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}.json`, {
        user_id: user.id
      })
      .then(function(response){
        console.log(response);
        if(response.data.success){
          refetchData();
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
    }

    return(
      <li>
        <a type="button" class="color-1 dropdown-toggle mr-15 border-none font-12 background-hover-2" data-toggle="dropdown" onClick={() => changeOwner()}>
          <img src={user.avatar} className="avatar-small mr-8" />{user.username}
        </a>
      </li>
    )
  })  

  return(
    <React.Fragment>
      <div class="btn-group">
        <a type="button" class="task-user-item cursor-pointer color-1" data-toggle="dropdown" onClick={() => fetchUsers()}>
          <img src={task.user.avatar} className="avatar-small mr-5" />{task.user.username}
        </a>

        <ul class="dropdown-menu animated fadeInUp" role="menu">
          {loaded ? users_list : <><li><a><i class="fa fa-spinner"></i>Loading users...</a></li></>}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default TaskOwner;