import React , { useState, Fragment, useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Dropdown from 'react-bootstrap/Dropdown';
import { Portal } from 'react-portal';
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  Item,
  useContextMenu,
  Submenu, 
  Separator
} from "react-contexify";
import _ from 'lodash';
import TaskLabels from "../../../../Labels/TaskLabels.js";
import {TaskboardContext} from "../../../../Shared/TaskboardContext.js";
import TaskLabelPicked from "../../../../Labels/TaskLabelPicked.js";
import {OrganizationContext} from "../../../../Shared/OrganizationContext.js";
import UpdateColumn from "../Task/List/UpdateColumn.js";
import Deadline from "../Task/Deadline.js";
import Move from "../Task/Move.js";
import { confirmAlert } from 'react-confirm-alert';
import NewLabel from "../../../../Labels/NewLabel.js";

const TaskDropdown = ({task, refetchData, project_id, displayMenu, setOpenNewLabel, subtask, setOpenCover, setArray, hideIcon, customClass, setOpenTaskTemplate}) => {
  const {labels, hasPermission} = useContext(OrganizationContext)
  const { setColumns, columns, users, fullUsers} = useContext(TaskboardContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const [openDeadline, setOpenDeadline] = useState(false);
  const portal = window.location.href.includes("/portal/o/");
  const [openMoveTask, setOpenMoveTask] = useState(false);
  const [openLabelForm, setOpenLabelForm] = useState(false);
  const [labelFormLabel, setLabelFormLabel] = useState(null);

  const archive = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: 'Are you sure you want to archive this task?',
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Archive',
          className: 'btn btn-danger',
          onClick: () => {
            archiveTask();
          }
        },
      ]
    });
  }

  const [priorities, setPriorities] = useState([
    {
      icon: "far fa-circle",
      value: null,
      title: "No priority"
    },
    {
      icon: "fas fa-circle color-green",
      value: "Low",
      title: "Low"
    },
    {
      icon: "fas fa-circle color-orange",
      value: "Medium",
      title: "Medium"
    },
    {
      icon: "fas fa-circle color-red",
      value: "High",
      title: "High"
    }
  ]);

  const { t } = useTranslation();
  const { show: showContextMenu } = useContextMenu();

  const updateNestedArray = (array, taskData) => {
    return array.map(item => {
      if (item.token === taskData.token) {
        return taskData;
      }
      if (item.subtasks) {
        return {
          ...item,
          subtasks: updateNestedArray(item.subtasks, taskData)
        };
      }
      return item;
    });
  };

  const removeFromNestedArray = (array, taskToken) => {
    // First filter out any top-level matches
    const filteredArray = array.filter(item => item.token !== taskToken);
    
    // Then recursively handle subtasks
    const result = filteredArray.map(item => {
      if (item.subtasks) {
        const filteredSubtasks = item.subtasks.filter(subtask => 
          subtask.token !== taskToken
        );
        return {
          ...item,
          subtasks: removeFromNestedArray(filteredSubtasks, taskToken)
        };
      }
      return item;
    });
    return result;
  };

  const archiveTask = () => {
    setDisabled(true);

    axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/archive`)
    .then(function(response){
      if(response.data.success){
        if(!subtask){
          setColumns(prevColumns => {
            return prevColumns.map(col => {
              if (col.title === task.column.title) {
                return {
                  ...col,
                  tasks: col.tasks ? col.tasks.filter(t => t.token !== task.token) : col.tasks
                };
              }
              return col;
            });
          });
        } else {
          if(setArray){
            setArray(prevArray => removeFromNestedArray(prevArray, task.token));
          }
        }
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
      refetchData();
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const moveOut = () => {
    setDisabled(true);

    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      position: null, 
      parent_task_id: null, 
      // column_id: task.parent_task.column_id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        // notice("Moved out");
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const picked_task_labels_list = task?.task_labels?.map(task_label => {
    return(
      <TaskLabelPicked task_label={task_label} task={task} refetchData={() => {refetchData();}} setArray={setArray} subtask={subtask} updateNestedArray={updateNestedArray} setOpenLabelForm={setOpenLabelForm} setLabelFormLabel={setLabelFormLabel} />
    )
  })

  const labels_list = labels?.filter(label => !task?.task_labels?.some(task_label => task_label.label.id === label.id)).map(label => {
    return(
      <TaskLabels label={label} task={task} refetchData={refetchData} setArray={setArray} isSubtask={subtask} updateNestedArray={updateNestedArray} setOpenLabelForm={setOpenLabelForm} setLabelFormLabel={setLabelFormLabel} />
    )
  })

  const columns_list = columns.filter(column => column.title !== task.column.title).map(column => {
    return(
      <UpdateColumn column={column} project_id={project_id} task_id={task.token} isSubtask={subtask} updateNestedArray={updateNestedArray} setArray={setArray}/>
    )
  });

  const priority_list = priorities.map(priority => {
    const handlePriorityChange = () => {
      axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
        priority: priority.value,
        dashboard_task: true
      })
      .then(function (response) {
        if (response.data.success && response.data.task_data) {
          if(subtask){
            setArray(prevArray => updateNestedArray(prevArray, response.data.task_data));
            refetchData();
          } else {
            // Update the task in columns state
            setColumns(prevColumns => {
              return prevColumns.map(col => {
                if (col.title === task.column.title) {
                  return {
                    ...col,
                    tasks: col.tasks.map(t => 
                      t.token === task.token ? response.data.task_data : t
                    )
                  };
                }
                return col;
              });
            });
          }
        } else {
          notice("Failed to update column");
        }
      })
      .catch(function (error) {
        console.log(error);
        notice("An error occurred while updating the column");
      });
    }

    return(
      <Item closeOnClick={false} onClick={() => handlePriorityChange(priority.value)}>
        <i className={`${priority.icon} mr-8`}></i> {priority.title}
        {task.priority === priority.value && 
          <i className="fal fa-check ml-8 font-13"></i>
        }
      </Item>
    )
  });
  
  const assign_list = (fullUsers || users).map(user => {
    const isSelected = task.users.some(item => item.id === user.id);
    const handleAssignChange = () => {
      if(isSelected){
        // remove user from task
        axios.delete(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_users/${user.id}`)
          .then(function(response){
            // console.log(response);
            if(response.data.success){
              if(subtask){
                setArray(prevArray => updateNestedArray(prevArray, response.data.task_data));
              } else {
                setColumns(prevColumns => {
                  return prevColumns.map(col => {
                    if (col.title === task.column.title) {
                      return {
                        ...col,
                        tasks: col.tasks.map(t => 
                          t.token === task.token ? response.data.task_data : t
                        )
                      };
                    }
                    return col;
                  });
                });
              }
            }
          })
          .catch(function(error){
            console.log(error)
            notice("An error occured")
          })
          .then(function () {
            setDisabled(false);
          });
      } else {
        // add user to task
        axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}/task_users`, {
          email: user.email
        })
        .then(function(response){
          if(response.data.success){
            if(subtask){
              setArray(prevArray => updateNestedArray(prevArray, response.data.task_data));
            } else {
              setColumns(prevColumns => {
                return prevColumns.map(col => {
                  if (col.title === task.column.title) {
                    return {
                      ...col,
                      tasks: col.tasks.map(t => 
                        t.token === task.token ? response.data.task_data : t
                      )
                    };
                  }
                  return col;
                });
              });
            }
          }
        })
        .catch(function(error){
          console.log(error)
          notice("An error occured")
        })
        .then(function () {
          setDisabled(false);
        });
      }
    }

    return(
      <Item closeOnClick={false} onClick={() => handleAssignChange(user.id)}>
        <img src={user.avatar} className="avatar-small mr-8" alt={user.username}/>
        {user.username}

        {isSelected &&
          <i className="fas fa-check text-right font-12 color-blue"></i>
        }
      </Item>
    )
  });

  const handleDeadlineChange = (date) => {
    axios.put(`/api/portal/projects/${project_id || match.params.project_id}/tasks/${task.token}`, {
      deadline: date
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        if(subtask){
          setArray(prevArray => updateNestedArray(prevArray, response.data.task_data));
          refetchData();
        } else {
          setColumns(prevColumns => {
            return prevColumns.map(col => {
              if (col.title === task.column.title) {
                return {
                  ...col,
                  tasks: col.tasks.map(t => 
                    t.token === task.token ? response.data.task_data : t
                  )
                };
              }
              return col;
            });
          });
        }
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
      refetchData();
    })
    .then(function () {
      setDisabled(false);
    });
  };

  return(
    <React.Fragment>
      {!hideIcon && 
        <a onClick={displayMenu} className={`task-dropdown-btn animate grow btn color-1 background-hover hover-child-opacity ml-15 ${customClass}`}><i class="fas fa-ellipsis-v"></i></a>
      }

      <Portal>

        <Menu id={task.token}>
          <Item onClick={() => setOpenDeadline(true)}>
            <i class="fal fa-calendar-alt"></i>{t("Due date")}
          </Item>

          <Item onClick={() => setOpenCover(true)}>
            <i class="fal fa-image-polaroid"></i>{t("Change cover")}
          </Item>

          <Submenu closeOnClick={false} label={<Fragment><i class="fal fa-tag"></i><span>Labels</span></Fragment>}>
            {labels?.length > 0 &&
              <>
                {picked_task_labels_list}
                {labels_list}
              </>
            }
            <Item onClick={() => setOpenNewLabel(true)}>
              <i className="far fa-plus mr-8"></i>New Label
            </Item>
          </Submenu>

          <Submenu closeOnClick={false} label={<Fragment><i class="fal fa-columns"></i><span>Stage</span></Fragment>}>
            {columns_list}
          </Submenu>

          <Submenu closeOnClick={false} label={<Fragment><i class="fal fa-user"></i><span>Assign</span></Fragment>}>
            {assign_list}
          </Submenu>

          <Submenu closeOnClick={false} label={<Fragment><i class={`fal fa-exclamation-circle ${task.priority === "High" ? "color-red" : task.priority === "Medium" ? "color-orange" : "color-green"}`}></i><span>Priority</span></Fragment>}>
            {priority_list}
          </Submenu>
          
          {task.parent_task != null &&
            <Item onClick={moveOut}>
              <i class="fal fa-share"></i>{t("Move out")}
            </Item>
          }

          <Separator />

          <Item onClick={() => setOpenMoveTask(true)}>
            <i class="fal fa-exchange"></i>{t("Move")}
          </Item>

          {/* {(organizationAuthorizations.organization_user && match.params.project_id != null) &&
            <Item onClick={() => setOpenTaskTemplate(true)}>
              <i class="fal fa-object-group"></i> {t("Turn into template")}
            </Item>
          } */}

          <Separator />
          
          {hasPermission("delete_task") && 
            <Item onClick={archive}>
              <i class="fal fa-archive"></i>{t("Archive")}
            </Item>
          }
        </Menu>
      </Portal>

      <Modal open={openDeadline} onClose={() => setOpenDeadline(false)} className="modal-body-white" classNames={{modal: 'width-300'}} center>
        <Deadline date={task.deadline_raw} setDate={handleDeadlineChange} closeModal={() => setOpenDeadline(false)} />
      </Modal>

      <Modal open={openMoveTask} onClose={() => setOpenMoveTask(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Move task_id={task.token} refetchData={() => {refetchData();}} closeModal={() => setOpenMoveTask(false)}/>
      </Modal>

      <Modal open={openLabelForm} onClose={() => setOpenLabelForm(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel label={labelFormLabel} closeModal={() => {setOpenLabelForm(false); refetchData();}}/>
      </Modal>
    </React.Fragment>
  )
}

export default TaskDropdown