import React, { useState, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import {UserContext} from "../Shared/UserContext.js";
import axios from "axios"; 
import Filestack from "./Filestack.js";

const Edit = ({project, closeModal}) => {
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false)
  const [submitting, setSubmitting] = useState(false);
  const [title, setTitle] = useState(project.title);
  const [logo, setLogo] = useState(project.avatar);
  const [isPrivate, setIsPrivate] = useState(project.private);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    setSubmitting(true);
    setDisabled(true);
  
    try {
      const data = formData("editProject")
      const response = await axios.patch(`/api/o/${match.params.organization_id}/projects/${project.token}`, {
        title: title,
        logo: logo,
        private: isPrivate
      });
  
      console.log(response);
      if(response.data.success){
        await fetchProjectsAsync();
        closeModal();
        notice("Project updated");
        setDisabled(false);
        setSubmitting(false);
      } 
  
    } catch (error) {
      console.log(error);
      notice("An error occurred")
    }
    setSubmitting(false);
    setDisabled(false);
  }

  return(
    <React.Fragment>
      <div className="row no-margin">
        {project.avatar && 
          <Filestack project={project} setImage={setLogo} image={logo}/>
        }
        <form id="editProject" onSubmit={handleSubmit}>
          <div className="field">
            <label className="color-black-always">Title</label>
            <input className="form-control" name="title" placeholder="Title of your project" defaultValue={project.title} onChange={(e) => setTitle(e.target.value)} required/>
          </div>

          <div className="text-right">
            <button type="submit" disabled={disabled} className="btn btn-primary">{submitting ? "Submitting..." : "Submit"}</button>
          </div>
        </form>
      </div>
    </React.Fragment>
  )
}

export default Edit