import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import ColorPicker from 'react-pick-color';
import {OrganizationContext} from "../Shared/OrganizationContext.js";
import Select from 'react-select';
import {UserContext} from "../Shared/UserContext.js";

const EditTheme = ({onboarding}) => {
  const currentUser = useContext(UserContext);
  const {organizationAuthorizations, fetchOrganizationAuthorizations} = useContext(OrganizationContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [bodyBackground, setBodyBackground] = useState(organizationAuthorizations.organization.body_background || "#101114");
  const [background, setBackground] = useState(organizationAuthorizations.organization.background || "#141619");
  const [backgroundSecondary, setBackgroundSecondary] = useState(organizationAuthorizations.organization.background_secondary || "#0c0d0f")
  const [backgroundHover, setBackgroundHover] = useState(organizationAuthorizations.organization.background_hover || "#222529");
  const [textColor, setTextColor] = useState(organizationAuthorizations.organization.text_color || "#DAE1E9");
  const [inputBackground, setInputBackground] = useState(organizationAuthorizations.organization.input_background || "#16191D");
  const [buttonBackground, setButtonBackground] = useState(organizationAuthorizations.organization.button_background || "#4169E1");
  const [buttonColor, setButtonColor] = useState(organizationAuthorizations.organization.button_color || "#DAE1E9");
  const [borderColor, setBorderColor] = useState(organizationAuthorizations.organization.border_color || "#3c4054");
  const [sidebarBackgroundColor, setSidebarBackgroundColor] = useState(organizationAuthorizations.organization.sidebar_background_color || "#101114");
  const [sidebarTextColor, setSidebarTextColor] = useState(organizationAuthorizations.organization.sidebar_text_color || "#DAE1E9");
  const [advanced, setAdvanced] = useState(organizationAuthorizations.organization.custom_theme);
  const [custom, setCustom] = useState(organizationAuthorizations.organization.custom_theme != null);

  // Array of predefined themes
  const themes = [
    {
      label: "System Default",
      value: "",
    },
    {
      label: "Queue Dark",
      value: "#101114",
    },
    {
      label: "Pure Light",
      value: "#ffffff",
    },
    {
      label: "Light",
      value: "#f5f8fa",
    },
    {
      label: "Dim",
      value: "#262836"
    },
    {
      label: "Mystic",
      value: "#261f28"
    },
    {
      label: "Darkness",
      value: "#0e0f0f"
    }
  ];

  const [currentTheme, setCurrentTheme] = useState(() => {
    if (organizationAuthorizations.organization.custom_theme) {
      return { value: "Custom", label: "Custom" };
    }

    let themeColor = currentUser.theme_color;
    
    if (themeColor === "" || themeColor === null) {
      themeColor = "";
    }

    const matchedTheme = themes.find(theme => theme.value === themeColor);
    return matchedTheme;
  });

  // Modify the themes array to work with react-select
  const themeOptions = themes.map((theme, index) => ({
    value: theme.value,
    label: theme.label
  }));

  // Function to handle theme selection
  const handleThemeChange = (theme) => {
    console.log(theme)
    if(theme.label != "Custom") {
      axios.post(`/api/update_theme_preference`, {theme_color: theme.value, organization_id: match.params.organization_id})
      .then((response) => {
        console.log(response);
        location.reload(true);
      })
    } else if (organizationAuthorizations.organization_user) {
      setCustom(true);
      setAdvanced(true);
    }
  };

  const handleSubmit = (evt) => {
    if(evt != null){
      evt.preventDefault();
    }
    setDisabled(true);

    const data = {
      button_background: buttonBackground,
      button_color: buttonColor,
    };

    axios.put(`/api/o/${match.params.organization_id}`, data)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        location.reload(true);
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <div className="row display-flex justify-content-center mt-15">
        <div className={`default-padding col-12`} style={{maxWidth: "500px"}}>
          <div className={`background-3 border-radius default-padding ${onboarding ? "" : "border-all"} themes-wrapper`}>
            {!onboarding && 
              <React.Fragment>
                <div className="display-flex align-items-center justify-content-between">
                  <div>
                    <h4 className="font-weight-500 mt-0">Theme</h4>
                    <p className="opacity-5 font-13" style={{marginTop: "-5px"}}>Update the platform theme to fit your personal taste. Each theme is unique to your settings.</p> 
                  </div>
                </div>
              </React.Fragment>
            }
            
            {(custom && advanced) && 
              <>
                <div className="alert-warning mt-15">
                  Enabling custom theme will disable your clients and others from choosing their own theme.
                </div>
              </>
            }
            
            <React.Fragment>
              <form className="row mt-25">
                {/* Replace the dropdown with react-select */}
                <div className="field col-md-12">
                  <label>Themes</label>
                  <div className="field">
                    <Select
                      value={currentTheme}
                      options={themeOptions}
                      onChange={(selectedOption) => handleThemeChange(selectedOption)}
                      defaultValue={themeOptions.find(option => option.value === organizationAuthorizations.organization.background)}
                      className="react-select-container"
                      classNamePrefix="react-select"
                    />
                  </div>
                </div>

                
                
                {organizationAuthorizations.organization_user &&
                  <>
                    <div className="field col-md-12">
                      <label>Button</label>

                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: buttonBackground}}>

                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={buttonBackground} onChange={buttonBackground => setButtonBackground(buttonBackground.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="field col-md-12">
                      <label>Button Text</label>
                      <div className="field">
                        <div class="btn-group width-100-percent">
                          <button type="button" class="btn btn-small dropdown-toggle color-picker-box border-all" data-toggle="dropdown" style={{background: buttonColor}}>

                          </button>
                          <ul class="dropdown-menu animated fadeInUp" role="menu">
                            <ColorPicker color={buttonColor} onChange={buttonColor => setButtonColor(buttonColor.hex)} />
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="default-padding col-md-12 text-center">
                      <button onClick={handleSubmit} className="btn btn-primary" style={{background: buttonBackground, color: buttonColor}}>Update</button>
                    </div>
                  </>
                }
              </form>
            </React.Fragment>
          </div>
        </div>
        
        {(advanced && custom && organizationAuthorizations.organization_user) && 
          <div className={`col-md-6 col-12 border-radius default-padding`}>
            <div className="row border-all border-radius" style={{height: "100%", borderColor: borderColor, background: bodyBackground}}>
              <div className="col-3 field border-right" style={{background: sidebarBackgroundColor, borderColor: borderColor}}>
                <div className="pr-15 border-right border-radius" style={{borderColor: borderColor}}>
                  <ul style={{paddingLeft: "0", listStyle: "none", padding: "15px"}}>
                    <li style={{color: sidebarTextColor}}>
                      Sidebar text 1
                    </li>

                    <li style={{color: sidebarTextColor}}>
                      Sidebar text 2
                    </li>

                    <li style={{color: sidebarTextColor}}>
                      Sidebar text 3
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-9 field default-padding">
                <div className="border-all border-radius default-padding" style={{background: background, borderColor: borderColor}}>
                  <p className="font-12" style={{color: textColor}}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                  </p>

                  <div className="field default-padding" style={{color: textColor, background: backgroundHover}}>
                    Hover active
                  </div>

                  <div className="field">
                    <input className="form-control" placeholder="Input Background" style={{color: textColor, background: inputBackground, borderColor: borderColor}}/> 
                  </div>

                  <div className="field">
                    <button className="btn" style={{color: buttonColor, background: buttonBackground}}>
                      Button
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )
}

export default EditTheme;