import React from 'react';

const TaskLabel = ({task_label}) => {
  return(
    <React.Fragment>
      <span className={`badge border-all mr-5 mb-5`} >
        <i className="fas fa-circle mr-5" style={{color: `#${task_label.label.color}`}}></i>
        <span className="opacity-7">{task_label.label.title}</span>
      </span>
    </React.Fragment>
  )
}

export default TaskLabel;