import React, { useState, useEffect, useContext } from "react";
import { Route, Switch, withRouter, useRouteMatch, useHistory} from "react-router-dom";
import { UserContext } from "../Shared/UserContext.js";
import axios from "axios"; 
import Projects from '../Projects/Projects.js';
import {OrganizationContext} from "../Shared/OrganizationContext.js";
// import pusher from '../Shared/pusher.js';

const Show = () => {
  const match = useRouteMatch();
  const history = useHistory() 
  document.body.style.overflow = "unset";
  const currentUser = useContext(UserContext);
  const [loaded, setLoaded] = useState(false);
  const [open, setOpen] = useState(false);
  const [organizationAuthorizations, setOrganizationAuthorizations] = useState();
  const [permissions, setPermissions] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [labels, setLabels] = useState([]);
  const [taskTemplates, setTaskTemplates] = useState([]);

  useEffect(() => {
    fetchOrganizationUsers();
    fetchLabels();
    fetchTaskTemplates();
  }, [match.params.organization_id]);

  const fetchOrganizationUsers = () => {
    axios.get(`/api/o/${match.params.organization_id}/organization_users.json`)
    .then(function(response){
      if(response.data.success){
        setOrganizationUsers(response.data.organization_users);
      }
    })
    .catch(function(error){
      console.log(error)
    })
  }

  const fetchLabels = () => {
    axios.get(`/api/o/${match.params.organization_id}/labels.json`)
    .then(function(response){
      if(response.data.success){
        setLabels(response.data.labels);
      }
    })
    .catch(function(error){
      console.log(error)
    })
  }

  const fetchTaskTemplates = () => {
    axios.get(`/api/o/${match.params.organization_id}/task_templates.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setTaskTemplates(response.data.task_templates);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }
  
  // const [members, setMembers] = useState([]);

  // useEffect(() => {
  //   const channel = pusher.subscribe(`presence-${match.params.organization_id}-channel`); // Replace with your channel name

  //   channel.bind('pusher:subscription_succeeded', (members) => {
  //     setMembers(Object.values(members.members)); // Members list
  //   });

  //   channel.bind('pusher:member_added', (member) => {
  //     setMembers((prev) => [...prev, member.info]);
  //   });

  //   channel.bind('pusher:member_removed', (member) => {
  //     debugger
  //     setMembers((prev) => prev.filter((m) => m.id !== parseInt(member.id)));
  //   });

  //   return () => {
  //     channel.unsubscribe();
  //   };
  // }, []);
  
  useEffect(() => {
    if(location.pathname.includes("conversations")){
      history.push(location.pathname.replace("/conversations", ""))
    }

    if(currentUser != null ){
      fetchOrganizationAuthorizations();
    }
  },[currentUser, match.params.organization_id]);

  const fetchOrganizationAuthorizations = () => {
    axios.get(`/api/o/${match.params.organization_id}/subscriptions.json`)
    .then(function(response){
      // console.log(response); 
      if(!response.data.authorizations.organization_user || !response.data.authorizations.project_user){
        // history.push(`/not-found`)
      }
      setOrganizationAuthorizations(response.data.authorizations);
      setPermissions(response.data.authorizations.permissions);
      setLoaded(response.data.success);

      if(response.data.success){
        if(!response.data.authorizations.organization_user){
          if (location.pathname.includes('/o/') && !location.pathname.includes('/portal/o/')) {
            const newPath = location.pathname.replace('/o/', '/portal/o/') + location.search;
            history.push(newPath);
          }
        }
      }
    })
  }
  window.fetchOrganizationAuthorizations = fetchOrganizationAuthorizations;

  // close navbar when link is clicked 
  useEffect(() => {
    return history.listen((location) => { 
      setOpen(false);
    }) 
  },[history]) 

  // close sidebar when resizing under 1151px
  var limitFunc = function(){
    // if (window.innerWidth<1151){
    if (window.innerWidth<1280){

      setOpen(false);
    }
  };
 
  window.addEventListener("resize", limitFunc);

  const hasPermission = (permissionIdentifier) => {
    return !permissions.some(permission => permission.identifier == permissionIdentifier);
  };

  return (
    <React.Fragment>
      {loaded && (
        <OrganizationContext.Provider value={{organizationAuthorizations, fetchOrganizationAuthorizations, hasPermission, permissions, organizationUsers, labels, fetchLabels, setLabels, taskTemplates, fetchTaskTemplates, setTaskTemplates}}>
          <Switch>
            <Route path="/o/:organization_id/projects" render={() => <Projects refetchSubscriptions={fetchOrganizationAuthorizations}/>} />
            <Route path="/o/:organization_id" render={() => <Projects refetchSubscriptions={fetchOrganizationAuthorizations}/>} />
          </Switch>
        </OrganizationContext.Provider>
      )}
    </React.Fragment>
  );
};

export default withRouter(Show);