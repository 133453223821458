import React , { useState, useEffect, useContext } from 'react';
import axios from "axios"; 
import animationData from "../Shared/Lotties/115518-locker-and-secure.json";
import { Player } from "@lottiefiles/react-lottie-player";
import Feedback from "./Feedback.js";
import { Modal } from 'react-responsive-modal';

const Premium = ({organization_id}) => {
  const [loaded, setLoaded] = useState(false)
  const [organizationUser, setOrganizationUser] = useState(false);
  const [organizationTitle, setOrganizationTitle] = useState("")
  const [subscription, setSubscription] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [url, setUrl] = useState(null);
  const [code, setCode] = useState("");
  const [showCode, setShowCode] = useState(false);
  const [nextBill, setNextBill] = useState(null);
  const [data, setData] = useState(null);
  const [storageSize, setStorageSize] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchSubscription()
  },[])

  useEffect(() => {
    if(subscription != null && subscription.id != null){
      fetchSubscriptionInfo()
      // notice(subscription.id)
    }
  },[subscription])

  const fetchSubscription = () => {
    axios.get(`/api/o/${organization_id}/subscriptions.json`)
    .then(function(response){
      console.log(response);
      setOrganizationUser(response.data.authorizations.organization_user);
      setOrganizationTitle(response.data.authorizations.organization.title);
      setSubscription(response.data.authorizations.subscription);
      setOrganization(response.data.authorizations.organization)
      setLoaded(true);
    })
  }

  const updateSubscription = () => {
    axios.get(`/api/o/${organization_id}/subscriptions/${subscription?.id}/subscription_edit?href=${window.location.href }`)
    .then(function(response){
      console.log(response);
      window.location.href = response.data.session.url
    })
  }

  const fetchSubscriptionInfo = () => {
    axios.get(`/api/o/${organization_id}/subscriptions/${subscription.id}/subscription_info.json`)
    .then(function(response){
      console.log(response);
      setNextBill(response.data.next_bill);
      setData(response.data.data);
      setStorageSize(response.data.storage_size)
    })
  }

  const fetchCheckoutUrl = () => {
    axios.post(`/api/o/${organization_id}/stripe_checkout?redirect_path=${window.location.href}&tolt_referral=${window.tolt_referral}`)
    .then(function(response){
      // console.log(response);
      window.location.replace(response.data.payment_url);
      refetchData();
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // setDisabled(false)
    });
  }

  const addInviteCode = (e) => {
    e.preventDefault();
    axios.post(`/api/o/${organization_id}/add_invite_code`,{
      user: {
        code: code
      }
    })
      .then(function(response){
        fetchSubscription();
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        // setDisabled(false)
      });
  }

  return(
    loaded ? (
      organizationUser ? (
        <React.Fragment>
          {subscription === null ?(
            <React.Fragment>
              {organization.tier != null ? (
                <React.Fragment>
                  <div className="height-100p display-flex medium-padding border-all-white border-radius" style={{flexDirection: "column"}}>
                    <h4>Plan information</h4>
                    <div className="mb-15 color-black-always display-block">
                      <strong>Tier:</strong> EarlyBird {organization.tier}
                    </div>

                    <div className="mb-15 color-black-always display-block">
                      <strong>Workspace member limit:</strong> 
                      {organization.tier == 1 && 
                        "2"
                      }

                      {organization.tier == 2 && 
                        "10"
                      }

                      {organization.tier == 3 && 
                        "Unlimited"
                      }
                    </div>

                    <div className="mb-15 color-black-always display-block">
                      <strong>Free storage limit:</strong> 
                      {organization.tier == 1 && 
                        "2TB"
                      }

                      {organization.tier == 2 && 
                        "4TB"
                      }

                      {organization.tier == 3 && 
                        "15TB"
                      }
                    </div>

                    <div className="mb-15 color-black-always display-block">
                      Storage used: {storageSize} Megabytes
                    </div>

                    <div className="mb-15 color-black-always">
                      <strong>Enterprise:</strong> {organization.enterprise ? "True" : "False"}
                    </div>

                    <div className="mb-15 color-black-always display-block">
                      <strong>Code:</strong> {organization.code}
                    </div>
                  </div>
                </React.Fragment>
              ):(
                <React.Fragment>
                  <div className="workspace-active-subscription-wrapper">
                    <div className="animated fadeIn slow-1">
                      <div className="mt-15 text-center medium-padding">
                        <a className="btn btn-primary" onClick={fetchCheckoutUrl}>Upgrade</a>
                      </div>

                      <div className="text-center">
                        <a href="https://www.usequeue.com/pricing" target="_blank" className="color-1 font-12 opacity-4 opacity-10-hover animate btn btn-small background-hover"><i class="far fa-external-link mr-8"></i>Pricing info</a>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          ):(
            <React.Fragment>
              {(data != null) && 
              <div className="workspace-active-subscription-wrapper">
                <div>
                  <div className="default-padding border-all border-radius background-3 animated fadeIn slow-1">
                    <div className="display-flex justify-content-between align-items-center">
                      <h4>Subscription info</h4>
                      <a href="https://www.usequeue.com/pricing" target="_blank" className="color-1 font-12 opacity-4 opacity-10-hover animate btn btn-small background-hover"><i class="far fa-external-link mr-8"></i>Pricing info</a>
                    </div>

                    {/* Status */}
                    <div className="row border-bottom">
                      <div className="col-6 default-padding opacity-4 pl-0 pr-0">
                        Plan status
                      </div>

                      <div className="col-6 default-padding text-right pl-0 pr-0">
                        <span className="badge background-active">{subscription.status}</span>
                      </div>
                    </div>

                    {/* Amount */}
                    <div className="row border-bottom">
                      <div className="col-6 default-padding opacity-4 pl-0 pr-0">
                        Next bill
                      </div>

                      <div className="col-6 default-padding text-right pl-0 pr-0">
                        {data.subscription.status === "canceled" ? (
                          <>
                            $0.00
                          </>
                        ):(
                          <>
                            {numberToCurrency((nextBill?.amount_due / 100.0), nextBill?.currency)}
                          </>
                        )}
                      </div>
                    </div>

                    {/* Users */}
                    <div className="row border-bottom">
                      <div className="col-6 default-padding opacity-4 pl-0 pr-0">
                        Team size
                      </div>

                      <div className="col-6 default-padding text-right pl-0 pr-0">
                        {data.total_users_count}
                      </div>
                    </div>

                    {/* Storage usage */}
                    <div className="row border-bottom">
                      <div className="col-6 default-padding opacity-4 pl-0 pr-0">
                        Storage used
                      </div>

                      <div className="col-6 default-padding text-right pl-0 pr-0">
                        {storageSize} Megabytes
                      </div>
                    </div>

                    {/* Renewal date */}
                    <div className="row border-bottom">
                      <div className="col-6 default-padding opacity-4 pl-0 pr-0">
                        Next payment attempt
                      </div>

                      <div className="col-6 default-padding text-right pl-0 pr-0">
                        {data.subscription.status === "canceled" ? (
                          <>
                            Plan ends on {new Date(data.subscription.current_period_end * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' })}
                          </>  
                        ):(
                          <>
                          {nextBill ? (
                            <>
                              {new Date(nextBill?.next_payment_attempt * 1000).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit', timeZoneName: 'short' })}
                            </>
                          ) : (
                            "N/A" 
                          )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="mt-15 text-right">
                      {data.subscription.status === "canceled" ? (
                        <>
                          <a onClick={fetchCheckoutUrl} className="btn btn-primary">Resubscribe</a>
                        </>
                      ):(
                        <>
                          <a onClick={() => setOpen(true)} className="btn btn-danger mr-15">Cancel subscription</a>
                          <a onClick={updateSubscription} className="btn btn-primary">Update payment method</a>
                          
                          <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
                            <h4 className="color-black-always modal-title">Cancel subscription</h4>

                            <div className="row no-margin">
                              <Feedback organization_id={organization_id} updateSubscription={updateSubscription}/>
                            </div>
                          </Modal>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              }

              {/* <div className="row default-padding">
                <div className="col-md-9">
                  <h3 className="mt-0">Current plan</h3>
                  <h5>
                    Status: {subscription.status}
                  </h5>
                </div>

                <div className="col-md-3 text-right">
                  {subscription.status === "canceled" && 
                    <button onClick={fetchCheckoutUrl} className="btn btn-primary width-100-percent mb-15">Resubscribe</button>
                  }
                  
                  {(subscription.status === "active" || subscription.status === "paused") && 
                    <button onClick={updateSubscription} className="btn btn-primary width-100-percent mb-15">Modify payment method</button>
                  }
                </div>
              </div> */}
            </React.Fragment>
          )}
        </React.Fragment>
      ):(
        <React.Fragment>
          <div className="flex-center">
          <Player style={{width: "300px", margin: "auto"}} mode="normal" autoplay loop src={animationData} />
          <p className="default-padding text-center">{organizationTitle} needs to be upgraded before you can do this.</p>
          </div>
        </React.Fragment>
      )
    ):(
      <div className="flex-center large-padding">
        <i className="fas fa-spinner fa-spin"></i>
      </div>
    )
  )
}

export default Premium