import React , { useState, useContext } from 'react';
import { useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { UserContext } from "../Shared/UserContext.js";
import Cookies from 'js-cookie';
import { PickerOverlay } from 'filestack-react';
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import LocalizationCountries from '../Daas/Portal/LocalizationCountries.js';

const Edit = ({closeModal}) => {
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const [theme, setTheme] = useState(Cookies.get('color_theme') || "System"); // State to hold the selected theme
  const [open, setOpen] = useState(false);
  const [avatar,setAvatar] = useState(currentUser.avatar); 
  const [email, setEmail] = useState(currentUser.email)
  const [username, setUsername] = useState(currentUser.username);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  // Function to handle theme change
  const handleThemeChange = (event) => {
    setTheme(event.target.value);
    // Calculate the expiration date for 10 years from now
    const expirationDate = new Date();
    expirationDate.setFullYear(expirationDate.getFullYear() + 10);

    // Set the cookie with the expiration date
    Cookies.set('color_theme', event.target.value, { expires: expirationDate });

    // Perform a full page reload, bypassing the cache
    window.location.reload(true);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/email_available.json?email=${document.getElementById("user_email").value}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        updateUser()
      } else {
        notice("That email already exists");
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const updateUser = () => {
    setDisabled(true);

    axios.put(`/api/users/${currentUser.token}`, {
      token: currentUser.token, 
      filepicker_url: avatar, 
      username: username, 
      email: email 
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        // notice("Account updated")
        checkLogin();
        closeModal();
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  // Filestack
  const uploadAvatar = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    console.log(filesUploaded[0]);
    setAvatar(filesUploaded[0].url);
  }

  return (
    <React.Fragment>
      <div className="row">
        <form onSubmit={handleSubmit} class="update_user" id="update_user">
          <div className="row">
            <div class="col-12">
              <div class="field display-row" style={{alignItems: "center"}}>
                <img
                  src={avatar}
                  className="user-update-image cursor-pointer"
                  onClick={() => setOpen(true)}
                />

                <div class="">
                  <a onClick={() => setOpen(true)} className="ml-15 color-1 font-12 opacity-6 opacity-10-hover btn btn-small background-hover">
                    {t("Change")}
                  </a>
                  
                  {open && 
                    <PickerOverlay
                      apikey={"AKVhxMbEQhkOIryqBvSEQz"}
                      onSuccess={(res) => console.log(res)}
                      onUploadDone={(res) => {
                        uploadAvatar(res);
                      }}
                      clientOptions={{
                        sessionCache: true
                      }}
                      pickerOptions={{
                        onClose: () => {
                          setOpen(false);
                        }, 
                        fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
                        accept: ["image/png", "image/jpeg"],
                        maxFiles: 1,
                        storeTo: {
                          location: 'gcs',
                          path: '/',
                          container: 'queue-filestack'
                        }
                      }}
                    />
                  }
                </div>
              </div>

              <div class="field">
                <label class="color-black-always">{t("Name")}</label>
                <input
                  defaultValue={currentUser.username}
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-control"
                  name="user[username]"
                  required="true"
                />
              </div>

              <div class="field">
                <label class="color-black-always">{t('Email')}</label>
                <input
                  defaultValue={currentUser.email}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="form-control"
                  name="user[email]"
                  required="true"
                  type="email"
                  id="user_email"
                />
                <div id="emailStatus" class="color-black-always"></div>
              </div>

              <div className="field">
                <LocalizationCountries />
              </div>

              <div class="field text-right">
                <button className="btn btn-primary" type="submit" disabled={disabled}>
                  {t("Submit")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

export default Edit