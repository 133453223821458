import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import {UserContext} from "../Shared/UserContext.js";
import _ from 'lodash';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { lazy, Suspense } from 'react';
import OrganizationUsers from "./OrganizationUsers.js";
import Clients from "./Clients.js";

const People = ({}) => {
  const currentUser = useContext(UserContext);
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [copied, setCopied] = useState(false)
  const [organizationUsers, setOrganizationUsers] = useState([]);
  const [projectUsers, setProjectUsers] = useState([])
  const [clients, setClients] = useState([]); 
  const [organization, setOrganization] =  useState(); 
  const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [roles, setRoles] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [invites, setInvites] = useState([]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam !== null) {
      setActiveTab(parseInt(tabParam, 10));
    }
  }, [location.search]);

  const handleTabChange = (index) => {
    setActiveTab(index);
    history.push(`${location.pathname}?tab=${index}`);
  };

  useEffect(() => {
    fetchPeople();
    fetchRoles();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false)
    }, 1500)
  },[copied])

  const fetchPeople = () => {
    axios.get(`/api/o/${match.params.organization_id}/people.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setOrganizationUsers(response.data.organization_users);
        setProjectUsers(response.data.project_users);
        setClients(response.data.clients);
        setOrganization(response.data.organization)
        setCompanies(response.data.companies);
        setInvites(response.data.invites);
        setLoaded(true)
      } else {
        notice(response.data.error);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
    });
  }

  const fetchRoles = () => {
    axios.get(`/api/o/${match.params.organization_id}/roles.json`)
    .then(function(response){
      if(response.data.success){
        setRoles(response.data.roles);
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {

    });
  }

  return(
    loaded && 
    <React.Fragment>
      <title>People</title>

      <div className={`queue-platform-header queue-platform-button-group`}>
        <div className="btn-group queue-btn-pills" style={{width: "fit-content"}}>
          <NavLink exact activeClassName="active" className="btn btn-small btn-default color-1 queue-btn-group-btn" to={`/o/${match.params.organization_id}/people/team`}>Team</NavLink>
          <NavLink exact activeClassName="active" className="btn btn-small btn-default color-1 queue-btn-group-btn" to={`/o/${match.params.organization_id}/people/clients`}>Clients</NavLink>
        </div>
      </div>

      <div className="queue-platform-content default-margin">
        <Switch>
          <Route path={`/o/:organization_id/people/team`} render={() => <OrganizationUsers invites={invites} organizationUsers={organizationUsers} roles={roles} refetchData={() => {fetchPeople(); fetchRoles();}} organization={organization} setOrganizationUsers={setOrganizationUsers}/>} />
          <Route path={`/o/:organization_id/people/clients`} render={() => <Clients clients={clients} setClients={setClients} companies={companies} refetchData={() => {fetchPeople(); fetchRoles();}} invites={invites} roles={roles}/>} />
        </Switch>
      </div>
    </React.Fragment>
  )
}

export default People