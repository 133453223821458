import React, { useEffect, useState } from "react";
import "./styles.css";
import { Gantt, Task, ViewMode } from "gantt-task-react";
import { initTasks, getStartEndDateForProject } from "./Helpers";
import { ViewSwitcher } from "./ViewSwitcher";
import { Modal } from "react-responsive-modal";
import FullCardV2 from "../../Daas/Portal/Projects/FullCardV2";

export default function App({columns, projects, refetchData}) {
  const currentDate = new Date();
  const [projectTasks, setProjectTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [view, setView] = useState<ViewMode>(ViewMode.Day);
  const [tasks, setTasks] = useState([]);
  const [isChecked, setIsChecked] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);


  let columnWidth = 60;
  if (view === ViewMode.Month) {
    columnWidth = 300;
  } else if (view === ViewMode.Week) {
    columnWidth = 250;
  }

  useEffect(() => {
    setIsLoading(true);
    // First format the projects
    const formattedProjects = projects.map(project => ({
      start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 15),
      name: `📋 ${project.title}`,  // Added emoji to distinguish projects
      id: `Project-${project.id}`,
      progress: 0,
      type: "project",
      hideChildren: true
    }));
    
    // Get all tasks from columns array and format them
    const formattedTasks = columns.flatMap(column => 
      column.tasks.map(task => {
        let progress = 0;
        if (task.subtasks) {
          const totalSubtasks = task.subtasks.length;
          const completedSubtasks = task.subtasks.filter(subtask => subtask.column.stage === 'done').length;
          progress = totalSubtasks > 0 ? Math.round((completedSubtasks / totalSubtasks) * 100) : 0;
        }

        return {
          start: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
          end: new Date(currentDate.getFullYear(), currentDate.getMonth(), 2, 12, 28),
          name: task.title,  // Just the task title
          id: task.token,
          progress: progress,
          type: "task",
          project: `Project-${task.project.id}`,  // This links to the parent project
          dependencies: [], 
          object: task
        };
      })
    );

    // Instead of simply concatenating, let's group tasks by project
    const allTasks = formattedProjects.reduce((acc, project) => {
      // Add the project first
      acc.push(project);
      // Then add all tasks that belong to this project
      const projectTasks = formattedTasks.filter(
        task => task.project === project.id
      );
      acc.push(...projectTasks);
      return acc;
    }, []);

    console.log('Formatted Tasks:', allTasks);
    setProjectTasks(allTasks);
    setTasks(allTasks);
    setIsLoading(false);
  }, [projects, columns]);

  const handleTaskChange = (task: Task) => {
    console.log("On date change Id:" + task.id);
    let newTasks = tasks.map((t) => (t.id === task.id ? task : t));
    if (task.project) {
      const [start, end] = getStartEndDateForProject(newTasks, task.project);
      const project =
        newTasks[newTasks.findIndex((t) => t.id === task.project)];
      if (
        project.start.getTime() !== start.getTime() ||
        project.end.getTime() !== end.getTime()
      ) {
        const changedProject = { ...project, start, end };
        newTasks = newTasks.map((t) =>
          t.id === task.project ? changedProject : t
        );
      }
    }
    setTasks(newTasks);
  };

  const handleTaskDelete = (task: Task) => {
    const conf = window.confirm("Are you sure about " + task.name + " ?");
    if (conf) {
      setTasks(tasks.filter((t) => t.id !== task.id));
    }
    return conf;
  };

  const handleProgressChange = async (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On progress change Id:" + task.id);
  };

  const handleDblClick = (task: Task) => {
    console.log("On Double Click event Id:" + task);
    setSelectedTask(task.object);
    setOpen(true);
  };

  const handleSelect = (task: Task, isSelected: boolean) => {
    console.log(task.name + " has " + (isSelected ? "selected" : "unselected"));
  };

  const handleExpanderClick = (task: Task) => {
    setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    console.log("On expander click Id:" + task.id);
  };

  return (
    <>
      <div style={{marginTop: 30, padding: 15}}>
        <ViewSwitcher
          onViewModeChange={(viewMode: ViewMode) => setView(viewMode)}
          onViewListChange={setIsChecked}
          isChecked={isChecked}
        />
        {!isLoading && (
          <Gantt
            tasks={tasks}
            viewMode={view}
            onDateChange={handleTaskChange}
            onDelete={handleTaskDelete}
            onProgressChange={handleProgressChange}
            onDoubleClick={handleDblClick}
            onSelect={handleSelect}
            onExpanderClick={handleExpanderClick}
            listCellWidth={isChecked ? "155px" : ""}
            columnWidth={columnWidth}
          />
        )}
      </div>

      <Modal open={open} onClose={() => setOpen(false)} classNames={{modal: 'modal-fullcard'}} center>
        <FullCardV2 
          task_id={selectedTask?.token} 
          refetchData={refetchData} 
          organizationUser={true} 
          project_id={selectedTask?.project?.token}
        />
      </Modal>
    </>
  );
}
