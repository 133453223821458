import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import axios from "axios"; 
import Edit from "./Edit.js";
import { UserContext } from "../../Shared/UserContext.js";
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import ReactTooltip from 'react-tooltip';
import Select from 'react-select';
import InviteNewUser from "../../Users/Invite.js";
import { Modal } from 'react-responsive-modal';
import ProjectUser from "./ProjectUser.js";
import ClientUser from "./ClientUser.js";
import {OrganizationContext} from "../../Shared/OrganizationContext.js";
import ClientInvite from "./ClientInvite.js";
import { ProjectWrapperContext } from "../ProjectWrapperContext.js";

const Invite = ({refetchData, hide, addTeammate }) => {
  const {clients, projectUsers, organizationUsers, adminOrganizationUsers, parentProjectClientUsers} = useContext(ProjectWrapperContext);
  const {hasPermission} = useContext(OrganizationContext)
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [inviteLinks, setInviteLinks] = useState();
  const [copied, setCopied] = useState(false);
  const [organizationUser, setOrganizationUser] = useState(false)
  const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const [selectedUser, setSelectedUser] = useState(null);
  const [openInvite, setOpenInvite] = useState(false);
  const [openClientInvite, setOpenClientInvite] = useState(false);
  const [invites, setInvites] = useState([]);

  useEffect(() => {
    fetchInvites();

    axios.get(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/invite_links.json`)
    .then(function(response){
      setOrganizationUser(response.data.organization_user)
      setInviteLinks(response.data.invite_links);
      setLoaded(true);

    })
  },[]);

  const fetchInvites = () => {
    axios.get(`/api/o/${match.params.organization_id}/invites.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setInvites(response.data.invites);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const invitesList = invites.filter(invite => invite.project_token === match.params.project_id).map((invite) => {
    const handleRemoveInvite = () => {
      axios.delete(`/api/o/${match.params.organization_id}/invites/${invite.token}.json`)
      .then(function(response){
        fetchInvites();
      })
    };

    return (
      <div className="display-flex justify-content-between align-items-center mb-15 hover-parent-opacity" key={invite.id}>
        <div className="display-flex align-items-center">
          <img src={`https://ui-avatars.com/api/?name=${invite.email[0]}&length=1&background=340613&color=fff&format=png`} className="avatar mr-8" />
          {invite.email}
          <div className="hover-child-opacity ml-8" onClick={handleRemoveInvite}>
            <i className="fas fa-times cursor-pointer opacity-4 opacity-10-hover animate btn btn-small background-hover"></i>
          </div>
        </div>
        <span className="badge badge-orange border-all opacity-4 margin-none">Pending</span>
      </div>
    )
  });
  
  const admin_organization_users_list = adminOrganizationUsers.map((user, index) => {
    
    const handleToggle = () => {
      setDisabled(true);
     if(user.account_manager){
      axios.delete(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/account_managers/${user.account_manager?.token}`)
      .then(function(response){
        console.log(response);
        refetchData();
        setDisabled(false);
      })
      .catch(function(error){
        console.log(error);
        setDisabled(false);
      })
     } else {
      axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/account_managers`, {user_id: user.user_id})
      .then(function(response){
        console.log(response);
        refetchData();
        setDisabled(false);
      })
      .catch(function(error){
        console.log(error);
        setDisabled(false);
      })
     }
    }
    
    return(
      <React.Fragment key={user.email}>
        <div className="display-flex justify-content-between align-items-center mb-15 hover-parent-opacity">
          <div className="display-flex align-items-center">
            <img src={user.avatar} className="avatar mr-8" />
            <div className="font-12 color-black-always">
              {user.username}
            </div>
          </div>

          <div className="font-12 display-flex align-items-center">
            <span className="ml-8 opacity-4">
              Account manager <i className="fal fa-question-circle ml-8" data-tip="Account Managers will be automatically assigned to all new requests."></i>
            </span>
            <div style={{scale: "0.7"}}>
              <Toggle icons={false} checked={user.account_manager} onChange={handleToggle}/>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  });

  const project_users_list = projectUsers.map((user, index) => {
    // Check if user.type is "Client" and user.private is true
    if ((location.href.includes("/portal/o/") && user.private)) {
      // If condition is met, return null or an empty fragment to skip rendering
      return null;
    }

    return(
      <React.Fragment key={user.email}>
        <ProjectUser user={user} refetchData={refetchData}/>
      </React.Fragment>
    )
  })

  const clients_list = clients.map((user, index) => {
    return(
      <React.Fragment key={user.email}>
        <ClientUser user={user} refetchData={refetchData}/>
      </React.Fragment>
    )
  });

  const getSelectOptions = () => {
    // Get existing options
    const existingEmails = new Set([
      ...adminOrganizationUsers.map(u => u.email),
      ...projectUsers.map(u => u.email),
      ...clients.map(u => u.email)
    ]);

    // Filter and format organization users
    const userOptions = [
      ...organizationUsers.map(user => ({
        value: user.email,
        label: `${user.username} (${user.email})`,
        kind: 'organizationUser'
      })),
      ...parentProjectClientUsers.map(user => ({
        value: user.email,
        label: `${user.username} (${user.email})`,
        kind: 'parentProjectClientUser'
      }))
    ].filter(user => !existingEmails.has(user.value));

    // Start with invite options
    return [
      { 
        value: 'invite', 
        label: (
          <div className="display-flex align-items-center">
            <i className="far fa-user-plus mr-8"></i>
            Invite teammate
          </div>
        ), 
        isInviteOption: true 
      },
      // Only add client invite option if user has permission
      ...(hasPermission("invite_users") ? [{
        value: 'invite_client', 
        label: (
          <div className="display-flex align-items-center">
            <i className="far fa-user-plus mr-8"></i>
            Invite client
          </div>
        ), 
        isClientInviteOption: true 
      }] : []),
      // Add user options after invite options
      ...userOptions
    ];
  };

  const handleSelectChange = (option) => {
    if (option?.isInviteOption) {
      setOpenInvite(true);
      setSelectedUser(null);
    } else if (option?.isClientInviteOption) {
      setOpenClientInvite(true);
      setSelectedUser(null);
    } else {
      setSelectedUser(option);
      handleAddUser(option);
    }
  };

  const handleAddUser = (selectedOption) => {
    if (!selectedOption) return;

    axios.post(`/api/o/${match.params.organization_id}/projects/${match.params.project_id}/add_user`, {
      email: selectedOption.value, 
      kind: selectedOption.kind
    })
    .then(function(response) {
      refetchData(); // Refresh the user lists
      setSelectedUser(null); // Reset selection
    })
    .catch(function(error) {
      console.error(error);
      notice("Failed to add user", "error");
    });
  };

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      <div className="field">
        {hide == null && 
          <div className="display-flex justify-content-between align-items-center mb-25">
            <h4 className="margin-none">
              {t("Members")}
            </h4>
            {(hasPermission("invite_users") && loaded) && 
              <CopyToClipboard text={`${inviteLinks.client_invite}`} onCopy={() => {notice("Invite link copied"); setCopied(true); setTimeout(function(){setCopied(false)}, 2000);}}>
                {copied ? (
                  <a className="animated fadeIn btn btn-small background-hover opacity-6 font-12 cursor-pointer opacity-10-hover color-1">Copied<i class="fal fa-check ml-8"></i></a>
                ):(
                  <a className="animated fadeIn btn btn-small background-hover opacity-6 font-12 cursor-pointer opacity-10-hover color-1" data-tip="Copy invite link">Invite client<i class="fal fa-copy ml-8"></i></a>
                )}
              </CopyToClipboard>
            }
          </div>
        }

        {/* <div className="row display-flex justify-content-between align-items-center mb-15 hover-parent-opacity font-12">
          <div className="display-flex align-items-center col-8">
            Member
          </div>

          <div className="font-12 display-flex align-items-center col-4">
            Account Manager <i className="fal fa-question-circle ml-8" data-tip="Account Managers will be automatically assigned to all new requests."></i>
          </div>
        </div> */}

        {admin_organization_users_list}
        {project_users_list}
        {clients_list}
        {invitesList}

        {hasPermission("invite_users") && 
          <Select
            value={selectedUser}
            onChange={handleSelectChange}
            options={getSelectOptions()}
            className="color-1"
            classNamePrefix="react-select"
            placeholder="Add members"
          />
        }
      </div>

      <Modal open={openInvite} onClose={() => setOpenInvite(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <InviteNewUser project_id={match.params.project_id} refetchData={() => {refetchData(); fetchInvites()}} closeModal={() => setOpenInvite(false)}/> 
      </Modal>

      <Modal open={openClientInvite} onClose={() => setOpenClientInvite(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <ClientInvite project_id={match.params.project_id} refetchData={() => {refetchData(); fetchInvites()}} closeModal={() => setOpenClientInvite(false)}/> 
      </Modal>
    </React.Fragment>
  )
}

export default Invite;