import React , { useContext, useState } from 'react';
import {OrganizationContext} from "../../../../Shared/OrganizationContext.js";
import { Modal } from 'react-responsive-modal';
import NewLabel from "../../../../Labels/NewLabel.js";

const AssignLabelsForm = ({setSelectedLabels, selectedLabels}) => {
  const {labels} = useContext(OrganizationContext);
  const [openNewLabel, setOpenNewLabel] = useState(false);

  return(
    <React.Fragment>
      <div className="btn-group dropup ml-8">
        <button type="button" className="btn btn-default btn-small dropdown-toggle background-hover-2" data-toggle="dropdown">
          <i className="fal fa-tag mr-8"></i>
          {selectedLabels.length > 0 ? `${selectedLabels.length} labels selected` : 'Labels'}
        </button>

        <ul className="dropdown-menu animated fadeInUp" role="menu" style={{maxHeight: '300px', overflowY: 'auto'}}>
          {labels.map((label) => {
            const isSelected = selectedLabels.find(l => l.value === label.token);
            return (
              <li key={label.id}>
                <a onClick={(e) => {
                  e.stopPropagation();
                  if (isSelected) {
                    setSelectedLabels(selectedLabels.filter(l => l.value !== label.token));
                  } else {
                    setSelectedLabels([...selectedLabels, {
                      value: label.token,
                      label: label.title,
                      color: label.color,
                    }]);
                  }
                }}>
                  <i className={`fal fa-${isSelected ? 'check-square' : 'square'} mr-8`}></i>
                  <i className={`fas fa-circle`} style={{ color: `#${label.color}` }}></i>
                  {label.title}
                </a>
              </li>
            );
          })}

          <li><a onClick={() => setOpenNewLabel(true)}><i className="fal fa-plus mr-8"></i>Add new label</a></li>
        </ul>
      </div>

      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => {setOpenNewLabel(false);}}/>
      </Modal>
    </React.Fragment>
  )
}

export default AssignLabelsForm;