import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import {UserContext} from "../../Shared/UserContext.js";
import DashboardSkeleton from "./DashboardSkeleton.js";
import Analytics from "./Analytics.js";
import KanbanBoard from "../../Dashboard/KanbanBoard.js";
import ReactTooltip from 'react-tooltip';
import People from "../../Users/People.js";

const Dashboard = ({projectAuthorizations}) => {
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);

  return(
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>     
      <Switch>
        <Route exact path={`/o/:organization_id/dashboard`} render={() => <KanbanBoard projectAuthorizations={projectAuthorizations} fetchPath={"dashboard_columns"}/> } />
        <Route exact path={`/o/:organization_id/dashboard/my-tasks`} render={() => <KanbanBoard projectAuthorizations={projectAuthorizations} fetchPath={"my_tasks"}/> } />
        <Route exact path={`/o/:organization_id/dashboard/analytics`} render={() => <Analytics projectAuthorizations={projectAuthorizations}/> } />
      </Switch>
    </React.Fragment>
  )
}

export default Dashboard