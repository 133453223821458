import React from 'react';
import {Menu, Item, Separator, useContextMenu} from "react-contexify";
import { Portal } from 'react-portal';

const SupportDropdown = ({}) => {
  // Update the handleContextMenu to prevent default right-click menu
  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ id: `dropdown` }).show({ event: e });
  };

  return(
    <React.Fragment>
      <a style={{textAlign: "left", marginBottom: 10}} className="color-1 btn btn-small display-block background-hover opacity-7 opacity-10-hover" onClick={handleContextMenu}><i className="fal fa-headset mr-8"></i> Need help?</a>
      <Portal>
        <Menu id={`dropdown`}>
          <Item onClick={() => {
            window.postMessage({
              target: 'FeaturebaseWidget',
              data: { 
                action: 'openFeedbackWidget',
                setBoard: 'feedback',
              }
            })
          }}>
            <i className="fal fa-bug"></i> Report a bug
          </Item>

          <Item onClick={() => window.open('https://roadmap.usequeue.com', '_blank')}>
            <i className="fal fa-thumbs-up"></i> Vote on new features
          </Item>

          <Separator />

          <Item onClick={() => window.open('https://discord.gg/qBzqz8XB7V', '_blank')}>
            <i className="fab fa-discord"></i> Join our community
          </Item>

          <Item onClick={() => window.open('https://guides.usequeue.com', '_blank')}>
            <i className="fal fa-play-circle"></i> Guides
          </Item>

          <Item onClick={() => window.open('https://roadmap.usequeue.com/changelog', '_blank')}>
            <i className="fal fa-sync"></i> Latest updates
          </Item>

          <Separator />

          <Item onClick={() => window.Intercom('show')}>
            <i className="fal fa-headset"></i> Talk to support
          </Item>
        </Menu>
      </Portal>
    </React.Fragment>
  )
}

export default SupportDropdown;