import React, { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import DateField from './DateField';
import LinkField from './LinkField';
import RadioField from './RadioField';
import TextField from './TextField';
import UploadField from './UploadField';
import CheckboxField from './CheckboxField';
import TextareaField from './TextareaField';

const Field = ({field, setSections, setFields}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const renderFieldValue = () => {
    switch (field.kind || field.type) {
      case 'text':
        return (
          <TextField field={field} setSections={setSections} setFields={setFields}/>
        );
      case 'textarea':
        return (
          <TextareaField field={field} setSections={setSections} setFields={setFields}/>
        );
      case 'checkbox':
        return (
          <CheckboxField field={field} setSections={setSections} setFields={setFields}/>
        );
      case 'radio':
        return (
          <RadioField field={field} setSections={setSections} setFields={setFields}/>
        );
      case 'link':
        return (
          <LinkField field={field} setSections={setSections} setFields={setFields}/>
        );
      case 'date':
        return (
          <DateField field={field} setSections={setSections} setFields={setFields}/>
        );
      case 'upload':
        return (
          <UploadField field={field} setSections={setSections} setFields={setFields}  />
        );
      default:
        return <span>null</span>;
    }
  }

  return(
    <React.Fragment>
      <div>
        {renderFieldValue()}
      </div>
    </React.Fragment>
  )
}

export default Field;