import React, { forwardRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Modal } from 'react-responsive-modal';

const AssignDeadlineForm = ({ dateRange, setDateRange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  return (
    <>
        <a 
          onClick={handleClick}
          className="btn btn-small btn-default background-hover-2 ml-8 color-1"
        >
          <i className="fal fa-calendar-alt mr-8"></i>
          {dateRange[0] && dateRange[1] 
            ? `${dateRange[0].toLocaleDateString()} - ${dateRange[1].toLocaleDateString()}`
            : "Due date"}

            {dateRange[0] && dateRange[1] && (
              <a 
                onClick={(e) => {
                  e.preventDefault();
                  setDateRange([null, null]);
                }}
                className="color-1 ml-8"
              >
                <i className="fas fa-times"></i>
              </a>
            )}
        </a>


      <Modal open={isOpen} onClose={() => setIsOpen(false)} classNames={{modal: 'calendar-modal'}} center>
        <DatePicker 
          withPortal
          selectsRange={true}
          startDate={dateRange[0]}
          endDate={dateRange[1]}
          onChange={(update) => {
            setDateRange(update);
          }}
          inline
          className="btn btn-small btn-default background-hover-2 width-110 cursor-pointer ml-8"
          placeholderText="Date range"
        />
        <p className="text-center font-12 opacity-5 mt-5">Select a start and due date</p>
      </Modal>
    </>
  );
};

export default AssignDeadlineForm;