import React , { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom'
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import { ProjectWrapperContext } from "./ProjectWrapperContext.js";
import axios from "axios";
import "./breadcrumbs.css"

const ResourceBreadcrumb = ({ match, resourceType }) => {
  const [title, setTitle] = useState();
  const { organization_id, project_id } = match.params;
  const resourceId = match.params[`${resourceType}_id`];

  useEffect(() => {
    axios.get(`/api/o/${organization_id}/projects/${project_id}/breadcrumbs?${resourceType}_id=${resourceId}`)
      .then(response => {
        if (response.data.success) {
          setTitle(response.data.title);
        }
      });
  }, [organization_id, resourceId, resourceType]);

  return title;
};

const ProjectResourceBreadcrumb = ({ match }) => {
  const [project, setProject] = useState();
  const [parentProject, setParentProject] = useState();
  const { organization_id, project_id } = match.params;
  const CACHE_KEY = `project-${project_id}-breadcrumbs-v2`;

  useEffect(() => {
    const cachedData = sessionStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { project: cachedProject, parent_project: cachedParentProject } = JSON.parse(cachedData);
      setProject(cachedProject);
      setParentProject(cachedParentProject);
      return;
    }

    axios.get(`/api/o/${organization_id}/projects/${project_id}/project_breadcrumbs`)
    .then(response => {
      if (response.data.success) {
        setProject(response.data.project);
        setParentProject(response.data.parent_project);
        // Using the same CACHE_KEY for storing
        sessionStorage.setItem(CACHE_KEY, JSON.stringify({
          project: response.data.project,
          parent_project: response.data.parent_project
        }));
      }
    });
  }, [organization_id, project_id]);

  return (
    project && 
    <React.Fragment>
      {parentProject?.token && (
        <>
          <Link className="breadcrumb-item breadcrumb-previous-path" to={`/o/${organization_id}/projects/${parentProject.token}/tasks`}>
            <img src={parentProject.avatar} className="project-list-avatar"/>
            {parentProject.title}
          </Link>
          <span className="breadcrumb-separator"> <i className="fal fa-angle-right font-16"></i> </span>
        </>
      )}
      <Link className="breadcrumb-item breadcrumb-previous-path" to={`/o/${organization_id}/projects/${project_id}/tasks`}>
        <img src={project.avatar} className="project-list-avatar"/>
        {project.title}
      </Link>
    </React.Fragment>
  );
};

// Replace individual breadcrumb components with ResourceBreadcrumb
const ProjectBreadcrumb = ({ match }) => <ResourceBreadcrumb match={match} resourceType="project" />;
const TaskBreadcrumb = ({ match }) => <ResourceBreadcrumb match={match} resourceType="task" />;
const DocumentBreadcrumb = ({ match }) => <ResourceBreadcrumb match={match} resourceType="document" />;
const EmbedBreadcrumb = ({ match }) => <ResourceBreadcrumb match={match} resourceType="embed" />;
const FolderBreadcrumb = ({ match }) => <ResourceBreadcrumb match={match} resourceType="folder" />;

// Custom breadcrumb components with custom links
const WorkspaceBreadcrumb = ({ match }) => (
  <Link className="color-1breadcrumb-item" to={`/o/${match.params.organization_id}/dashboard`}>Workspace</Link>
);

// Define your routes configuration
const routes = [
  { path: '/', breadcrumb: '' },
  { path: '/o', breadcrumb: WorkspaceBreadcrumb },
  { path: '/o/:organization_id', breadcrumb: WorkspaceBreadcrumb },
  { path: '/o/:organization_id/dashboard', breadcrumb: "All requests" },
  { path: '/o/:organization_id/dashboard/my-tasks', breadcrumb: "Assigned to me" },
  { path: '/o/:organization_id/projects', breadcrumb: "Clients" },
  { path: '/o/:organization_id/settings', breadcrumb: "Customization" },
  { path: '/o/:organization_id/workflow/client-onboarding', breadcrumb: 'Client onboarding' },
  { path: '/o/:organization_id/workflow/taskboard-templates', breadcrumb: 'Taskboard templates' },
  // { path: '/o/:organization_id/projects/:project_id', exact: false, breadcrumb: ParentProjectBreadcrumb },
  { path: '/o/:organization_id/projects/:project_id/', exact: true, breadcrumb: ProjectResourceBreadcrumb },
  { path: '/o/:organization_id/projects/:project_id/files', breadcrumb: 'Files' },
  { path: '/o/:organization_id/projects/:project_id/files/task_files', breadcrumb: 'Tasks' },
  { path: '/o/:organization_id/projects/:project_id/files/task_files/:task_id', breadcrumb: TaskBreadcrumb },
  { path: '/o/:organization_id/projects/:project_id/files/folders/:folder_id',   breadcrumb: FolderBreadcrumb },
  { path: '/o/:organization_id/projects/:project_id/embeds/:embed_id', breadcrumb: EmbedBreadcrumb },
  { path: '/o/:organization_id/projects/:project_id/documents/:document_id', breadcrumb: DocumentBreadcrumb },
  { path: '/o/:organization_id/projects/:project_id/payments', breadcrumb: 'Payments' },
  { path: '/o/:organization_id/projects/:project_id/deliverables', breadcrumb: 'Deliverables' },
  { path: '/o/:organization_id/projects/:project_id/tasks', breadcrumb: 'Requests' },
  { path: '/o/:organization_id/projects/:project_id/details', breadcrumb: 'Billing' },
  // Portal routes
  { path: '/portal', breadcrumb: '' },
  { path: '/portal/o', breadcrumb: WorkspaceBreadcrumb },
  { path: '/portal/o/:organization_id', breadcrumb: WorkspaceBreadcrumb },
  { path: '/portal/o/:organization_id/settings', breadcrumb: "Customization" },
  { path: '/portal/o/:organization_id/projects', breadcrumb: "Clients" },
  { path: '/portal/o/:organization_id/projects/:project_id', breadcrumb: ProjectBreadcrumb },
  { path: '/portal/o/:organization_id/projects/:project_id/files', breadcrumb: 'Files' },
  { path: '/portal/o/:organization_id/projects/:project_id/files/task_files', breadcrumb: 'Tasks' },
  { path: '/portal/o/:organization_id/projects/:project_id/files/task_files/:task_id', breadcrumb: TaskBreadcrumb },
  { path: '/portal/o/:organization_id/projects/:project_id/files/folders/:folder_id', breadcrumb: FolderBreadcrumb },
  { path: '/portal/o/:organization_id/projects/:project_id/embeds/:embed_id', breadcrumb: EmbedBreadcrumb },
  { path: '/portal/o/:organization_id/projects/:project_id/documents/:document_id', breadcrumb: DocumentBreadcrumb },
  { path: '/portal/o/:organization_id/projects/:project_id/payments', breadcrumb: 'Payments' },
  { path: '/portal/o/:organization_id/projects/:project_id/deliverables', breadcrumb: 'Deliverables' },
  { path: '/portal/o/:organization_id/projects/:project_id/tasks', breadcrumb: 'Requests' },
  { path: '/portal/o/:organization_id/projects/:project_id/details', breadcrumb: 'Billing' }
];

const Breadcrumbs = ({}) => {
  const Breadcrumbs = withBreadcrumbs(routes)(({ breadcrumbs }) => {
    const filteredBreadcrumbs = breadcrumbs.filter(({ match }) => 
      !['/', '/o', '/o/:organization_id', '/o/:organization_id/projects', `/o/:organization_id/projects/:project_id/files/folders`, '/portal', '/portal/o', '/portal/o/:organization_id', '/portal/o/:organization_id/projects'].includes(match.path)
    );
    
    return (
      <div className="breadcrumbs-container">
        {filteredBreadcrumbs.map(({ match, breadcrumb }, index) => {
          const isProjectBreadcrumb = match.path === '/o/:organization_id/projects/:project_id/';
          
          return (
            <React.Fragment key={match.url}>
              {index > 0 && <span className="breadcrumb-separator"> <i className="fal fa-angle-right font-16"></i> </span>}
              {isProjectBreadcrumb ? (
                // Special handling for project breadcrumb
                breadcrumb
              ) : (
                // Normal breadcrumb
                <Link 
                  className={`breadcrumb-item ${index < filteredBreadcrumbs.length - 1 ? 'breadcrumb-previous-path' : ''}`} 
                  to={breadcrumb.url_path || match.url}
                >
                  {breadcrumb}
                </Link>
              )}
            </React.Fragment>
          );
        })}
      </div>
    );
  });

  return(
    <React.Fragment>
      <Breadcrumbs />
    </React.Fragment>
  )
}

export default Breadcrumbs;