import React , { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import Role from "../Roles/Role.js";
import Form from "../Roles/Form.js";
import noaccess from "../../Shared/Lotties/lottie-shield.json";
import { Player } from "@lottiefiles/react-lottie-player";

const ClientRoles = ({refetchData, roles}) => {
  const [open, setOpen] = useState(false);

  const roles_list = roles.filter(role => role.is_client).map(role => {
    return(
      <Role role={role} refetchData={refetchData} />
    )
  })

  return(
    <React.Fragment>
      <div className="display-flex align-items-center justify-content-between mb-15 pr-15">
        {roles.filter(role => role.is_client).length > 0 && 
          <>
            <h4 className="">{roles.filter(role => role.is_client).length} available roles</h4>
            <a className="btn btn-primary btn-small" onClick={() => setOpen(true)}><i class="fas fa-plus mr-8"></i>Create role</a>
          </>
        }
      </div>

      <div className="row">
        {roles.filter(role => role.is_client).length > 0 ?(
          roles_list
        ) :(
          <>
            <div className="display-flex flex-direction-column min-height-200 default-padding text-center align-items-center justify-content-center">
              <Player style={{width: "150px", margin: "auto"}} mode="normal" loop autoplay src={noaccess} />
              <h4>Roles & Permissions</h4>
              <p>Create Roles & Permissions to manage what your clients can access.</p>
              <div className="text-center mt-15">
                <a className="btn btn-primary btn-small" onClick={() => setOpen(true)}><i class="fas fa-plus mr-8"></i>Create role</a>
              </div>
            </div>
          </>
        )}
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <h4 className="color-black-always modal-title">Create new role</h4>

        <div className="row no-margin">
          <Form refetchData={refetchData} closeModal={() => setOpen(false)} is_client={true}/>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ClientRoles;