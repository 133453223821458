import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import CompanyIndex from "../Companies/Index.js";
import Select from 'react-select';
import Form from "../Organizations/Roles/Form.js";
import { confirmAlert } from 'react-confirm-alert';
import {OrganizationContext} from "../Shared/OrganizationContext.js";

const ClientUser = ({client, setClients, refetchData, companies, roles}) => {
  // const organizationAuthorizations = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [showCompany, setShowCompany] = useState(false);
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const {hasPermission} = useContext(OrganizationContext)

  useEffect(() => {
    // Initialize selectedUsers with existing company users
    if (client.companies) {
      const initialCompanies = client.companies.map(company => ({
        value: company.token,
        label: company.title,
      }));
      setSelectedCompanies(initialCompanies);
    }
  }, [client.companies]);

  const companyOptions = companies.map(company => ({
    value: company.token,
    label: company.title
  }));

  const handleCompanyChange = (newSelectedOptions) => {
    // Find added and removed companies
    const addedCompanies = newSelectedOptions.filter(
      option => !selectedCompanies.some(selected => selected.value === option.value)
    );
    const removedCompanies = selectedCompanies.filter(
      selected => !newSelectedOptions.some(option => option.value === selected.value)
    );

    // Call handleCompanyAdded for each added company
    addedCompanies.forEach(company => handleCompanyAdded(company));

    // Call handleCompanyRemoved for each removed company
    removedCompanies.forEach(company => handleCompanyRemoved(company));

    // Update the state with the new selection
    setSelectedCompanies(newSelectedOptions);
  };

  const removeClient = () => {
    confirmAlert({
      title: 'Are you sure?',
      message: `This will remove the client's access to all of their projects.`,
      buttons: [
        {
          label: 'Cancel',
          className: 'btn react-confirm-cancel-btn',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Delete',
          className: 'btn btn-danger',
          onClick: () => {
            axios.delete(`/api/o/${match.params.organization_id}/projects/${client.project.token}/clients/${client.token}`)
            .then(function(response){
              if(self){
                // fetchPeople();
                setClients(prevState => _.reject(prevState, { token: client.token }));
              } else {
                // fetchPeople();
              }
            })
          }
        },
      ]
    });
  };

  const handleCompanyAdded = (company) => {
    axios.post(`/api/o/${match.params.organization_id}/companies/${company.value}/company_users.json?user_id=${client.user.id}`)
    .then(function(response){
      // console.log(response);
      refetchData();
    })
    .catch(function(error){
      console.log(error);
    });
    // Add your logic for when a user is added
  }

  const handleCompanyRemoved = (company) => {
    console.log(`User removed: ${company.label} (ID: ${company.value})`);
    axios.delete(`/api/o/${match.params.organization_id}/companies/${company.value}/company_users/${client.user.id}?user_id=${client.user.id}`)
    .then(function(response){
      // console.log(response);
      refetchData();
    })
    .catch(function(error){
      console.log(error);
    });
    // Add your logic for when a user is removed
  }

  const handleOptionClick = (option, event) => {
    if (option.value !== 'add_company') {
      // Open a modal or perform any action for existing companies
      console.log(`Clicked on company: ${option.label}`);
      // You can add your modal opening logic here
    }
  };

  const user_companies_list = client?.companies?.map(company => (
    <a className="btn btn-small background-hover color-1 ml-15-negative opacity-6 opacity-10-hover" onClick={() => setShowCompany(true)}>
      <i className="fal fa-building mr-8"></i> {company.title}
    </a>
  ));

  const roleOptions = [
    {
      value: null,
      label: 'No role'
    },
    ...roles.filter(role => role.is_client).map(role => ({
      value: role.token,
      label: role.title
    })),
    {
      value: 'add_role',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <i className="fal fa-plus mr-8"></i>
          Add Role
        </div>
      )
    }
  ];


  const handleRoleChange = (selectedRole) => {
    if (selectedRole === null) {
      axios.post(`/api/o/${match.params.organization_id}/organization_users/${user.organization_user_id}/modify_role`, {
        role_id: null
      })
      .then((response) => {
        if(response.data.success){
          console.log(response.data.organization_user)

          setOrganizationUsers(prevUsers => 
            prevUsers.map(orgUser => 
              orgUser.token === response.data.organization_user.token
                ? response.data.organization_user
                : orgUser
            )
          );
        }
      })
      .catch((error) => {
        console.error('Error removing role:', error);
      });
      return;
    }

    if (selectedRole.value === 'add_role') {
      setRoleModalOpen(true);
      return;
    }

    axios.post(`/api/o/${match.params.organization_id}/projects/${client.project.token}/clients/${client.token}/modify_role`, {
      role_id: selectedRole.value
    })
    .then((response) => {
      notice("Done")
      setClients(prevUsers => 
        prevUsers.map(clientUser => 
          clientUser.token === response.data.client.token
            ? response.data.client
            : clientUser
        )
      );
      // refetchData();
    })
    .catch((error) => {
      console.error('Error updating role:', error);
    });
  };

  return(
    <React.Fragment>
      <tr className="queue-table-tr hover-parent-opacity" key={client.token}>
        <td>
          <div className="display-flex table-gap-8">
            <img src={client.user.avatar} className="queue-table-image" />
            <div>
              <div className="font-14">{client.user.username}</div>
                {hasPermission("client_email") ?(
                  <div className="opacity-4 font-12">{client.user.email}</div>
                ):(
                  <div data-tip="You don't have permission to view this email" className="no-permission-blur opacity-4 font-12">Permission denied</div>
                )}
            </div>
          </div>
        </td>

        <td>{client?.companies?.length > 0 ? user_companies_list : ''}</td>

        <td className="queue-table-td">{client.user.last_sign_in_at}</td>

        {/* <td>
          <Link to={`/o/${match.params.organization_id}/projects/${client.project.token}/tasks`} className="btn btn-small color-1 background-secondary background-hover border-all">
            <i className="fal fa-external-link-alt mr-8"></i>{client.project.title}
          </Link>
        </td> */}

        <td>
          <div className="max-width-200px">
            <Select
              options={roleOptions}
              onChange={handleRoleChange}
              value={client.role ? {
                value: client.role.token,
                label: client.role.title
              } : {
                value: null,
                label: 'No role'
              }}
              isClearable={false}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Select..."
            />
          </div>
        </td>
        
        <td className="text-right">
          <div class="btn-group">
            <a type="button" className="color-1 btn btn-small background-hover opacity-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fas fa-ellipsis-v"></i>
            </a>

            <ul class="dropdown-menu pull-right animated fadeInUp">
              <li><a onClick={removeClient}><i className="fal fa-trash"></i>Remove</a></li>
            </ul>
          </div>
        </td>
      </tr>

      {showCompany && 
        <div className="client-users-item-company box-shadow animated slideInRight">
          <div className="client-users-item-company-close">
            <a className="opacity-4 opacity-10-hover btn btn-small color-1 background-hover-secondary background-active" onClick={() => setShowCompany(false)}>
              <i className="fal fa-times mr-5"></i> Close
            </a>
          </div>

          <CompanyIndex project_id={client.project.token} refetchData={refetchData}/>
        </div>
      }

      <Modal open={roleModalOpen} onClose={() => setRoleModalOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <h4 className="color-black-always modal-title">Add New Role</h4>
        <div className="row no-margin">
          <Form refetchData={refetchData} closeModal={() => setRoleModalOpen(false)} is_client={true} />
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ClientUser;