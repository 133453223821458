import React , { useContext } from 'react';
import StageIcon from "../../../../Shared/StageIcon.js";
import { OrganizationContext } from '../../../../Shared/OrganizationContext';

const AssignColumnsForm = ({setColumn, column, columns}) => {
  const {hasPermission} = useContext(OrganizationContext);
  return(
    <React.Fragment>
      <div class="btn-group dropup">
        <button type="button" class="btn btn-small btn-default background-hover-2 dropdown-toggle mr-8" data-toggle="dropdown">
          <div className="display-flex align-items-center">
            {column ? ( 
              <>
                <StageIcon stage={column.stage}/>{column.title}
              </>
            ) : (
              <div>
                Select Column
              </div>
            )}
          </div>
        </button>

        <ul class="dropdown-menu dropup animated fadeInUp" role="menu">
          {columns?.filter(columnItem => hasPermission(columnItem.stage)).map(columnItem => (
            <li className={columnItem.full || ((columnItem.tasks?.length >= columnItem.limit) && columnItem.limit !== null) ? "disabled" : ""} key={columnItem.token} onClick={() => setColumn(columnItem)}>
              <a>
                <div className="display-flex align-items-center">
                  <StageIcon stage={columnItem.stage}/>{columnItem.title}
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  )
}

export default AssignColumnsForm;