import { Gantt, Toolbar } from "wx-react-gantt";
import "wx-react-gantt/dist/gantt.css";
import React, { useRef, useEffect, useState } from "react";
import "./gantt.css";
import FullCardV2 from "../Daas/Portal/Projects/FullCardV2.js";
import { Modal } from 'react-responsive-modal';

const MyGanttComponent = ({columns, projects, refetchData}) => {
  const defaultStartDate = new Date(); // Default to today's date
  const defaultEndDate = new Date(defaultStartDate);
  defaultEndDate.setDate(defaultEndDate.getDate() + 1); // Default duration of 1 day

  const [open, setOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const cellBorders = "columns";

  useEffect(() => {
    notice("Updated projects array");
  }, [projects, columns]);

  const tasks = [
    
    // {
    //   id: 47,
    //   text: "Master project",
    //   type: "summary",
    // },
    // {
    //   id: 22,
    //   text: "Task",
    //   start: "2025-02-11", // August 11, 2025
    //   end: "2025-02-19",   // September 12, 2025
    //   // duration: 8,
    //   progress: 0,
    //   parent: 47,
    //   type: "task",
    //   token: "1234567890",
    // },
    // {
    //   id: 1,
    //   text: "Master project 2",
    //   type: "summary",
    // },
    // {
    //   id: 22,
    //   text: "Task",
    //   start: "2025-02-11",
    //   end: "2025-02-12",
    //   // duration: 8,
    //   progress: 0,
    //   parent: 1,
    //   type: "task",
    // },
  ];

  // Add projects from props to tasks
  const projectTasks = projects.map(project => ({
    id: project.id,
    text: project.title,
    type: "summary",
    start: defaultStartDate,
    end: defaultEndDate,
    token: project.token,
  }));

  // Extract tasks from columns
  const columnTasks = columns.flatMap(column => 
    column.tasks.map(task => ({
      id: task.id,
      text: task.title,
      start: task.start_date || defaultStartDate,
      end: task.deadline || defaultEndDate,
      progress: 0,
      parent: task.project.id,
      type: "task",
      token: task.token,
      project: task.project,
    }))
  );

  const allTasks = [...projectTasks, ...columnTasks];

  const apiRef = useRef();

  useEffect(() => {
    if (apiRef.current) {
      apiRef.current.intercept("show-editor", (data) => {
        console.log(data);
        
        // Find the task with the matching id
        const task = allTasks.find(task => task.id === data.id);
        if (task) {
          console.log("Found task:", task);
          if(task.type == "task"){
            notice("show-editor");
            setSelectedTask(task);
            // setOpen(true);
          }
        } else {
          console.log("Task not found");
        }
        return false;
      });

      apiRef.current.on("cell-click", () => {
        notice("Hello world");
      });

      // if (apiRef.current) {
      //   apiRef.current.exec("add-task", { task: {} });
      // }
    }
  }, [apiRef.current]);

  const customColumns = [
    { id: "text", header: "Task name", flexgrow: 2 },
  ];

  return (
    <>
      <Gantt
        api={apiRef}
        tasks={allTasks}
        zoom={true}
        columns={customColumns}
        cellBorders={cellBorders}
      />

      <Modal 
        focusTrapped={false} 
        closeOnEsc={false} 
        open={open} 
        onClose={() => {
          setOpen(false); 
          setSelectedTask(null); // Reset selected task when modal is closed
        }} 
        className="modal-body-white" 
        classNames={{modal: 'modal-fullcard'}} 
        center
      >
        {selectedTask && 
          <FullCardV2 
            task_id={selectedTask.token} 
            refetchData={refetchData} 
            organizationUser={true} 
            project_id={selectedTask.project?.token}
          />
        }
      </Modal>
    </>
  );
};

export default MyGanttComponent;