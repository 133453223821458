import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import axios from 'axios'; // Import Axios
import Spline from '@splinetool/react-spline';

class ErrorBoundary extends React.Component {
  constructor(props) {
    console.log(props);
    super(props);
    this.state = {
      hasError: false,
      value: '',
      copied: false,
      consoleLogs: [], // Added state for capturing console logs
      errorLocation: '', // Add new state for error location
    };
    this.captureConsoleLog(); // Start capturing console logs
  }

  // Function to capture console logs
  captureConsoleLog() {
    const originalConsoleLog = console.log;
    const that = this;

    console.log = function (...args) {
      originalConsoleLog.apply(console, args); // Log to the console as usual
      const logMessage = args.map((arg) =>
        typeof arg === 'object' ? JSON.stringify(arg) : arg
      ).join(' '); // Convert log arguments to string
      that.setState((prevState) => ({
        consoleLogs: [...prevState.consoleLogs, logMessage], // Store the log message
      }));
    };
  }

  handleChunkLoadError(error) {
    const errorString = error.toString();
    const hasAttemptedReload = localStorage.getItem('chunkErrorReloaded');
    const reloadTimestamp = localStorage.getItem('chunkErrorReloadTime');
    const ONE_HOUR = 60 * 60 * 1000; // 1 hour in milliseconds
    
    // Clear reload flag if it's been more than an hour
    if (reloadTimestamp && Date.now() - parseInt(reloadTimestamp) > ONE_HOUR) {
      localStorage.removeItem('chunkErrorReloaded');
      localStorage.removeItem('chunkErrorReloadTime');
    }
    
    if (errorString.includes('ChunkLoadError') && !hasAttemptedReload) {
      localStorage.setItem('chunkErrorReloaded', 'true');
      localStorage.setItem('chunkErrorReloadTime', Date.now().toString());
      window.location.reload(true);
      return true;
    }
    
    // Clear the reload flag if it's not a ChunkLoadError
    if (!errorString.includes('ChunkLoadError')) {
      localStorage.removeItem('chunkErrorReloaded');
      localStorage.removeItem('chunkErrorReloadTime');
    }
    
    return false;
  }

  componentDidCatch(error, info) {
    console.log(error);
    const errorString = error.toString();
    const isChunkError = errorString.includes('ChunkLoadError');
    console.log("error", error);
    console.log("info", info);
    
    // Extract file location from componentStack
    let errorLocation = '';
    if (info && info.componentStack) {
      const stackLines = info.componentStack.split('\n');
      // Find the first line that contains the actual error location
      const errorLine = stackLines.find(line => line.includes('at'));
      if (errorLine) {
        errorLocation = errorLine.trim();
      }
    }

    // Record all errors first
    axios
      .post('/record_react_error', {
        errorInfo: `${error}`,
        errorType: isChunkError ? 'ChunkLoadError' : 'ReactError',
        info: info,
        errorLocation: errorLocation // Add error location to the recorded data
      })
      .then((response) => {
        console.log('Error data sent successfully:', response.data);
      })
      .catch((error) => {
        console.error('Error sending error data:', error);
      });

    // Only handle chunk errors if it's actually a ChunkLoadError
    if (isChunkError && this.handleChunkLoadError(error)) {
      return;
    }

    // Set error state for non-chunk errors or if chunk error handling didn't reload
    this.setState(
      {
        hasError: true,
        value: `${error}`,
        errorLocation: errorLocation, // Store error location in state
      },
      () => {
        localStorage.removeItem('location');
      }
    );
  }
  
  // Add this new function to clear IndexedDB
  clearIndexedDB = async () => {
    try {
      const databases = await window.indexedDB.databases();
      databases.forEach(db => {
        window.indexedDB.deleteDatabase(db.name);
      });
    } catch (error) {
      console.error('Error clearing IndexedDB:', error);
    }
  };

  render() {
    if (this.state.hasError) {
      return (
        <React.Fragment>
          <div className="container-fluid align-items-center display-flex text-center flex-direction-column justify-content-center" style={{height: "75vh", width: "100vw"}}>
            <div className="animated fadeIn" style={{height: 300}}>
              <Spline scene="https://prod.spline.design/3pFOavrtYzKVlNIm/scene.splinecode" />
            </div>

            <div className="mt-25">
              <h1 className="animated fadeIn slow">🐞 System Error</h1>
              <p className="mt-15 opacity-7" style={{maxWidth: 500}}>
                Our engineers has been notified and will fix the issue within 24 hours. Feel free to chat with us on Intercom for immediate support.
              </p>

              <div className="display-flex align-items-center mt-25 justify-content-center animated fadeIn slower">
                <a className="btn background-3 border-all color-1" href="/"><i className="fal fa-home mr-8"></i> Home</a>
                <button 
                  className="btn btn-primary ml-15" 
                  onClick={async () => {
                    await this.clearIndexedDB();
                    window.location.reload(true);
                  }}
                >
                  <i className="fal fa-sync-alt mr-8"></i> Reload
                </button>

                {/* <button className="btn background-orange color-1 ml-15" onClick={() => Intercom('showNewMessage', `I got a bug on this page ${window.location.href}.`)}><i className="fal fa-headset mr-8"></i> Contact support</button> */}
              </div>
              <div className="animated fadeIn slowerr">
                <p className="mt-15 opacity-7">A reload usually fixes the problem </p>
              </div>
            </div>

            {window.location.href.includes('queue.ngrok.io') && (
              <>
                <textarea 
                  style={{maxWidth: 500}} 
                  rows={10} 
                  className="form-control mt-25" 
                  value={`Error: ${this.state.value}\n\nLocation: ${this.state.errorLocation}`}
                ></textarea>
              </>
            )}
          </div>
        </React.Fragment>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;
