import React , { useState, useEffect, Fragment, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import Versions from "../../../Replays/Versions/Versions.js";
import Edit from "../../../Replays/Edit.js";
import {Menu, Item, useContextMenu, Submenu} from "react-contexify";
import Filestack from "../../../Replays/Forms/Filestack.js";
import WebsiteForm from "../../../Replays/Website/Form.js";
import StageIcon from "../../../Shared/StageIcon.js";
import { Portal } from 'react-portal';
import ApproveModal from "../../../Replays/FeedbackLinks/ApproveModal.js";
import ApprovalsIndex from "../../../Replays/Approvals/ApprovalsIndex.js";
import {OrganizationContext} from "../../../Shared/OrganizationContext.js";
import Share from "../../../Replays/Share.js";

const TaskReplay = ({replay, refetchData, task_id, project_id, simple}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [disabled, setDisabled] = useState(false);
  const {organizationAuthorizations} = useContext(OrganizationContext);
  const [open, setOpen] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [currentVersionIndex, setCurrentVersionIndex] = useState(null);
  const [openWebsiteForm, setOpenWebsiteForm] = useState(false);
  const [uploadFile, setUploadFile] = useState(false);
  const [openApproval, setOpenApproval] = useState(false);
  const [openApprovals, setOpenApprovals] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [approve, setApprove] = useState(null);
  const portal = window.location.pathname.includes("/portal/");

  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ id: `replay-${replay.token}-versions-dropdown` }).show({ event: e });
  };

  const handleApprovalsContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ id: `replay-${replay.token}-approvals-dropdown` }).show({ event: e });
  };

  
  useEffect(() => {
    if (replay?.versions) {
      const currentVersion = replay.versions.find(v => v.token === replay.token);
      setCurrentVersionIndex(currentVersion?.index || null);
    }
  }, [replay]);

  const deleteReplay = (evt) => {
    const confirmThis = confirm("Are you sure?")
    if(confirmThis){
      axios.delete(`/replays/${replay.token}`)
      .then(function(response){
        // console.log(response);
        if(response.data.success){
          notice("Removed file")
          refetchData();
          // setReplays((replays) => replays.filter((replay) => replay.token !== replayToken));
        }
      })
      .catch(function(error){
        console.log(error)
        notice("An error occured")
      })
      .then(function () {
        setDisabled(false);
      });
    }
  }

  const trashReplay = () => {
    axios.post(`/api/replays/${replay.token}/remove_replay_from_task`, {
      task_id: task_id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        // setReplays((replays) => replays.filter((replay) => replay.token !== replayToken));
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const versions_list = replay?.versions?.map((version, index) => {
    return (
      <Item 
        key={version.index}
        onClick={() => history.push(`/replays/${version.token}`)}
        className={`${currentVersionIndex == version.index ? "background-active border-radius mb-5 mt-5" : ""}`}
      >
        <div className={`display-flex align-items-center replay-versions-wrapper`}>
          <div>
            <div className="replay-versions-index">
              v{version.index}
            </div>
          </div>

          {/* <div>
            <img 
              src={version.preview_image} 
              className="replay-versions-preview-image"
              alt={`Preview for version ${version.index}`}
            />
          </div> */}

          <div className="display-flex flex-direction-column truncate-parent">
            <div className="replay-versions-title truncate-child width-200">
              {version.title}
            </div>

            <div className="replay-versions-information">
              <div className="">
                {version.user.username}
              </div>

              <div>
                •
              </div>

              <div>
                {version.created_ago} ago 
              </div>
            </div>
          </div>
        </div>
      </Item>
    )
  })

  return(
    <React.Fragment>
      <div className={`daas-task-replays-wrapper hover-parent-opacity ${simple ? "padding-8" : ""}`}>
        <div className="col-8 card-replays-info">
          {/* {!simple && 
            <a className="replay-versions-button mr-10" onClick={handleContextMenu} style={{marginLeft: 0, background: "var(--background-hover)"}}>
              <span className="font-12">v</span>
              <span className="" style={{marginLeft: 2}} id={`replay-${replay.token}-ancestors-size`}>
                {currentVersionIndex || ''}
              </span>
              <i className="far fa-angle-down ml-8 opacity-6"></i>
            </a>
          } */}

          {!simple && 
            <>
              {replay.approval ? (
                <a className="btn btn-small mr-5" onClick={() => setOpenApprovals(true)}>
                  <div className="display-flex align-items-center">
                    <StageIcon classNames="display-flex align-items-center" stage={replay.approval.approved ? "done" : "canceled"}/>
                  </div>
                </a>
              ):(
                <a onClick={handleApprovalsContextMenu} className="btn btn-small mr-5">
                  <div className="display-flex align-items-center">
                    <StageIcon classNames="display-flex align-items-center" stage={"in_review"}/>
                  </div>
                </a>
              )}

              <Portal>
                <Menu id={`replay-${replay.token}-approvals-dropdown`}>
                  <Item onClick={() => {setApprove(true); setOpenApproval(true)}}>
                    <StageIcon stage={"done"}/>Approved
                  </Item>

                  <Item onClick={() => {setApprove(false); setOpenApproval(true)}}>
                    <StageIcon stage={"canceled"}/>Request changes
                  </Item>
                </Menu>
              </Portal>

              <Modal open={openApproval} center onClose={()=> setOpenApproval(false)} className="modal-body-white" classNames={{modal: 'width-500'}}>
                <ApproveModal replay={replay} refetchData={() => {refetchData();}} closeModa={() => setOpenApproval(false)} approve={approve}/>
              </Modal>
            </>
          }

          <Menu id={`replay-${replay.token}-versions-dropdown`}>
            {versions_list}
            <Submenu closeOnClick={false} label={<Fragment><i class="fal fa-plus"></i><span>Add version</span></Fragment>}>
              <Item onClick={() => setUploadFile(true)}>
                <i className="fal fa-file"></i>File
              </Item>
              <Item onClick={() => setOpenWebsiteForm(true)}>
                <i className="fal fa-globe  "></i>Website
              </Item>
            </Submenu>

            <Item onClick={() => setOpen(true)}>
              <i className="fal fa-code-merge"></i>Manage versions
            </Item>
          </Menu>

           {/* File  */}
           <Modal open={uploadFile} onClose={() => setUploadFile(false)}  className="modal-body-white" classNames={{modal: 'filestack-modal'}} center>
            <Filestack project_id={project_id} folder_id={null} task_id={task_id} parent_replay_id={replay.token} refetchData={() => refetchData()} redirect={true} closeParentModal={() => setUploadFile(false)}/>
          </Modal>
          
          {/* Website */}
          <Modal open={openWebsiteForm} onClose={() => setOpenWebsiteForm(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
            <WebsiteForm project_id={project_id} folder_id={null} task_id={task_id} version={null} closeModal={() => setOpenWebsiteForm(false)} refetchData={refetchData} parent_replay_id={replay.token} redirect={true}/>
          </Modal>
          
          {/* {!simple &&
            <img src={replay.preview_image || "https://img.icons8.com/?size=100&id=11321&format=png"} className="card-replays-info-image border-radius mr-10" />
          } */}

          <div className="display-flex flex-direction-column width-100-percent truncate-parent">
            <a className="truncate-child color-1 opacity-6 opacity-10-hover animate" href={`/replays/${replay.token}`} target={"_blank"}>
              {replay.title}
            </a>
            
            {!simple &&
              <div className="font-10 opacity-4">
                <i class="far fa-clock mr-5"></i>{replay.created_ago} ago by {replay.user.username} • <i class="fal fa-comment-alt-lines mr-5"></i>{replay.video_markers?.filter(vm => vm.completed).length}/{replay.video_markers?.length} comments
              </div>
            }
          </div>
        </div>

          {replay.descendants_size > 1 && 
            <div className="col-2 font-12 daas-task-replays-cta">
                <span onClick={() => setOpen(true)} className="badge background-active background-hover-2 ml-8 cursor-pointer">v{replay.descendants_size}</span>
            </div>
          }

        {/* <div className="col-2 font-12 daas-task-replays-cta">
          {replay.video_markers?.length > 0 && 
            <div className={replay.video_markers?.filter(vm => vm.completed).length === replay.video_markers?.length ? "color-green" : "color-1"}>
              <i class="fal fa-comment-alt-lines mr-5"></i>{replay.video_markers?.filter(vm => vm.completed).length} / {replay.video_markers?.length}
            </div>
          }
        </div> */}
        
        <div className="col-1 text-right pr-15">
          <div class="btn-group opacity-6 opacity-10-hover">
            <a type="button" class="btn btn-small background-hover hover-child-opacity dropdown-toggle color-1" data-toggle="dropdown">
              <i class="fas fa-ellipsis-v"></i>
            </a>
            <ul class="dropdown-menu pull-right" role="menu">
              {!portal && 
                <li><a onClick={() => setOpenShare(true)}><i class="fal fa-share mr-6"></i>Share</a></li>
              }
              <li><a href={replay.download_link}><i class="fal fa-download mr-6"></i>Download</a></li>
              <li><a onClick={() => setOpenEdit(true)}><i class="fal fa-pencil mr-6"></i>Edit</a></li>
              <li><a onClick={trashReplay}><i class="fal fa-minus-circle mr-5"></i>Remove</a></li>
              <li><a onClick={deleteReplay}><i class="fal fa-trash mr-5"></i>Delete file</a></li>
            </ul>
          </div>
        </div>
      </div>

      {organizationAuthorizations.organization_user &&
        <React.Fragment>
          <Modal open={openShare} onClose={() => setOpenShare(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
            <Share replay_id={replay.token} refetchData={refetchData} />
          </Modal>
        </React.Fragment>
      }

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Versions replay_id={replay.token} refetchData={() => refetchData()} closeModal={() => setOpen(false)}/>
      </Modal>

      <Modal open={openEdit} onClose={() => setOpenEdit(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <Edit replay={replay} refetchData={() => refetchData()} onCloseModal={() => setOpenEdit(false)}/>
      </Modal>

      <Modal open={openApprovals} center onClose={()=> setOpenApprovals(false)} className="modal-body-white" classNames={{modal: 'width-500'}}>
        <ApprovalsIndex replay={replay} closeModa={() => setOpenApprovals(false)}/>
      </Modal>
    </React.Fragment>
  )
}

export default TaskReplay;