import React, {useEffect, useState} from "react";
import { setup, isSupported } from "@loomhq/record-sdk";
import ReactTooltip from 'react-tooltip';
import { useTranslation } from 'react-i18next';

const PUBLIC_APP_ID = "83052daa-50bf-4dc8-9ab3-d990d2d2738c";
const BUTTON_ID = "loom-record-sdk-button";

const LoomRecorder = ({setVideoUrl, videoUrl, classNames}) => {
  const { t } = useTranslation();

  useEffect(() => {
    async function setupLoom() {
      const { supported, error } = await isSupported();

      if (!supported) {
        console.warn(`Error setting up Loom: ${error}`);
        return;
      }

      const button = document.getElementById(BUTTON_ID);

      if (!button) {
        return;
      }

      const { configureButton } = await setup({
        publicAppId: PUBLIC_APP_ID,
      });

      const sdkButton = configureButton({ element: button });

      sdkButton.on("insert-click", async (video) => {
        setVideoUrl(video.sharedUrl);
      });
    }

    setupLoom();
  }, [videoUrl]);

  const removeVideo = () => {
    setVideoUrl(null);
  }

  return (
    <React.Fragment>
      <ReactTooltip effect="solid" backgroundColor="black" textColor="white" delayShow={200}/>
      {
        videoUrl == null ? (
          <a id={BUTTON_ID} data-tip="Record your screen with voice. Premium feature." className={`btn btn-small border-all color-1 background-hover mr-10 cursor-pointer ${classNames}`}><i class="color-black-always fal fa-video-plus mr-5"></i>{t("Screen Record")}</a>
        ):(
          <React.Fragment>
            <button className="btn btn-small border-all color-1 background-hover mr-10" onClick={removeVideo}>
              <i class="far fa-times mr-5"></i>{t("Remove")}
            </button>
          </React.Fragment>
        )
      }
    </React.Fragment>
  );
}
export default LoomRecorder