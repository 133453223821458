import React , { useState, useEffect, useContext } from 'react';
import { Route, Switch, withRouter, Link, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { useTranslation } from 'react-i18next';
import _ from 'lodash'; // setClients(prevState => _.reject(prevState, { token: client.token }));
import Select from 'react-select';
// import {OrganizationContext} from "../../Shared/OrganizationContext.js";
// import {UserContext} from "../../Shared/UserContext.js";
import Form from "../Organizations/Roles/Form.js";
import Toggle from 'react-toggle';

const Invite = ({project_id, refetchData, closeModal}) => {
  // const {organizationAuthorizations} = useContext(OrganizationContext);
  // const currentUser = useContext(UserContext);
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [accessLevel, setAccessLevel] = useState(null);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [email, setEmail] = useState(null);
  const [roleModalOpen, setRoleModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [addToProject, setAddToProject] = useState(false);

  const roleOptions = [
    ...roles.filter(role => role.is_client != true).map(role => ({
      value: role.token,
      label: role.title
    })),
    {
      value: 'add_role',
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <i className="fal fa-plus mr-8"></i>
          Add Role
        </div>
      )
    }
  ];

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = () => {
    axios.get(`/api/o/${match.params.organization_id}/roles.json`)
    .then(function(response){
    //   console.log(response);
      if(response.data.success){
        setRoles(response.data.roles);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
      setLoaded(true);
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/invites`, {
      // name: name,
      // access_level: accessLevel?.value,
      email: email,
      role_id: selectedRole?.value,
      project_id: match.params.project_id
    })
    .then(function(response){
      // console.log(response);
      if(response.data.success){
        refetchData();
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  };

  const handleRoleChange = (selectedRole) => {
    if (selectedRole.value === 'add_role') {
      setRoleModalOpen(true);
      return;
    }

    setSelectedRole(selectedRole);
  };


  return(
    loaded && 
    <React.Fragment>
      <h4 className="color-black-always modal-title">Add teammate</h4>

      <div className="row no-margin">
        <form onSubmit={handleSubmit}>
          {/* <div className="field">
            <label>Name</label>
            <input type="text" className="form-control" onChange={(evt) => setName(evt.target.value)}/>
          </div> */}

          <div className="field">
            <label>Email</label>
            <input type="email" className="form-control" onChange={(evt) => setEmail(evt.target.value)} required={true}/>
          </div>

          {/* <div className="field">
            <label>Access level</label>
            <Select
              value={accessLevelOptions.find(option => option.value === accessLevel?.value)}
              onChange={setAccessLevel}
              options={accessLevelOptions}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Select..."
            />
          </div> */}

          <div className="field">
            <label>Role</label>
            <Select
              // value={roles.find(option => option.value === selectedRole?.value)}
              onChange={handleRoleChange}
              options={roleOptions}
              className="color-1"
              classNamePrefix="react-select"
              placeholder="Leave blank for Admin"
            />
          </div>

          <div className="field text-right">
            <button disabled={disabled || !email} type="submit" className="btn btn-primary"><i className="fal fa-plus mr-8"></i>Add member</button>
          </div>
        </form>
      </div>

      <Modal open={roleModalOpen} onClose={() => setRoleModalOpen(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <h4 className="color-black-always modal-title">Add New Role</h4>
        <div className="row no-margin">
          <Form refetchData={refetchData} closeModal={() => setRoleModalOpen(false)}/>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default Invite;