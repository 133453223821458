import React , { useState } from 'react';
import axios from "axios"; 
import { Modal } from 'react-responsive-modal';
import { PickerOverlay, PickerInline} from 'filestack-react';
const UploadAttachements = ({task, refetchData, project_id}) => {;
  const [openFilepicker, setOpenFilepicker] = useState(false);

  const uploadAttachements = (filestack) => {
    const filesUploaded = filestack.filesUploaded;
    axios.post(`/api/portal/projects/${project_id}/tasks/${task.token}/task_attachements`, {
      attachements: filesUploaded
    })
    .then(function(response){
      console.log("createAttachements");
      console.log(response);
      if(response.data.success){
        refetchData();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
    });
  }

  return(
    <React.Fragment>
      <span className="btn btn-small btn-primary mt-15" onClick={() => setOpenFilepicker(true)}>
        <i className="fal fa-upload mr-5"></i>Upload
      </span>

      <Modal open={openFilepicker} onClose={() => setOpenFilepicker(false)} className="modal-body-white" classNames={{modal: 'width-800'}} center>
        <PickerInline
          apikey={"AKVhxMbEQhkOIryqBvSEQz"}
          onSuccess={(res) => console.log(res)}
          onUploadDone={(res) => {
            uploadAttachements(res);
          }}
          clientOptinos={{
            sessionCache:true
          }}
          pickerOptions={{
            onClose: () => {
              setOpenFilepicker(false);
            }, 
            fromSources: ["local_file_system", "googledrive", "dropbox", "box", "onedrive", "onedriveforbusiness", "googlephotos", "facebook", "instagram"],
            maxFiles: 15, 
            storeTo: {
              location: 'gcs',
              path: '/',
              container: 'queue-filestack'
            }
          }}
        />
      </Modal>
    </React.Fragment>
  )
}

export default UploadAttachements;