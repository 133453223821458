import React, { useState } from "react";
import "gantt-task-react/dist/index.css";
import { ViewMode } from "gantt-task-react";
import {Menu, Item, useContextMenu} from "react-contexify";
import { Portal } from 'react-portal';

type ViewSwitcherProps = {
  isChecked: boolean;
  onViewListChange: (isChecked: boolean) => void;
  onViewModeChange: (viewMode: ViewMode) => void;
};
export const ViewSwitcher: React.FC<ViewSwitcherProps> = ({
  onViewModeChange,
  onViewListChange,
  isChecked
}) => {
  const [viewMode, setViewMode] = useState<ViewMode>(ViewMode.Day);

  const handleContextMenu = e => {
    e.preventDefault();
    e.stopPropagation();
    useContextMenu({ id: `gantt-dropdown` }).show({ event: e });
  };

  return (
    <div className="ViewContainer mt-15 display-flex align-items-center justify-content-end mb-15" style={{position: 'absolute', top: 15, right: 40}}>
      <a className="color-1 btn btn-default btn-small" onClick={handleContextMenu}><i className={`fal fa-calendar mr-5`}></i>{viewMode}</a>
      <Portal>
        <Menu id={`gantt-dropdown`}>
          {/* <Item onClick={() => onViewModeChange(ViewMode.QuarterDay)}>
            <i className="fal fa-calendar-quarter"></i> Quarter of day
          </Item>
          <Item onClick={() => onViewModeChange(ViewMode.HalfDay)}>
            <i className="fal fa-calendar-half-alt"></i> Half of day
          </Item> */}
          <Item onClick={() => onViewModeChange(ViewMode.Day)}>
            <i className="fal fa-calendar-day"></i> Day
          </Item>
          <Item onClick={() => onViewModeChange(ViewMode.Week)}>
            <i className="fal fa-calendar-week"></i> Week
          </Item>
          <Item onClick={() => onViewModeChange(ViewMode.Month)}>
            <i className="fal fa-calendar-alt"></i> Month
          </Item>
        </Menu>
      </Portal>


      {/* <div className="Switch">
        <label className="Switch_Toggle">
          <input
            type="checkbox"
            defaultChecked={isChecked}
            onClick={() => onViewListChange(!isChecked)}
          />
          <span className="Slider" />
        </label>
        Show Task List
      </div> */}
    </div>
  );
};
