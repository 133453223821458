import React, { useState, useEffect, useContext, useRef } from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useHistory, useRouteMatch, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import Pusher from 'pusher-js';
import Card from "../Daas/Portal/Projects/Card.js";
import KanBanSkeleton from "../Daas/Portal/Projects/KanBanSkeleton.js";
import localForage from "localforage";
import {UserContext} from "../Shared/UserContext.js";
import List from "../Daas/Portal/Projects/Task/List.js";
import {TaskboardContext} from "../Shared/TaskboardContext.js";
import MyCalendar from "../Calendar/MyCalendar.js";
import { useDraggable } from "react-use-draggable-scroll";
import NewLabel from "../Labels/NewLabel.js";
import { Modal } from 'react-responsive-modal';
import Loading from "../Shared/Loading.js";
import StageIcon from "../Shared/StageIcon.js";
import {ProjectContext} from "../Projects/ProjectContext.js";
import MyGanttComponent from "../Gantt/MyGanttComponent.js";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Tickets from "../Daas/Portal/Projects/Tickets/Tickets.js";
import GanttChartApp from "../Gantt/ReactGanttChart/App.tsx";
import FixColumns from "../Daas/Portal/Projects/Columns/FixColumns.js";
import Filters from './Filters';
import {OrganizationContext} from "../Shared/OrganizationContext.js";

const KanbanBoard = ({projectAuthorizations, fetchPath}) => {
  const {organizationAuthorizations} = useContext(OrganizationContext);
  const {refreshData} = useContext(ProjectContext);
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const currentUser = useContext(UserContext);
  const [columns, setColumns] = useState([]);
  const [projects, setProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [openTask, setOpenTask] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [labels, setLabels] = useState([]);
  const [organizationUser, setOrganizationUser] = useState(projectAuthorizations.organization_user);
  const [openNewLabel, setOpenNewLabel] = useState(false);
  const [fixColumns, setFixColumns] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [collapsedColumns, setCollapsedColumns] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [view, setView] = useState(localStorage.getItem('view') || "kanban");
  const [hasColumnsWithoutStage, setHasColumnsWithoutStage] = useState(false);

  const [filters, setFilters] = useState({
    hiddenColumns: [],
    users: [],
    sortBy: null,
    sortOrder: 'asc', // 'asc' or 'desc'
    hideEmptyColumns: false,
    projects: [],
    taskPriorities: [], 
    deadline: null, 
    labels: []
  });

  useEffect(() => {
    localStorage.setItem('view', view);
  }, [view]);
  
  
  // const ref = useRef(); // We will use React useRef hook to reference the wrapping div:
  // const { events } = useDraggable(ref); // Now we pass the reference to the useDraggable hook:

  // // Custom event handlers that will only trigger dragging on certain elements
  // const handleMouseDown = (e) => {
  //   if (e.target.classList.contains("draggable")) {
  //     events.onMouseDown(e);
  //   }
  // };

  // const handleTouchStart = (e) => {
  //   if (e.target.classList.contains("draggable")) {
  //     events.onTouchStart(e);
  //   }
  // };


  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabParam = searchParams.get('tab');
    if (tabParam !== null) {
      setActiveTab(parseInt(tabParam, 10));
    }
  }, [location.search]);

  const handleTabChange = (index) => {
    setActiveTab(index);
    history.push(`${location.pathname}?tab=${index}`);
  };

  useEffect(() => {
    // fetchColumns();
    loadFilters();

    const controller = new AbortController();
    const signal = controller.signal;
    const fetchData = async () => {
      try {
        const value = await localForage.getItem(`${window.location.pathname}-2`);
        if (value) {
          setColumns(value.columns);
          if(value.projects != null){
            setProjects(value.projects);
          }
          if(value.users != null){
            setUsers(value.users);
          }
          setLoaded(true);
        }

        await fetchColumns(signal); // Fetch new data
      } catch (error) {
        console.error('Error fetching and updating data:', error);
      }
    };

    fetchData();

    return () => {
      // Cleanup function: cancel the ongoing fetch request
      controller.abort();
    };
  }, [location, refreshData]); 
  
  // Update LocalForage when columns state changes
  useEffect(() => {
    localForage.getItem(`${window.location.pathname}-2`)
      .then(function (value) {
        if (value) {
          value.columns = columns;
          localForage.setItem(`${window.location.pathname}-2`, value)
            .then(function (updatedValue) {
              // console.log('Columns updated in LocalForage:', updatedValue.columns);
            })
            .catch(function(err) {
              console.error('Error updating columns in LocalForage:', err);
            });
        }
      })
      .catch(function(err) {
        console.error('Error loading data from LocalForage:', err);
      });
  }, [columns]);

  useEffect(() => {
    saveFiltersToLocalForage();
  }, [filters]);

  useEffect(() => {
    const pusher = new Pusher('683f89863cff332fae20', {cluster: 'us2'});

    const channel = pusher.subscribe(`dashboard-${match.params.organization_id}`);

    channel.bind('subscription', data => {
      // console.log(data)
      // Handle the incoming event data here
      if(data.user_id != currentUser.id){
        // console.log('Received data:', data);
        fetchColumns();
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    };
  }, [match.params.organization_id]);

  const fetchColumns = async (signal) => {
    setFetchingData(true);
    try {
      const response = await axios.get(`/api/o/${match.params.organization_id}/${fetchPath}.json`, { signal });
      if (response.data.success) {
        setColumns(response.data.columns);
          setProjects(response.data.projects);
          setUsers(response.data.users);
          setLabels(response.data.labels);
          setLoaded(true);
          setFetchingData(false);
          localForage.setItem(`${window.location.pathname}-2`, response.data).then(function (value) {
            // console.log(value);
          }).catch(function(err) {
            console.log(err);
          });

        await localForage.setItem(`${window.location.pathname}-2`, response.data);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log('Request canceled', error.message);
      } else {
        console.log(error);
      }
    }
  };

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // Return if dropped outside the droppable area or in the same position
    if (!destination || (destination.droppableId === source.droppableId && destination.index === source.index)) {
      return;
    }

    const sourceColumnId = source.droppableId;
    const destinationColumnId = destination.droppableId;

    // Find the source and destination columns in the columns array
    const sourceColumnIndex = columns.findIndex(column => column.title === sourceColumnId);
    const destinationColumnIndex = columns.findIndex(column => column.title === destinationColumnId);

    const sourceColumn = columns[sourceColumnIndex];
    const destinationColumn = columns[destinationColumnIndex];

    // Move card within the same column
    if (sourceColumn === destinationColumn) {
      // // Clone the cards array to make changes
      // const newCards = [...sourceColumn.tasks];
    
      // // Remove the card from the source index
      // const [movedCard] = newCards.splice(source.index, 1);
    
      // // Insert the card at the destination index
      // newCards.splice(destination.index, 0, movedCard);
    
      // // Update the positions of cards in the same column
      // const updatedCards = newCards.map((card, index) => ({
      //   ...card,
      //   position: index + 1,
      // }));
    
      // // Update the source column with the new card order
      // const updatedSourceColumn = {
      //   ...sourceColumn,
      //   tasks: updatedCards,
      // };
    
      // // Update the columns state
      // const updatedColumns = [...columns];
      // updatedColumns[sourceColumnIndex] = updatedSourceColumn;
    
      // setColumns(updatedColumns);
    
      // // Prepare the batched card updates
      // const batchedCardUpdates = updatedCards.map((card) => ({
      //   token: card.token,
      //   column_id: destinationColumn.token,
      //   position: card.position,
      // }));

      // axios.post(`/api/portal/projects/${project_id || match.params.project_id}/tasks/update_positions`, {
      //   updates: batchedCardUpdates,
      //   kind: "within"
      // })
      // .then(function (response) {
      //   // console.log(response);
      //   // Handle the response as needed
      // })
      // .catch(function (error) {
      //   console.log(error);
      //   // Handle the error as needed
      // });
    } else {
      // Create copies of the source and destination columns
      const updatedSourceColumn = { ...sourceColumn };
      const updatedDestinationColumn = { ...destinationColumn };

      // Find the index of the card being dragged in the source column
      const movedCardIndex = updatedSourceColumn.tasks.findIndex(card => card.id === draggableId);

      // Return if the card is not found in the source column
      if (movedCardIndex === -1) {
        // Handle error condition if card not found
        return;
      }

      // Remove the card from the source column and insert it into the destination column
      const [removed] = updatedSourceColumn.tasks.splice(movedCardIndex, 1);
      updatedDestinationColumn.tasks.splice(destination.index, 0, removed);

      // Update the card positions within the updated columns
      updatedSourceColumn.tasks = updatedSourceColumn.tasks.map((card, index) => ({
        ...card,
        position: index + 1,
      }));
      updatedDestinationColumn.tasks = updatedDestinationColumn.tasks.map((card, index) => ({
        ...card,
        position: index + 1,
      }));

      // Prepare the updated card data
      const updatedCard = removed;
      updatedCard.column_id = destinationColumn.id;

      // Update only the affected columns
      setColumns((prevColumns) => {
        const updatedColumns = [...prevColumns];
        updatedColumns[sourceColumnIndex] = updatedSourceColumn;
        updatedColumns[destinationColumnIndex] = updatedDestinationColumn;
        return updatedColumns;
      });

      axios.post(`/api/portal/projects/${updatedCard.project.token}/tasks/update_positions`, {
        updates: updatedDestinationColumn.tasks,
        column_id: updatedDestinationColumn.token, 
        column_title: updatedDestinationColumn.title,
        dashboard_move: true,
        updated_card_id: updatedCard.token
      })
      .then(function (response) {
        // console.log(response);
        if(response.data.column.finished && response.data.review === null){
          setReviewForm({
            column: response.data.column,
            task: response.data.task
          })
        }
      })
      .catch(function (error) {
        // console.log(error);
        reportError(`File: KanbanBoard.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
        // Handle the error as needed
      });
    }
  };
  
  const resetFilters = () => {
    const defaultFilters = {
      hiddenColumns: [],
      users: [],
      sortBy: null,
      sortOrder: 'asc',
      hideEmptyColumns: false,
      projects: [],
      taskPriorities: [],
      deadline: null
    };
    
    setFilters(defaultFilters);
    
    // Also clear the filters from localForage
    localForage.removeItem(`kanban_filters`)
      .catch(err => console.error('Error clearing filters from LocalForage:', err));
  };

  const loadFilters = () => {
    localForage.getItem('kanban_filters')
      .then(value => {
        if (value) {
          setFilters(value);
        }
      })
      .catch(err => {
        console.error('Error loading filters from LocalForage:', err);
      });
  };

  const saveFiltersToLocalForage = () => {
    localForage.setItem('kanban_filters', filters)
      .catch(err => console.error('Error saving filters to LocalForage:', err));
  };

  const applyFilters = (columns) => {
    // Create a copy of the original columns array and filter out hidden columns
    // Columns
    const filteredColumns = columns.filter(column => !filters.hiddenColumns.some(hiddenColumn => hiddenColumn.title === column.title)).map(column => ({...column,tasks: [...column.tasks]}));

    // Add label filtering here
    if (filters.labels?.length) {
      filteredColumns.forEach(column => {
        column.tasks = column.tasks.filter(task => 
          task.task_labels?.some(taskLabel => 
            filters.labels.some(filterLabel => 
              taskLabel.label.id === filterLabel.id
            )
          )
        );
      });
    }
  
    if (filters.hideEmptyColumns) {
      // Filter out columns with no tasks
      filteredColumns = filteredColumns.filter(column => column.tasks.length > 0);
    }
    
    // Users
    if (filters.users.length > 0) {
      filteredColumns.forEach(column => {
        column.tasks = column.tasks.filter(task => 
          filters.users.some(filterUser => {
            // Match user ID
            const userMatch = task.users.some(taskUser => taskUser.id === filterUser.id);
            
            // If lead property exists, also check if user is lead on the task
            if (filterUser.lead) {
              return userMatch && task.users.some(taskUser => 
                taskUser.id === filterUser.id && taskUser.lead
              );
            }
            
            return userMatch;
          })
        );
      });
    }


    // Projects
    if (filters.projects.length > 0) {
      // Filter tasks within each column based on selected projects
      filteredColumns.forEach(column => {
        column.tasks = column.tasks.filter(task => filters.projects.some(project => project.id === task.project.id));
      });
    }
    
    // Date
    if (filters.sortBy === 'created_at') {
      // Sort tasks within each column based on created_at
      filteredColumns.forEach(column => {
        column.tasks.sort((a, b) => {
          const timestampA = new Date(a.created_at).getTime();
          const timestampB = new Date(b.created_at).getTime();
          return filters.sortOrder === 'asc' ? timestampA - timestampB : timestampB - timestampA;
        });
      });
    }

    // Priorities
    if (filters.taskPriorities.length > 0) {
      // Filter tasks within each column based on selected priorities
      filteredColumns.forEach(column => {
        column.tasks = column.tasks.filter(task => filters.taskPriorities.includes(task.priority));
      });
    }

    // Deadline
    if (filters.deadline === 'nearest' || filters.deadline === 'furthest') {
      // Sort tasks within each column based on deadline
      filteredColumns.forEach(column => {
        column.tasks.sort((a, b) => {
          const dateA = a.deadline_raw ? new Date(a.deadline_raw) : null;
          const dateB = b.deadline_raw ? new Date(b.deadline_raw) : null;

          // Handle cases where deadline might be null
          if (!dateA && !dateB) return 0;
          if (!dateA) return 1;
          if (!dateB) return -1;

          // Sort oldest first for 'nearest', newest first for 'furthest'
          return filters.deadline === 'nearest' 
            ? dateA - dateB  // Oldest first
            : dateB - dateA; // Newest first
        });
      });
    }
  
    return filteredColumns;
  };

  const filterByUserId = (userId, username, avatar) => {
    const updatedUsers = [...filters.users, { id: userId, username, avatar }];
    setFilters(prevFilters => ({
      ...prevFilters,
      users: updatedUsers
    }));
  };

  const filterByPriority = (priority) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      taskPriorities: [...prevFilters.taskPriorities, priority]
    }));
  };
  
  const filterByDeadline = (order) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      deadline: order
    }));
  };

  const reorderTasksByDate = (order) => {
    const today = new Date();
    const filteredColumnsCopy = JSON.parse(JSON.stringify(columns)); // Deep copy of columns
  
    filteredColumnsCopy.forEach(column => {
      column.tasks.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
  
        const diffA = Math.abs(today - dateA);
        const diffB = Math.abs(today - dateB);
  
        return order === 'nearest' ? diffA - diffB : diffB - diffA;
      });
    });
  
    // Update both columns and filters state
    setColumns(filteredColumnsCopy);
    setFilters(prevFilters => ({
      ...prevFilters,
      sortBy: 'created_at',
      sortOrder: order === 'nearest' ? 'asc' : 'desc'
    }));
  };

  

  const toggleColumnCollapse = (columnTitle) => {
    setCollapsedColumns(prev => ({
      ...prev,
      [columnTitle]: !prev[columnTitle]
    }));
  };

  const fetchTasks = () => {
    fetchColumns();
  }

  // Add this useEffect to check columns after they're loaded or updated
  useEffect(() => {
    if (columns.length > 0) {
      const columnsWithoutStage = columns.some(column => !column.stage);
      setHasColumnsWithoutStage(columnsWithoutStage);
    }
  }, [columns]);

  return (
    <TaskboardContext.Provider value={{labels, setLabels, columns, setColumns, projectAuthorizations, columns, setColumns, organizationUser, setOpenNewLabel, users, fetchTasks}}>
      <title>Requests</title>
      
      {hasColumnsWithoutStage && (
        <>
          <div className="alert alert-warning animated fadeInDown slowed mb-0" style={{right: 15, top: 15}}>
            <i className="fas fa-exclamation-triangle mr-8"></i>
            You have missing stages for certain columns. <strong className="cursor-pointer" onClick={() => setFixColumns(true)}>Click to update.</strong>
          </div>
        </>
      )}

      <Modal open={fixColumns} onClose={() => setFixColumns(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <FixColumns columns={columns} refetchData={fetchColumns} />
      </Modal>

      <Loading loading={fetchingData} />

      <div className={`queue-platform-header queue-platform-button-group`}>
        <div className="display-flex justify-content-between align-items-center">
          <div class="btn-group queue-btn-group">
            <a onClick={() => setView("kanban")} type="button" class={`btn color-1 queue-btn-group-btn ${view === "kanban" ? "active" : ""}`}><i class="fal fa-th mr-5"></i>Kanban</a>
            <a onClick={() => setView("list")} type="button" class={`btn color-1 ml-5 queue-btn-group-btn ${view === "list" ? "active" : ""}`}><i class="fal fa-bars mr-5"></i>List</a>
            <a onClick={() => setView("calendar")} type="button" class={`btn color-1 ml-5 queue-btn-group-btn ${view === "calendar" ? "active" : ""}`}><i class="fal fa-calendar-alt mr-5"></i> Calendar</a>
            <a onClick={() => setView("tickets")} type="button" class={`btn color-1 ml-5 queue-btn-group-btn ${view === "tickets" ? "active" : ""}`}><i class="fal fa-ticket-alt mr-5"></i>   Tickets</a>
          </div>

          <Filters
            view={view}
            setView={setView}
            filters={filters}
            setFilters={setFilters}
            users={users}
            projects={projects}
            columns={columns}
            setFixColumns={setFixColumns}
            resetFilters={resetFilters}
          />
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="queue-platform-content position-relative pt-15">
            {loaded && view === "calendar" && 
              <div className="animated fadeIn container-fluid pl-15 pr-15 pt-0">
                <MyCalendar organization_id={match.params.organization_id} projectAuthorizations={projectAuthorizations} refetchData={fetchColumns} project_id={null} height="calc(-173px + 100vh)"/>
            </div>
          }

          {loaded && view === "tickets" && 
            <Tickets columns={columns} organization_id={match.params.organization_id} refetchData={fetchColumns} project_id={null}/>
          }

          {loaded && view === "gantt" && !fetchingData &&
            <GanttChartApp columns={columns} projects={projects} organization_id={match.params.organization_id} refetchData={fetchColumns} project_id={null} className="gantt-dashboard-container" fetchingData={fetchingData} />
          }

          {loaded && (view === "kanban" || view === "list") && 
              <div className={`row animated fadeIn ${view === "list" ? "taskboard-list" : "list-wrapper-dashboard show-scrollbar"}`} style={{ overflow: "auto" }}>
              {(loaded) ? (
                <div className={`${view === "list" ? "": "row draggable display-flex flex-wrap-nowrap user-select-none"}`}>
                  {(columns.length === 0 || columns.every(column => column.tasks.length === 0)) ?(
                    <div className="display-flex align-items-center justify-content-center flex-direction-column height-70vh">
                      <h4>No task has been created</h4>
                      <p className="opacity-6">Here you'll find all of the tasks from your projects in one place.</p>
                      <p className="opacity-6">
                        <a href="https://guides.usequeue.com" target="_blank" className="btn btn-small background-hover color-1"><i class="fal fa-play-circle mr-8"></i>Video guides</a>
                      </p>
                    </div>
                  ):(
                    <>
                      {applyFilters(columns).map((column, index) => (
                        <Droppable key={column.title} droppableId={column.title}>
                          {(provided) => (
                            <>
                              {view === "list" ? (
                                <div ref={provided.innerRef} {...provided.droppableProps} className="list-column">
                                  <div className="taskboard-column-lists">
                                    <div className="list-header mb-15">
                                      <h5 className="kanban-list-header background-active default-padding mt-0 mb-0 display-flex justify-content-between align-items-center">
                                        <span className="display-flex align-items-center">
                                          <StageIcon stage={column.stage}/>{column.title}
                                          <span className="ml-10 font-12 opacity-6 badge background-active">
                                            {column.tasks.length}
                                          </span>
                                        </span>

                                        <div>
                                          <i 
                                            className={`fal fa-angle-${collapsedColumns[column.title] ? 'right' : 'down'} btn btn-small background-hover-2 cursor-pointer`}
                                            onClick={() => toggleColumnCollapse(column.title)}
                                          ></i>
                                        </div>
                                      </h5>
                                    </div>
                                    {!collapsedColumns[column.title] && (
                                      <>
                                        {column.tasks.map((card, index) => (
                                          <List
                                            key={card.token}
                                            card={card}
                                            index={index}
                                            refetchData={fetchColumns}
                                            organizationUser={true}
                                            column={column}
                                            draggable_id={card.id}
                                            columns={card.columns}
                                            openTask={openTask === card.token}
                                            setOpenTask={setOpenTask}
                                            projectAuthorizations={projectAuthorizations}
                                            project_id={card.project?.token}
                                            users={card.users_as_strings}
                                            project_title={card.project?.title}
                                            avatar={card.avatar}
                                          />
                                        ))}
                                        {provided.placeholder}
                                      </>
                                    )}
                                  </div>
                                </div>
                              ):(
                                <div
                                  ref={provided.innerRef}
                                  {...provided.droppableProps}
                                  className="kanban-column draggable"
                                  style={{cursor: "default"}}
                                >
                                  <div className="card">
                                    <div className="card-header">
                                      <h5 className="kanban-card-header" style={{justifyContent: "unset"}}>
                                        <StageIcon stage={column.stage}/>{column.title}
                                        <span className="ml-5 font-12 opacity-6 badge">
                                          {column.tasks.length}
                                        </span>
                                      </h5>
                                    </div>
                                    <div className="card-body">
                                      {column.tasks.map((card, index) => (
                                        <div className="">
                                          <Card
                                            key={card.token}
                                            card={card}
                                            index={index}
                                            refetchData={fetchColumns}
                                            organizationUser={true}
                                            column={column}
                                            draggable_id={card.id}
                                            columns={card.columns}
                                            openTask={openTask === card.token}
                                            setOpenTask={setOpenTask}
                                            projectAuthorizations={projectAuthorizations}
                                            project_id={card.project.token}
                                          />
                                        </div>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </Droppable>
                      ))}
                    </>
                  )}
                </div>
              ):(
                <div className="ml-15-negative mr-15-negative">
                  <KanBanSkeleton />
                </div>
              )}
            </div>
          }
        </div>
      </DragDropContext>

      <Modal open={openNewLabel} onClose={() => setOpenNewLabel(false)} className="modal-body-white" classNames={{modal: 'width-500'}} center>
        <NewLabel closeModal={() => {setOpenNewLabel(false); fetchColumns();}}/>
      </Modal>
    </TaskboardContext.Provider>
  );
};

export default KanbanBoard;